import { Component, OnInit } from '@angular/core';
import { Chart } from 'chart.js';
declare var jquery: any;
declare var $: any;

@Component({
  selector: 'app-analytics',
  templateUrl: './analytics.component.html',
  styleUrls: ['./analytics.component.css']
})
export class AnalyticsComponent implements OnInit {
  barChart: any = [];
  douughnutChart1: any = [];
  douughnutChart2: any = [];
  douughnutChart3: any = [];

  constructor() { }

  ngOnInit() {
    this.loadChart();
  } 

  //load chart
  loadChart() {
    //line chart
    var ctx = $('#barChart');
    this.barChart = new Chart(ctx, { // สร้าง object และใช้ชื่อ id lineChart ในการอ้างอิงเพื่อนำมาเเสดงผล
      type: 'bar',
      data: {
        labels: ["08.00-08.59","09.00-09.59","10.00-10.59","11.00-12.59","13.00-13.59",
        "14.00-14.59","15.00-15.59","16.00-16.59","17.00-17.59","18.00-18.59","19.00-19.59","20.00-20.59",
        "21.00-21.59","22.00-22.59","23.00-23.59"],
        datasets: [{
          label: "Visit",
          backgroundColor: "#f3ce85",
          data: [1000, 4000, 5000,3000,3000,1203,5000,3000,1000,3500,2500,3000,1900,2000,1500]
        }, {
          label: "Spending",
          backgroundColor: "#6bafbd",
          data: [10204, 20000, 20000,15000,19878,4656,5445,7245,3888,6999,7456,4785,6888,8899,9000]
        }, {
          label: "Average Spending",
          backgroundColor: "#fc8675",
          data: [9000, 19000, 18000,15000,13878,4256,4445,6245,3188,6199,7356,4385,6188,8499,8500]
        }]
      },
      options: {
        maintainAspectRatio: false,
        scales: {
            yAxes: [{
                ticks: {
                    min: 0,
                }
            }]
        }
      }
    });

    //dougthnut1
    var ctx = $('#douughnutChart1');
    this.douughnutChart1 = new Chart(ctx, { // สร้าง object และใช้ชื่อ id lineChart ในการอ้างอิงเพื่อนำมาเเสดงผล
      type: 'doughnut',
      data: {
        labels: ["Male","Female","UnKnow"],
        datasets: [{
          data: [117,1,2],
          backgroundColor: [
            "#f6998a",
            "#f9f49a",
            "#3498db"
          ]
        }]
      },
      options: {
        maintainAspectRatio: false,
      }
    });

    //dougthnut2
    var ctx = $('#douughnutChart2');
    this.douughnutChart3 = new Chart(ctx, { // สร้าง object และใช้ชื่อ id lineChart ในการอ้างอิงเพื่อนำมาเเสดงผล
      type: 'doughnut',
      data: {
        labels: ["Loyalty Customers","Potential Customers","New Customers","Casual Customers","At-Risk Customers"],
        datasets: [{
          data: [25,15,15,50,15],
          backgroundColor: [
            "#83b7d8",
            "#a8d26c",
            "#9b59b6",
            "#f9f49a",
            "#e74c3c"
          ]
        }]
      },
      options: {
        maintainAspectRatio: false,
      }
    });

    //dougthnut3
    var ctx = $('#douughnutChart3');
    this.douughnutChart2 = new Chart(ctx, { // สร้าง object และใช้ชื่อ id lineChart ในการอ้างอิงเพื่อนำมาเเสดงผล
      type: 'doughnut',
      data: {
        labels: ["Age 0 - 10 Year","Age 11 - 20 Year","Age 21 - 30 Year","Age 31 - 40 Year","Age 41 - 50 Year","Age 51 - 60 Year","Age 60+ Year","UnKnow Age"],
        datasets: [{
          data: [2,30,32,20,20,15,0,1],
          backgroundColor: [
            "#83b7d8",
            "#a8d26c",
            "#f6998a",
            "#f9f49a",
            "#9b59b6",
            "#b13031",
            "#1abc9c",
            "#95a5a6"
          ]
        }]
      },
      options: {
        maintainAspectRatio: false,
      }
    });

  }
  //end load chart


}
