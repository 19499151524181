import { Component, OnInit } from "@angular/core";
declare var jquery: any;
declare var $: any;
import {
  FormBuilder,
  FormGroup,
  Validators,
  FormControl
} from "@angular/forms";
import { PackageService } from "../service/package.service";
import { Package } from "../model/package.model";
import { BranchServicepackageService } from "../service/branchservicepackage.service";

@Component({
  selector: "app-servicepackage",
  templateUrl: "./servicepackage.component.html",
  styleUrls: ["./servicepackage.component.css"]
})
export class ServicePackageComponent implements OnInit {
  addForm: FormGroup;
  addFormMenu: FormGroup;
  editForm: FormGroup;
  editFormMenu: FormGroup;
  searchForm: FormGroup;
  searchMenuForm: FormGroup;
  statusSelected = "เปิดใช้บริการ";
  rid: string;
  ridMenu: string;

  packages = new Array();
  menus = new Array();
  servicepackage = {};
  menu = {};
  branchList = [];
  status = [
    { key: "เปิดใช้บริการ", value: "เปิดใช้บริการ" },
    { key: "ยกเลิก", value: "ยกเลิก" }
  ];
  constructor(
    private fb: FormBuilder,
    private packageService: PackageService,
    private branchServicepackageService: BranchServicepackageService,
    public servicePackages: Package
  ) {
    this.addForm = fb.group({
      packageName: ["", Validators.required],
      price: ["0", Validators.required],
      status: ["", Validators.required]
    });

    this.searchForm = fb.group({
      packageName: [""],
      priceStart: [""],
      priceEnd: [""],
      status: [""]
    });


    this.searchMenuForm = fb.group({
      menuName: [""],
      priceMenuStart: [""],
      priceMenuEnd: [""],
      menuStatus: [""]
    });

    this.editForm = fb.group({
      id: ["", Validators.required],
      packageName: ["", Validators.required],
      price: ["", Validators.required],
      status: ["", Validators.required]
    });

    this.editFormMenu = fb.group({
      id: ["", Validators.required],
      name: ["", Validators.required],
      price: ["", Validators.required]
    });

    this.addFormMenu = fb.group({
      name: ["", Validators.required],
      price: ["0", Validators.required]
    });
  }

  ngOnInit() {
    this.loadDefaultScript();
    this.search();
    this.searchMenu();
  }

  findPackageAll() {
    $("#loading").show();
    this.packages = [];
    $("#package-table")
      .DataTable()
      .clear()
      .destroy();
    this.packageService.get().subscribe(data => {
      data.forEach(doc => {
        let newItem = doc.data();
        newItem["id"] = doc.id;

        this.packages.push(newItem);
      });
      $("#loading").hide();
      setTimeout(() => {
        $("#package-table").DataTable({
          paging: true,
          retrieve: true,
          responsive: true
        });
      }, 0);
    });
  }

  search() {
    $("#loading").show();
    this.packages = [];
    let criteria = {};
    let priceStart = $("#priceStart").val();
    let priceEnd = $("#priceEnd").val();
    let status = $("#status").val();
    let packageName = $("#packageName").val();

    criteria["priceStart"] = priceStart;
    criteria["priceEnd"] = priceEnd;
    criteria["status"] = status;
    criteria["packageName"] = packageName;

    this.packages = [];
    $("#package-table")
      .DataTable()
      .clear()
      .destroy();
    this.packageService.searchByCriteria(criteria).subscribe(data => {
      data.forEach(doc => {
        let newItem = doc.data();
        newItem["id"] = doc.id;
        if (
          Number(priceStart) <= Number(doc.data().price) &&
          Number(doc.data().price) <= Number(priceEnd)
        ) {
          this.packages.push(newItem);
        }
      });
      $("#loading").hide();
      setTimeout(() => {
        $("#package-table").DataTable({
          paging: true,
          retrieve: true,
          responsive: true
        });
      }, 0);
    });
  }

  editPackage(value) {
    this.servicepackage = value;
  }

  viewPackage(servicepackage) {
    //$("#loading").show();
    this.servicepackage = servicepackage;
    /*
    this.packageService.getBranchByUserId(servicepackage.id).subscribe(data => {
      if (data.size == 0) {
        $("#loading").hide();
        setTimeout(() => {
          $(".datatables2")
            .DataTable({
              retrieve: true,
              paging: false,
              responsive: true
            })
            .clear()
            .draw();
        }, 0);
      }
      data.forEach(doc => {
        let newItem = doc.data();
        let serviceId = newItem.servicePackageId;

        if (newItem.branchRef) {
          newItem.branchRef.get().then(res => {
            let branch = res.data();
            newItem["branchname"] = branch.name;

            this.branchServicepackageService
              .getByServicePackage(serviceId)
              .subscribe(d => {
                newItem["numOfService"] = d.size;

                this.branchList.push(newItem);
                $("#loading").hide();
                setTimeout(() => {
                  $(".datatables2").DataTable({
                    paging: true,
                    retrieve: true,
                    responsive: true
                  });
                }, 0);
              });
          });
        } else {
          $("#loading").hide();
          setTimeout(() => {
            $(".datatables2").DataTable({
              paging: true,
              retrieve: true,
              responsive: true
            });
          }, 0);
        }
      });
    });

    */
  }

  submitEditPackage(value) {
    let packageValue = {};
    packageValue["nameTh"] = value.packageName;
    packageValue["price"] = Number(value.price);
    packageValue["status"] = value.status;
    this.packageService
      .updateServicePackage(value.id, packageValue)
      .then(res => {
        console.log("success");
        $("#edit-modal").modal("hide");
        this.search();
      });
  }

  deletePackage(data) {
    this.rid = data.id;
  }

  confirmDeletePackage() {
    let id = this.rid;
    this.packageService.remove(id).then(
      res => {
        console.log("success");
        $("#delete-modal").modal("hide");
        this.search();
      },
      err => {
        console.log(err);
      }
    );
  }

  addPackage(value) {
    let form = {};
    form["nameTh"] = value.packageName;
    form["price"] = value.price;
    form["status"] = value.status;

    this.packageService.create(form).then(
      res => {
        console.log("success");
        $("#add-modal").modal("hide");
        this.search();
      },
      err => {
        console.log(err);
      }
    );
  }


  addMenu(value) {
    $('#loading').show();
    this.packageService.createMenu(value).then(
      res => {
        let menuValue = {};
        //menuValue["id"] = res.id;
        menuValue["name"] = value.name;
        menuValue["price"] = Number(value.price);
        this.packageService.updateMenu(res.id, menuValue).then(res => {
            $('#loading').hide();
            $("#add-modal-menu").modal("hide");
            this.searchMenu();
          }); 
      },
      err => {
        console.log(err);
      }
    );
  }


  searchMenu() {
    $("#loading").show();
    this.menus = [];
    let criteria = {};
    let priceStart = $("#priceMenuStart").val();
    let priceEnd = $("#priceMenuEnd").val();
    let menuName = $("#menuName").val();

    criteria["priceMenuStart"] = priceStart;
    criteria["priceMenuEnd"] = priceEnd;
    criteria["menuName"] = menuName;

    this.menus = [];
    $("#menu-table")
      .DataTable()
      .clear()
      .destroy();
    this.packageService.searchMenuByCriteria(criteria).subscribe(data => {
      data.forEach(doc => {
        let newItem = doc.data();
        newItem["id"] = doc.id;
        if (
          Number(priceStart) <= Number(doc.data().price) &&
          Number(doc.data().price) <= Number(priceEnd)
        ) {
          this.menus.push(newItem);
        }
      });
      $("#loading").hide();
      setTimeout(() => {
        $("#menu-table").DataTable({
          paging: true,
          retrieve: true,
          responsive: true
        });
      }, 0);
    });
  }

  editMenu(value) {
    this.menu = value;
  }

  submitEditMenu(value) {
    $('#loading').show();
    let menuValue = {};
    menuValue["name"] = value.name;
    menuValue["price"] = Number(value.price);
    this.packageService
      .updateMenu(value.id, menuValue)
      .then(res => {
        console.log("success");
        $("#edit-modal-menu").modal("hide");
        $('#loading').hide();
        this.searchMenu();
      });
  }

  deleteMenu(data) {
    this.ridMenu = data.id;
  }

  confirmDeleteMenu() {
    $('#loading').show();
    let id = this.ridMenu;
    this.packageService.removeMenu(id).then(
      res => {
        console.log("success");
        $("#delete-modal-menu").modal("hide");
        $('#loading').hide();
        this.searchMenu();
      },
      err => {
        console.log(err);
      }
    );
  }

  viewMenu(menu) {
    this.menu = menu;
  }

  //load default script
  loadDefaultScript() {
    $("#add-modal").modal({
      show: false,
      backdrop: "static"
    });

    $("#edit-modal").modal({
      show: false,
      backdrop: "static"
    });

    $("#view-modal").modal({
      show: false,
      backdrop: "static"
    });

    $("#delete-modal").modal({
      show: false,
      backdrop: "static"
    });
  }
  //end load default script
}
