import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';

@Injectable({
    providedIn: 'root'
})
export class WorkCalendarService {

    constructor(public db: AngularFirestore) {}

     getWorkCalendars(){
        return this.db.collection('workCalendar').valueChanges();
      }
  
      create(value){
        return this.db.collection('workCalendar').add(value);
      }
  
      getByKey(key){
        return this.db.collection('workCalendar').doc(key).snapshotChanges();
      }
  
      set(key, value){
        return this.db.collection('workCalendar').doc(key).set(value);
      }
  
      update(key, value){
        return this.db.collection('workCalendar').doc(key).update(value);
      }
  
      delete(key){
        return this.db.collection('workCalendar').doc(key).delete();
      }
  
      get(){
        return this.db.collection('workCalendar').snapshotChanges();
      }

      getByDate(date){
        return this.db.collection('workCalendar',ref => ref.where('date', '==', date)).get();
      }
}
