import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';

@Injectable({
    providedIn: 'root'
})
export class PhotoService {

    constructor(public db: AngularFirestore) {}

    create(value){
      return this.db.collection('photo').add(value);
    }

    update(key, value){
      return this.db.collection('photo').doc(key).update(value);
    }

    get(){
      return this.db.collection('photo').get();
    }

    getByKey(key){
      return this.db.collection('photo').doc(key).snapshotChanges();
    }

    createHairModel(value){
      return this.db.collection('hairModel').add(value);
    }

    updateHairModel(key, value){
      return this.db.collection('hairModel').doc(key).update(value);
    }

    getHairModel(){
      return this.db.collection('hairModel').get();
    }

    removeHairModel(id) {
      return this.db.collection("hairModel").doc(id).delete();
    }

    getHairModelBycriteria(hairmodelname, status){
      if (hairmodelname == "" && status == ""){
        return this.db.collection('hairModel').get();
      }  else if (hairmodelname != "" && status == "") {
        return this.db.collection('hairModel',ref => ref.where('hairmodelname', '==', hairmodelname))
          .get();
      } else if (hairmodelname == "" && status != "") {
        return this.db.collection('hairModel',ref => ref.where('status', '==', status))
          .get();
      } else if (hairmodelname != "" && status != "") {
        return this.db.collection('hairModel',ref => ref.where('hairmodelname', '==', hairmodelname)
      .where('status', '==', status)).get();
      }
    }
}
