import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { AnalyticsComponent } from './analytics.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule
  ],
  declarations: [AnalyticsComponent],
  exports: [
    AnalyticsComponent
  ],
  providers: [
  ]
})
export class AnalyticsModule { }
