import { Component, OnInit } from '@angular/core';
declare var jquery: any;
declare var $: any;

@Component({
  selector: 'app-newspromotion',
  templateUrl: './newspromotion.component.html',
  styleUrls: ['./newspromotion.component.css']
})
export class NewsPromotionComponent implements OnInit {

  constructor() { }

  ngOnInit() {
    this.loadDefaultScript();
  } 

  //load default script
  loadDefaultScript(){
    $('#datatables').DataTable({
      "pagingType": "full_numbers",
      "lengthMenu": [
        [10, 25, 50, -1],
        [10, 25, 50, "All"]
      ],
      responsive: true,
      language: {
        search: "_INPUT_",
        searchPlaceholder: "Search records",
      }
    });

    $('#datatables2').DataTable({
      "pagingType": "full_numbers",
      "lengthMenu": [
        [10, 25, 50, -1],
        [10, 25, 50, "All"]
      ],
      responsive: true,
      language: {
        search: "_INPUT_",
        searchPlaceholder: "Search records",
      }
    });
    
}
//end load default script

}
