import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { HistoryPhotoService } from 'src/app/service/historyphoto.service';
import { HistoryService } from '../../service/history.service';
import { CustomerService } from '../../service/customer.service';
declare var jquery: any;
declare var $: any;

@Component({
  selector: 'app-history-view',
  templateUrl: './history_view.component.html',
  styleUrls: ['./history_view.component.css']
})
export class HistoryViewComponent implements OnInit {
  historyId;
  customerId;
  currentHistoryItem;
  currentCustomerItem;
  currentTechnicianItem;
  historyPhotoItem = [];
  comment = "-";
  score = 0;
  star = [];
  servicePackageMenu = [];

  constructor(
    private activatedRoute: ActivatedRoute,
    private historyService: HistoryService,
    private historyPhotoService: HistoryPhotoService,
    private customerService: CustomerService
    ) { }
  
  ngOnInit() {
    //get params
    this.activatedRoute.params.forEach((urlParams) => {
      this.historyId = urlParams['historyId'].replace('#', '');
      console.log("historyId : "+this.historyId);
      this.customerId = urlParams['customerId'].replace('#', '');
      console.log("customerId : "+this.customerId);
    });

    //get current history
    this.getCurrentHistory();

    //get current use service and menu
    this.getServicePackageMenu();

    //get history photo
    this.getHistoryPhoto();

    //get current customer
    this.getCurrentCustomer();

    //get current technician
    this.getCurrentTechnician();
  }

  getCurrentTechnician(){
    this.historyService.getUserHistoryByHistoryId(this.historyId).subscribe(data => {
      data.forEach(doc => {
        let historyItem = doc.data();
        if(historyItem.userRef){
          historyItem.userRef.get().then(res => {
            let newItem = {};
            //console.log("technician : ");
            //console.log(res.data());
            newItem['code'] = res.data().code;
            newItem['fname'] = res.data().fname;
            newItem['lname'] = res.data().lname;
            this.currentTechnicianItem = newItem;
          })
        }
      })
    });
  }

  getCurrentCustomer(){
    this.customerService.getByKey(this.customerId).subscribe(data => {
      console.log("customerItem : ");
      console.log(data.payload.data());
      this.currentCustomerItem = data.payload.data();
    });
  }

  getCurrentHistory(){
    this.historyService.getByKey(this.historyId).subscribe(data =>{
      console.log("currentHistoryItem : ");
      console.log(data.payload.data());
      this.currentHistoryItem = data.payload.data();
      //comment
      if(this.currentHistoryItem.comment){
        this.comment = this.currentHistoryItem.comment;
      }
      //score
      if(this.currentHistoryItem.score){
        this.star = [];
        this.score = this.currentHistoryItem.score;
        console.log("score : " + this.score);
        for(let i=0;i<this.score;i++){
          let starItem = {};
          if((!Number.isInteger(this.score)) && ((i+1)==(Math.ceil(this.score)))){
            starItem = {"star":true,"type":"half"};
          }else{
            starItem = {"star":true,"type":"full"};
          }
          console.log("star")
          console.log(starItem);
          this.star.push(starItem);
        }
      }
    });
  }


  getServicePackageMenu(){
    this.historyService.getUserHistoryByHistoryId(this.historyId).subscribe(data => {
      data.forEach(doc => {
        let historyItem = doc.data();
        if(historyItem.menuList && historyItem.menuList.length > 0){
          for(let i=0;i<historyItem.menuList.length;i++){
              this.servicePackageMenu.push(historyItem.menuList[i]);
          }
        }
        if(historyItem.servicepackageList && historyItem.servicepackageList.length > 0){
          if(historyItem.servicepackageList && historyItem.servicepackageList.length > 0){
            for(let i=0;i<historyItem.servicepackageList.length;i++){
                this.servicePackageMenu.push(historyItem.servicepackageList[i]);
            }
          }
        }
      })
      console.log("this.servicePackageMenu")
      console.log(this.servicePackageMenu);
    });
  }

  getHistoryPhoto(){
    $('#loading').show();
    this.historyPhotoItem = [];
    this.historyPhotoService.getPhotoByHistoryId(this.historyId).subscribe(data => {
      data.forEach(doc => {
        let historyPhotoItem = doc.data();
        if(historyPhotoItem.photoRef){
          historyPhotoItem.photoRef.get().then(res => {
            let newItem = {};
            newItem['lots'] = historyPhotoItem.lots;
            newItem['seq'] = historyPhotoItem.seq;
            newItem['name'] = historyPhotoItem.name;
            newItem['pathUrl'] = res.data().pathUrl;
            newItem['type'] = res.data().type;
            this.historyPhotoItem.push(newItem);
          })
        }
      })
      setTimeout(() => {
        this.historyPhotoItem.sort((a, b) => Number(a.seq) - Number(b.seq));
        console.log(this.historyPhotoItem);
        $('#loading').hide();
      }, 1000);

    });
  }

  openDetailModal(){
    $("#view-detail-his-modal").modal("show");
  }

}
