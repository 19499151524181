import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { BranchViewComponent } from './branch_view.component';
import { AgmCoreModule } from '@agm/core';
import { environment } from '../../../environments/environment';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    AgmCoreModule.forRoot({
      apiKey: environment.mapKey
    }),
  ],
  declarations: [BranchViewComponent],
  exports: [
    BranchViewComponent
  ],
  providers: [
  ]
})
export class BranchViewModule { }
