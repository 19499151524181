import { Component, OnInit } from '@angular/core';
import { Chart } from 'chart.js';
declare var jquery: any;
declare var $: any;

@Component({
  selector: 'app-customer',
  templateUrl: './customer.component.html',
  styleUrls: ['./customer.component.css']
})
export class CustomerComponent implements OnInit {
  lineChart: any = [];
  douughnutChart: any = [];

  constructor() { }

  ngOnInit() {
    this.loadDefaultScript();
    this.loadChart();
  } 


  //load chart
  loadChart() {
    //line chart
    var ctx = $('#lineChart');
    this.lineChart = new Chart(ctx, { // สร้าง object และใช้ชื่อ id lineChart ในการอ้างอิงเพื่อนำมาเเสดงผล
      type: 'line',
      data: {
        labels: ["Jan","Feb","March","April","May","June","July","August","Sep","Oct","Nov","Dec"],
        datasets: [{
          label: 'การใช้บริการ',
          data: [5,11,22,28,33,37,40,43,46,48,51,53],
          fill: true,
          lineTension: 0.5,
          backgroundColor: "#ffa6a6",
          borderColor: "red", // สีของเส้น
          borderWidth: 2
        }]
      },
      options: {
        maintainAspectRatio: false,
      }
    });

    //doughtnut chart
    var ctx = $('#douughnutChart');
    this.douughnutChart = new Chart(ctx, { // สร้าง object และใช้ชื่อ id lineChart ในการอ้างอิงเพื่อนำมาเเสดงผล
      type: 'doughnut',
      data: {
        labels: ["Loyalty Customers","Potential Customers","New Customers","Casual Customers","At-Risk Customers"],
        datasets: [{
          data: [25,15,15,50,15],
          backgroundColor: [
            "#83b7d8",
            "#a8d26c",
            "#9b59b6",
            "#f9f49a",
            "#e74c3c"
          ]
        }]
      },
      options: {
        maintainAspectRatio: false,
      }
    });
  }

  //end load chart

  //load default script
  loadDefaultScript(){
      $('#datatables').DataTable({
        "pagingType": "full_numbers",
        "lengthMenu": [
          [10, 25, 50, -1],
          [10, 25, 50, "All"]
        ],
        responsive: true,
        language: {
          search: "_INPUT_",
          searchPlaceholder: "Search records",
        }
      });
      
  }
  //end load default script

}
