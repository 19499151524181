import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { HistoryViewComponent } from './history_view.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule
  ],
  declarations: [HistoryViewComponent],
  exports: [
    HistoryViewComponent
  ],
  providers: [
  ]
})
export class HistoryViewModule { }
