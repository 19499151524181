import { Injectable } from '@angular/core';
@Injectable({
    providedIn: 'root'
})
export class User{
    id: string;
    fname: string;
    lname: string;
    identificationCode: string;
    role: string;
    email: string;
    password: string;
    branchId: string;
    branchCode: string;
    techSeq: string;
    franchise: boolean;
}
