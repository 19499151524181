import { Component, OnInit } from '@angular/core';
declare var jquery: any;
declare var $: any;

@Component({
  selector: 'app-cussettings',
  templateUrl: './cussettings.component.html',
  styleUrls: ['./cussettings.component.css']
})
export class CussettingsComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  } 

}
