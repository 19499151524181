import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { Workschedule3Component } from './workschedule3.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule
  ],
  declarations: [Workschedule3Component],
  exports: [
    Workschedule3Component
  ],
  providers: [
  ]
})
export class Workschedule3Module { }
