import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { DashboardComponent } from './dashboard/dashboard.component';
import { BranchComponent } from './branch/branch.component';
import { BranchAddComponent } from './branch/add/branch_add.component';
import { BranchEditComponent } from './branch/edit/branch_edit.component';
import { BranchViewComponent } from './branch/view/branch_view.component';
import { MenProcessComponent } from './menprocess/menprocess.component';
import { TechnicianComponent } from './technician/technician.component';
import { ServicePackageComponent } from './servicepackage/servicepackage.component';
import { HistoryComponent } from './history/history.component';
import { HistoryViewComponent } from './history/view/history_view.component';
import { WorkscheduleComponent } from './workschedule/workschedule.component';
import { CustomerComponent } from './customer/customer.component';
import { HairmodelComponent } from './hairmodel/hairmodel.component';
import { AnalyticsComponent } from './customer/analytics/analytics.component';
import { CussettingsComponent } from './customer/cussettings/cussettings.component';
import { CustomerDetailComponent } from './customer/customerdetail/customerdetail.component';
import { NewsPromotionComponent } from './customer/newspromotion/newspromotion.component';
import { PointRewardComponent } from './customer/pointreward/pointreward.component';
import { ReportComponent } from './customer/report/report.component';
import { NewCustomerComponent } from './customer/newcustomer/newcustomer.component';
import { CustomerViewComponent } from './customer/customerview/customerview.component';
import { TechnicalDashboardComponent } from './technicaldashboard/technicaldashboard.component';
import { CusHistoryComponent } from './customer/cushistory/cushistory.component';
import { bookingListComponent } from './bookingList/bookingList.component';
import { ApproveListComponent } from './freelance/approvelist/approvelist.component';
import { FreelanceListComponent } from './freelance/freelancelist/freelancelist.component';
import { FreelanceProfileComponent } from './freelance/freelanceProfile/freelanceProfile.component';
import { FreelanceHistoryComponent } from './freelance/freelanceHistory/freelanceHistory.component';
import { FreelanceHisDetailComponent } from './freelance/freelanceHisDetail/freelanceHisDetail.component';
import { Workschedule2Component } from './workschedule2/workschedule2.component';
import { TechnicianEditComponent } from './technicianedit/technicianedit.component';
import { Workschedule3Component } from './workschedule3/workschedule3.component';

const routes: Routes = [
  {
    path: '',
    component: DashboardComponent
    //,canActivate: [NeedAuthGuard]
  },
  {
    path: 'branch',
    component: BranchComponent
    //,canActivate: [NeedAuthGuard]
  },
  {
    path: 'branch-add',
    component: BranchAddComponent
  },
  {
    path: 'branch-edit/:rid',
    component: BranchEditComponent
  },
  {
    path: 'branch-view/:rid',
    component: BranchViewComponent
  },
  {
    path: 'menprocess',
    component: MenProcessComponent
    //,canActivate: [NeedAuthGuard]
  },
  {
    path: 'technician',
    component: TechnicianComponent
    //,canActivate: [NeedAuthGuard]
  },
  {
    path: 'servicepackage',
    component: ServicePackageComponent
    //,canActivate: [NeedAuthGuard]
  },
  {
    path: 'history',
    component: HistoryComponent
    //,canActivate: [NeedAuthGuard]
  },
  {
    path: 'history-view/:historyId/:customerId',
    component: HistoryViewComponent
  },
  {
    path: 'newspromotion',
    component: NewsPromotionComponent
  },
  {
    path: 'workschedule',
    component: WorkscheduleComponent
  },
  {
    path: 'customer-dashboard',
    component: CustomerComponent
  },
  {
    path: 'customer-analytics',
    component: AnalyticsComponent
  },
  {
    path: 'customer-settings',
    component: CussettingsComponent
  },
  {
    path: 'customer-detail',
    component: CustomerDetailComponent
  },
  {
    path: 'point-reward',
    component: PointRewardComponent
  },
  {
    path: 'customer-report',
    component: ReportComponent
  },
  {
    path: 'hairmodel',
    component: HairmodelComponent
  },
  {
    path: 'newcustomer',
    component: NewCustomerComponent
  },
  {
    path: 'customerview/:cid',
    component: CustomerViewComponent
  },
  {
    path: 'technicaldashboard',
    component: TechnicalDashboardComponent
  },
  {
    path: 'cushistory',
    component: CusHistoryComponent
  },
  {
    path: 'bookinglist',
    component: bookingListComponent
  },
  {
    path: 'approvelist',
    component: ApproveListComponent
  },
  {
    path: 'freelancelist',
    component: FreelanceListComponent
  },
  {
    path: 'freelanceProfile/:cid',
    component: FreelanceProfileComponent
  },
  {
    path: 'freelanceHistory',
    component: FreelanceHistoryComponent
  },
  {
    path: 'freelanceHisDetail/:cid',
    component: FreelanceHisDetailComponent
  },
  {
    path: 'workschedule2',
    component: Workschedule2Component
  },
  {
    path: 'technicianEdit/:cid',
    component: TechnicianEditComponent
  },
  {
    path: 'workschedule3',
    component: Workschedule3Component
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {useHash: true})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
