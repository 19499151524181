import { Component, OnInit } from '@angular/core';
import { CustomerService } from '../../service/customer.service';
import { CustomerConfigService } from '../../service/customerconfig.service';
declare var jquery: any;
declare var $: any;

@Component({
  selector: 'app-pointreward',
  templateUrl: './pointreward.component.html',
  styleUrls: ['./pointreward.component.css']
})
export class PointRewardComponent implements OnInit {
  customerList = [];
  pointConfig = 0;
  pointConfigModel;

  constructor(
    private customerService: CustomerService,
    private customerConfigService: CustomerConfigService
  ) { }

  ngOnInit() {
    this.search();
    this.getConfigPoint();
  } 

  search(){
    $('#loading').show();
    this.customerList = [];
    this.customerService.get()
      .subscribe(data => {
        data.forEach(doc => {
          let newItem = doc.payload.doc.data();
          newItem["id"] = doc.payload.doc.id;
          this.customerList.push(newItem);
        });

        setTimeout(() => {
          $('#loading').hide();
          $('#customer-point-reward-datatables').DataTable({
            retrieve: true,
            paging: true,
            responsive: true
          });
        }, 0);

      },
      err => {
        console.log(err);
      });
  }

  getConfigPoint(){
    this.customerConfigService.get().subscribe(data => {
      data.forEach(doc => {
        let newItem = doc.payload.doc.data();
        this.pointConfigModel = newItem;
        this.pointConfigModel['id'] = doc.payload.doc.id;
        this.pointConfig = newItem['pointPerCash'];
        //console.log(newItem);
      });
    })
  }

  updateConfigPoint(){
    $('#loading').show();
    let pointConfigModel = {};
    pointConfigModel['pointPerCash'] = Number(this.pointConfig);
    this.customerConfigService.update(this.pointConfigModel.id, pointConfigModel).then(data => {
      $('#loading').hide();
    })
  }

  //load default script
  loadDefaultScript(){
    $('#datatables').DataTable({
      "pagingType": "full_numbers",
      "lengthMenu": [
        [10, 25, 50, -1],
        [10, 25, 50, "All"]
      ],
      responsive: true,
      language: {
        search: "_INPUT_",
        searchPlaceholder: "Search records",
      }
    });
    
    $(".quick-btn").click(function(){
      $(".quick-btn").removeClass("active");
      $(this).addClass("active");
  });
}
//end load default script

}
