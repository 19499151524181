import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { Workschedule2Component } from './workschedule2.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule
  ],
  declarations: [Workschedule2Component],
  exports: [
    Workschedule2Component
  ],
  providers: [
  ]
})
export class Workschedule2Module { }
