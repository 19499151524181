import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';

@Injectable({
    providedIn: 'root'
})
export class CompanyService {

    constructor(public db: AngularFirestore) {}

    create(value){
      return this.db.collection('company').add(value);
    }
    
    getByKey(key){
      return this.db.collection('company').doc(key).snapshotChanges();
    }
  
    set(key, value){
      return this.db.collection('company').doc(key).set(value);
    }
  
    update(key, value){
      return this.db.collection('company').doc(key).update(value);
    }

    delete(key){
      return this.db.collection('company').doc(key).delete();
    }
  
    get(){
      return this.db.collection('company').snapshotChanges();
    }
  
    search(searchValue){
      return this.db.collection('company',ref => ref.where('nameToSearch', '>=', searchValue)
        .where('nameToSearch', '<=', searchValue + '\uf8ff'))
        .snapshotChanges()
    }
  
    searchByTitle(value){
      return this.db.collection('company',ref => ref.orderBy('title').startAt(value)).snapshotChanges();
    }
}
