import { Component, OnInit } from '@angular/core';
import { HistoryService } from '../service/history.service';
import * as Chart from 'chart.js'

declare var jquery: any;
declare var $: any;

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit {

  years = [];
  canvas: any;
  ctxCustomer: any;
  ctxBalance: any;

  currentYearBalance = new Date().getFullYear();
  currentYearCustomer = new Date().getFullYear();

  ngAfterViewInit() {
    console.log('ngAfterViewInit');
    this.canvas = document.getElementById('chartCustomer');
    this.ctxCustomer = this.canvas.getContext('2d');
    this.ctxCustomer.canvas.width  = window.innerWidth-325;
    this.ctxCustomer.canvas.height  = window.innerHeight/2;

    this.canvas = document.getElementById('chartBalance');
    this.ctxBalance = this.canvas.getContext('2d');
    this.ctxBalance.canvas.width  = window.innerWidth-325;
    this.ctxBalance.canvas.height  = window.innerHeight/2;
  }

  constructor(private historyService: HistoryService) { }
  
  branchId
  ngOnInit() {
    console.log('ngOnInit');
    console.log(this.currentYearCustomer);
    console.log(this.currentYearBalance);
    this.years[0] = this.currentYearBalance;
    this.years[1] = this.currentYearBalance-1;
    this.years[2] = this.currentYearBalance-2;
    this.initDataBalance();
    this.initDataCustomer();
    $('#loading').show();
    // console.log(localStorage.getItem("authenMenbarberToken"));
    let token = JSON.parse(localStorage.getItem("authenMenbarberToken"));
    if(token["role"].toUpperCase() == "TECH"){
      window.location.href = "/#/technicaldashboard";
    }else if(token["role"].toUpperCase() == "SUPERADMIN"){
      $('#loading').hide();
    }
    this.branchId = token["branchId"];
    this.getDataDashboard();
  } 

  dataBalance = [];
  dataCustomer = [];
  initDataBalance(){
    this.dataBalance[0] = 0;
    this.dataBalance[1] = 0;
    this.dataBalance[2] = 0;
    this.dataBalance[3] = 0;
    this.dataBalance[4] = 0;
    this.dataBalance[5] = 0;
    this.dataBalance[6] = 0;
    this.dataBalance[7] = 0;
    this.dataBalance[8] = 0;
    this.dataBalance[9] = 0;
    this.dataBalance[10] = 0;
    this.dataBalance[11] = 0;
  }

  initDataCustomer(){
    this.dataCustomer[0] = 0;
    this.dataCustomer[1] = 0;
    this.dataCustomer[2] = 0;
    this.dataCustomer[3] = 0;
    this.dataCustomer[4] = 0;
    this.dataCustomer[5] = 0;
    this.dataCustomer[6] = 0;
    this.dataCustomer[7] = 0;
    this.dataCustomer[8] = 0;
    this.dataCustomer[9] = 0;
    this.dataCustomer[10] = 0;
    this.dataCustomer[11] = 0;
  }

  historyData;
  dataArray = [];
  balanceToday = 0;
  customerToday = 0;
  balanceThisMonth = 0;
  customerThisMonth = 0;
  balanceThisYear = 0;
  customerThisYear = 0;
  getDataDashboard(){
    console.log(this.branchId);
    this.historyService.getHistoryByBranchIdAndStatus(this.branchId,"HIS").subscribe(data => {
      if(data.size == 0){
        console.log("data == 0");
        $('#loading').hide();
      }
      this.historyData = data;
      data.forEach(doc => {
        let item = doc.data();
        // console.log(item);
        var endDate = item["endDate"];
        if(endDate){
          let endDateTodate = endDate.toDate();
          // today
          if(this.compareWithoutTime(endDateTodate,new Date())){
            // console.log(item);
            this.balanceToday += item["price"];
            this.customerToday++;
          }
          // ThisMonth
          if(this.compareMonth(endDateTodate,new Date())){
            this.balanceThisMonth += item["price"];
            console.log(this.balanceThisMonth);
            this.customerThisMonth++;
          }
        }
        $('#loading').hide();
      });
      this.getYearBalance();
    },
    err => {
      alert(err);
      $('#loading').hide();
    })
    
  }

  chartBalance;
  getYearBalance(){
    this.historyData.forEach(doc => {
      let item = doc.data();
      var endDate = item["endDate"];
      if(endDate){
        let endDateTodate = endDate.toDate();
        // console.log(endDateTodate.getFullYear() +"==="+this.currentYearBalance);
        if(endDateTodate.getFullYear() != this.currentYearBalance && endDateTodate.getFullYear() != this.currentYearCustomer){
          return;
        }
        this.dataBalance[endDateTodate.getMonth()] = isNaN(this.dataBalance[endDateTodate.getMonth()])?0:this.dataBalance[endDateTodate.getMonth()]+item["price"];
        this.dataCustomer[endDateTodate.getMonth()] = isNaN(this.dataCustomer[endDateTodate.getMonth()])?0:this.dataCustomer[endDateTodate.getMonth()]+1;
      }
    });

    // console.log(this.dataBalance);

    this.chartBalance = new Chart(this.ctxBalance, {
      type: 'bar',
      data: {
          labels: ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"],
          datasets: [{
              label: 'ยอดเงิน',
              data: this.dataBalance,
              backgroundColor: [
                'rgba(54, 162, 235, 1)',
                'rgba(54, 162, 235, 1)',
                'rgba(54, 162, 235, 1)',
                'rgba(54, 162, 235, 1)',
                'rgba(54, 162, 235, 1)',
                'rgba(54, 162, 235, 1)',
                'rgba(54, 162, 235, 1)',
                'rgba(54, 162, 235, 1)',
                'rgba(54, 162, 235, 1)',
                'rgba(54, 162, 235, 1)',
                'rgba(54, 162, 235, 1)',
                'rgba(54, 162, 235, 1)'
              ],
              borderWidth: 1
          }]
      },
      options: {
        responsive: false
      }
    });

    this.getYearCustomer();
  }

  chartCustomer;
  getYearCustomer(){
      this.chartCustomer = new Chart(this.ctxCustomer, {
      type: 'bar',
      data: {
          labels: ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"],
          datasets: [{
              label: 'จำนวนลูกค้า',
              data: this.dataCustomer,
              backgroundColor: [
                'rgb(245, 174, 62)',
                'rgb(245, 174, 62)',
                'rgb(245, 174, 62)',
                'rgb(245, 174, 62)',
                'rgb(245, 174, 62)',
                'rgb(245, 174, 62)',
                'rgb(245, 174, 62)',
                'rgb(245, 174, 62)',
                'rgb(245, 174, 62)',
                'rgb(245, 174, 62)',
                'rgb(245, 174, 62)',
                'rgb(245, 174, 62)'
              ],
              borderWidth: 1
          }]
      },
      options: {
        responsive: false
      }
    });
  }

  changeYearBalanceUpdate(){
    this.initDataBalance();
    this.historyData.forEach(doc => {
      let item = doc.data();
      var endDate = item["endDate"];
      if(endDate){
        let endDateTodate = endDate.toDate();
        if(endDateTodate.getFullYear() != this.currentYearBalance){
          return;
        }
        this.dataBalance[endDateTodate.getMonth()] = isNaN(this.dataBalance[endDateTodate.getMonth()])?0:this.dataBalance[endDateTodate.getMonth()]+item["price"];
      }
    });
    this.chartBalance.data.datasets.data = this.dataBalance;
    this.chartBalance.update();
  }

  changeYearCustomerUpdate(){
    this.initDataCustomer();
    this.historyData.forEach(doc => {
      let item = doc.data();
      var endDate = item["endDate"];
      if(endDate){
        let endDateTodate = endDate.toDate();
        if(endDateTodate.getFullYear() != this.currentYearCustomer){
          return;
        }
        this.dataCustomer[endDateTodate.getMonth()] = isNaN(this.dataCustomer[endDateTodate.getMonth()])?0:this.dataCustomer[endDateTodate.getMonth()]+1;
      }
    });
    this.chartCustomer.data.datasets.data = this.dataCustomer;
    this.chartCustomer.update();
  }

  changeYearBalance(e) {
    this.currentYearBalance = e.target.value;
    console.log(this.currentYearBalance);
    this.changeYearBalanceUpdate();
  }

  changeYearCustomer(e) {
    this.currentYearCustomer = e.target.value;
    console.log(this.currentYearCustomer);
    this.changeYearCustomerUpdate();
  }

  compareYear(now,year){
    var nowStr = now.getFullYear();
    var yearStr = year;
    return nowStr == yearStr;
  }

  compareMonth(now,date){
    var nowStr = now.getFullYear()+""+now.getMonth();
    var dateStr = date.getFullYear()+""+date.getMonth();
    // console.log(nowStr+"==="+dateStr);
    return nowStr == dateStr;
  }

  compareWithoutTime(now,date){
    now.setHours(0);
    now.setMinutes(0);
    now.setSeconds(0);
    now.setMilliseconds(0);

    date.setHours(0);
    date.setMinutes(0);
    date.setSeconds(0);
    date.setMilliseconds(0);
    // console.log(now);
    // console.log(date);
    return now.getTime() === date.getTime();
  }

}
