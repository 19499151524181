import { Component, OnInit } from "@angular/core";
declare var jquery: any;
declare var $: any;
declare var $calendar: any;
declare var swal: any;

import { HttpClient } from "@angular/common/http";
import { HttpErrorResponse } from "@angular/common/http";
import { BranchService } from "../service/branch.service";
import { TechnicianService } from "../service/technician.service";
import { UserService } from "../service/user.service";
import { WorkCalendarService } from "../service/workCalendar.service";
import { AngularFirestore } from '@angular/fire/firestore';
import { HistoryService } from "../service/history.service";

@Component({
  selector: "app-workschedule2",
  templateUrl: "./workschedule2.component.html",
  styleUrls: ["./workschedule2.component.css"]
})
export class Workschedule2Component implements OnInit {
  branchList = [];
  technicianList = [];
  workList = [];
  chooseCurrentBranch = {};
  currentChangeTime = {};

  constructor(
    private httpService: HttpClient,
    private branchService: BranchService,
    private userService: UserService,
    private workCalendarService: WorkCalendarService,
    private technicianService: TechnicianService,
    private historyService: HistoryService,
    private db: AngularFirestore
  ) {}

  ngOnInit() {

    this.loadDefaultScript(); 
    this.getAllBranch();
    this.getAllTechnician();
    //this.loadDataWork();
  } 

  getAllBranch(){
    //console.log("branch");
    this.branchList = [];
    this.branchService.get().subscribe(data=>{
      data.forEach(doc => {
        //console.log(doc);
        let branch = doc.data();
        branch['id'] = doc.id;
        this.branchList.push(branch);
      });
    });
  }

  getAllTechnician(){ 
    //console.log("technician");
    this.technicianList = [];
    this.userService.getTech().subscribe(data=>{
      data.forEach(doc => {
        //console.log(doc.data());
        let tech = doc.data();
        tech['id'] = doc.id;
        tech['display'] = true;
        tech["openTime"] = tech['openTime'].slice(0,2) + ":" + tech['openTime'].slice(2);
        tech['closeTime'] = tech['closeTime'].slice(0,2) + ":" + tech['closeTime'].slice(2);
        this.technicianList.push(tech);
      }); 
    })
  }

  loadDataWork(){
    //console.log("loadDataWork");
    this.workList = [];
    this.getAllTechnician();
    
    for(let i=0;i<this.branchList.length;i++){
        let workModel = {};
        workModel['branch'] = this.branchList[i];
        workModel['technicianList'] = [];
        this.workList.push(workModel);
    }

    let dateSelected = $('#dateSchedule2').val();
    this.workCalendarService.getByDate(dateSelected).subscribe(data=>{
        data.forEach(doc => {
              let calendar = doc.data();

               for(let i=0;i<this.workList.length;i++){
                  if(this.workList[i]['branch']['id'] == calendar['branchId']){
                      if (calendar.userRef) {
                          calendar.userRef.get().then(res=>{
                              let tech = res.data();
                              tech['id'] = res.id;
                              tech["openTime"] = calendar['openTime'].slice(0,2) + ":" + calendar['openTime'].slice(2);
                              tech['closeTime'] = calendar['closeTime'].slice(0,2) + ":" + calendar['closeTime'].slice(2);
                              this.workList[i]['technicianList'].push(tech);

                              //update modal
                              for(let j=0;j<this.technicianList.length;j++){
                                if(this.technicianList[j].id==tech.id){
                                  this.technicianList[j].display=false;
                                }
                              }

                          });
                      }
                  }
               }

        });
    });

  }

  browseTech(branch){
    this.chooseCurrentBranch = branch;
    //console.log(this.chooseCurrentBranch);
    setTimeout(() => {
      $('#technician-work-modal').modal('show');
    }, 0);
  }

  chooseTech(tech){
    //console.log(tech);
    for(let i=0;i<this.workList.length;i++){
      //console.log(this.chooseCurrentBranch['id']);
      //console.log(this.workList[i]['branch']['id']);
      if(this.chooseCurrentBranch['id'] == this.workList[i]['branch']['id']){
        this.workList[i]['technicianList'].push(tech);

        setTimeout(() => {
          $('#technician-work-modal').modal('hide');
        }, 0);
        //break;

        for(let j=0;j<this.technicianList.length;j++){
          if(this.technicianList[j].id==tech.id){
            this.technicianList[j].display=false;
          }
        }

      }
    }
  }

  removeTech(branch, tech){
    //console.log(branch);
    //console.log(tech);
    for(let i=0;i<this.workList.length;i++){
      if(branch['id'] == this.workList[i]['branch']['id']){

        //remove tech
        for(let j=0;j<this.workList[i]['technicianList'].length;j++){
            if(this.workList[i]['technicianList'][j]['id'] == tech['id']){
              this.workList[i]['technicianList'].splice(j, 1);
            }
        }

        //update tech stock
        for(let j=0;j<this.technicianList.length;j++){
          if(this.technicianList[j].id==tech.id){
            this.technicianList[j].display=true;
          }
        }

      }
    }

  }

  saveSchedule(){
     
    let date = $('#dateSchedule2').val();
    //console.log(date);
    //clear old data
    this.workCalendarService.getByDate(date).subscribe(data=>{
      data.forEach(doc => {
          this.workCalendarService.delete(doc.id).then(res=>{
            console.log(doc.id + "Remove success");
          })
      });

    //branch
    for(let i=0;i<this.workList.length;i++){
      let branch = this.workList[i]['branch'];
      console.log("สาขาบริการ : " + branch['name']);

      //technician
      for(let j=0;j<this.workList[i]['technicianList'].length;j++){
          $('#loading').show();
          let tech = this.workList[i]['technicianList'][j];
          console.log("ช่าง : " + tech['fname']);
          let calendarValue = {};
          calendarValue['userId'] = tech['id'];
          calendarValue['userRef'] = this.db.doc('/users/'+tech['id']).ref;
          calendarValue['branchId'] = branch['id'];
          calendarValue['branchRef'] = this.db.doc('/branch/'+branch['id']).ref;
          calendarValue['date'] =  date;
          calendarValue['openTime'] = tech['openTime'].slice(0,2) + tech['openTime'].slice(3);
          calendarValue['closeTime'] = tech['closeTime'].slice(0,2) + tech['closeTime'].slice(3);
          this.workCalendarService.create(calendarValue).then(res=>{
            console.log(res.id);
            $('#loading').hide();
          });
      }

    }

    });

  }

  openAssignWorkDialog(){
    $('#assign-work-modal').modal('show');
  }

  openChangeTime(branch,tech){
    this.currentChangeTime['branch'] = branch;
    this.currentChangeTime['tech'] = tech;
    console.log(this.currentChangeTime);
    $('#techStartTime').val(tech['openTime']);
    $('#techEndTime').val(tech['closeTime']);
    $('#change-time-modal').modal('show');
  }

  confirmChangeTime(){
    for(let i=0;i<this.workList.length;i++){
      if(this.currentChangeTime['branch']['id'] == this.workList[i]['branch']['id']){
          for(let j=0;j<this.workList[i]['technicianList'].length;j++){
            if(this.workList[i]['technicianList'][j]['id'] == this.currentChangeTime['tech']['id']){
              this.workList[i]['technicianList'][j]['openTime'] = $('#techStartTime').val();
              this.workList[i]['technicianList'][j]['closeTime'] = $('#techEndTime').val();
              $('#change-time-modal').modal('hide');
            }
        }
      }
    }
  }




  /////////////////////////////////////////// tab 2 ///////////////////////////////////////////
  currentBranchId = "";
  currentBranch = {};
  technicianListTab2 = [];
  selectBranch(currentBranchId:string){
    this.currentBranchId = currentBranchId;
    console.log(this.currentBranchId); 
  }

  loadSchedule(){
    if(this.currentBranchId != "" && this.currentBranchId != null){
        this.technicianListTab2 = [];
        let date = $('#dateSchedule').val();
        let dateArr = date.split("/");
        let dateNewFormat = dateArr[1]+"/"+dateArr[0]+"/"+dateArr[2];
        this.workCalendarService.getByDate(date).subscribe(data=>{
          data.forEach(doc => {
              let workCalendar = doc.data();
              if(workCalendar.userRef){
                workCalendar.userRef.get().then(res => {
                    let technician = res.data();
                    if(technician.role == 'tech' && workCalendar.branchId == this.currentBranchId){
                        technician['id'] = res.id;
                        technician['blockTimeList'] = this.generateDefaultTime(workCalendar['openTime'], workCalendar['closeTime']);

                        //Check block avaiable
                        let currentDate = new Date();
                        date = new Date(dateNewFormat);
                        if (Number(date.getTime()) < Number(currentDate.getTime()) && !this.isSameDay(currentDate, date)) {
                          for (let i = 0; i < technician['blockTimeList'].length; i++) {
                            technician['blockTimeList'][i]['status'] = "INACTIVE";
                          }
                          this.technicianListTab2.push(technician);
                        }else{
                          for (let i = 0; i < technician['blockTimeList'].length; i++) {
                            technician['blockTimeList'][i]['status'] = "ACTIVE";
                          }
                          let historyList = [];
                          this.historyService.getUserHistoryByUserId(technician['id']).subscribe(data => {
                              let dataSize = data.size;
                              console.log(dataSize);
                              if (dataSize == 0) {
                                for (let i = 0; i < technician['blockTimeList'].length; i++) {
                                  let dateTimeCheck = dateNewFormat + " " + technician['blockTimeList'][i].startTime;
                                  let date = new Date(dateTimeCheck);
                                  if (Number(date.getTime()) < Number(currentDate.getTime())) {
                                    technician['blockTimeList'][i]['status'] = "INACTIVE";
                                  }
                                  //console.log(blockTimeList[i])
                                }
                                this.technicianListTab2.push(technician);
                              }

                              let count = 0;
                              data.forEach(doc => {
                                let userHistory = doc.data();
                                if (userHistory.historyRef) {
                                    userHistory.historyRef.get().then(res => {
                                      count++;
                                      let history = res.data();
                                      history['id'] = res.id;
                                      if (history['status'] == 'LEAVE' || history['status'] == 'BOOK') {
                                        //console.log(history);
                                        if (history.startDateBook && history.endDateBook) {
                                          historyList.push(history);
                                        }
                                      }

                                      if (count == dataSize) {
                                          for (let i = 0; i < technician['blockTimeList'].length; i++) {
                                              let dateTimeCheck = dateNewFormat + " " + technician['blockTimeList'][i].startTime;
                                              let date = new Date(dateTimeCheck);
                                              let isInactive = false;

                                              if(historyList.length > 0){
                                                  for (let k = 0; k < historyList.length; k++) {
                                                    if (Number(date.getTime()) < Number(currentDate.getTime())) {
                                                        isInactive = true;
                                                    }else{
                                                      if (historyList[k]['status'] == 'LEAVE' || historyList[k]['status'] == 'BOOK') {
                                                        let startDateCheck = new Date(historyList[k]['startDateBook'].seconds * 1000);
                                                        let endDateCheck = new Date(historyList[k]['endDateBook'].seconds * 1000);
                                                        if (Number(date.getTime()) >= Number(startDateCheck.getTime()) && Number(date.getTime()) < Number(endDateCheck.getTime())) {
                                                            isInactive = true;
                                                        }
                                                      }
                                                    }
                                                  }
                                              }//end history check length
                                              else{
                                                if (Number(date.getTime()) < Number(currentDate.getTime())) {
                                                  isInactive = true;
                                                }
                                              }

                                              if (isInactive) {
                                                technician['blockTimeList'][i]['status'] = "INACTIVE";
                                              }

                                              if ((i + 1) == technician['blockTimeList'].length) {
                                                  this.technicianListTab2.push(technician);
                                              }

                                          }//End loop block time
                                      }

                                    });
                                }
                              });

                          });
                        }
                        //End check block avaiable 
                    }

                })
              }
          });
        }); 
    }
  }

  isSameDay(d1,d2){
    if(d1.getFullYear() == d2.getFullYear() 
    && d1.getDay() == d2.getDay()
    && d1.getMonth() == d2.getMonth()){
      return true;
    }else{
      return false;
    }
  }

  generateDefaultTime(openTime, closeTime){
    //console.log(openTime);
    //console.log(closeTime);
    let defaultBlockTime = [];
    openTime = openTime.substring(0, 2);
    closeTime = closeTime.substring(0, 2);
    if(openTime < closeTime){
        for(let i=0;i<23;i++){
          if(i>=openTime && i<closeTime){
              let timeBlock = {"startTime":"", "endTime":"", "status":""}
              let prefixTime = "";
              if(i.toString().length == 1){
                prefixTime = prefixTime + "0";
              }
              timeBlock.startTime = prefixTime+ i + ":" + "00";
              if(i==9){
                timeBlock.endTime = (i+1) + ":" + "00";
              }else{
                timeBlock.endTime = prefixTime+ (i+1) + ":" + "00";
              } 
              timeBlock.status = "ACTIVE";
              defaultBlockTime.push(timeBlock);
          }
      }
    }else if(openTime > closeTime){
      for(let i=0;i<closeTime;i++){
            let timeBlock = {"startTime":"", "endTime":"", "status":""}
            let prefixTime = "";
            if(i.toString().length == 1){
              prefixTime = prefixTime + "0";
            }
            timeBlock.startTime = prefixTime+ i + ":" + "00";
            if(i==9){
              timeBlock.endTime = (i+1) + ":" + "00";
            }else{
              timeBlock.endTime = prefixTime+ (i+1) + ":" + "00";
            }
             
            timeBlock.status = "ACTIVE";
            defaultBlockTime.push(timeBlock);
        }

      for(let i=0;i<23;i++){
          if(i>=openTime){
              let timeBlock = {"startTime":"", "endTime":"", "status":""}
              let prefixTime = "";
              if(i.toString().length == 1){
                prefixTime = prefixTime + "0";
              }
              timeBlock.startTime = prefixTime+ i + ":" + "00";
              if(i==9){
                timeBlock.endTime = (i+1) + ":" + "00";
              }else{
                timeBlock.endTime = prefixTime+ (i+1) + ":" + "00";
              }
               
              timeBlock.status = "ACTIVE";
              defaultBlockTime.push(timeBlock);
          }
      }
    }
    
    console.log("generateDefaultTime");
    console.log(defaultBlockTime);
    return defaultBlockTime;
  }

  changeDateTechWork(){
    let date = $('#dateSchedule').val();
    console.log(date);
  }


  technicianWorkList = [];
  brachWorkSelect = "";
  selectUpdateWorkBranch(currentBranchId:string){
    this.brachWorkSelect = currentBranchId;
    this.technicianWorkList = [];
    this.technicianService.getByBranchId(currentBranchId).subscribe(data => {
        data.forEach(doc => {
          let branchUser = doc.data();
            if(branchUser.usersRef){
                branchUser.usersRef.get().then(res => {
                  let technician = res.data();
                    if(technician.role == 'tech'){
                        technician['id'] = res.id;
                        this.technicianWorkList.push(technician);
                    }
                })
            }
        })
    })
  }

  technicianWorkSelect = "";
  selectUpdateWorkTechnician(technicianId:string){
    this.technicianWorkSelect = technicianId;
  }

  leaveType = "D";
  leaveDateRange = "";
  selectUpdateWorkType(leaveType:string){
    this.leaveType = leaveType;
    console.log(leaveType);
  }

  saveLeave(){
    this.leaveDateRange = $('#leaveDateRange').val();
    //console.log(this.leaveDateRange);
    if(this.technicianWorkSelect == "" || this.leaveType == "" || this.brachWorkSelect == ""){
        alert("กรุณากรอกข้อมูลการลาให้ครบ");
    }else{
      $('#loading').show();
      let sDate;
      let eDate;
      if(this.leaveType == 'D'){
        console.log($('#leaveDateRange').val());
        var leaveDateRange = $('#leaveDateRange').val();
        var arrLeaveDateRange = leaveDateRange.split(" - ");
        sDate = new Date(arrLeaveDateRange[0] + " 00:00:00" );
        eDate = new Date(arrLeaveDateRange[1] + " 23:59:00" );
      }else if(this.leaveType == 'T'){
        sDate = new Date($('#leaveDate').val() + " " +$('#leaveStartTime').val());
        eDate = new Date($('#leaveDate').val() + " " +$('#leaveEndTime').val());
      }

      //Create history
      let historyValue = {};
      historyValue['status'] = 'LEAVE';
      historyValue["userRef"] = this.db.doc('/users/'+this.technicianWorkSelect).ref;
      historyValue["branchRef"] = this.db.doc('/branch/'+this.brachWorkSelect).ref;
      historyValue["startDateBook"] = sDate;
      historyValue["endDateBook"] = eDate;

      this.historyService.create(historyValue).then(res =>{
                var userHistoryValue = {};
                var historyId = res.id;
                userHistoryValue["historyId"] = historyId;
                userHistoryValue["historyRef"] = this.db.doc('/history/'+historyId).ref;
                userHistoryValue["userId"] = this.technicianWorkSelect;
                userHistoryValue["userRef"] = this.db.doc('/users/'+this.technicianWorkSelect).ref;

                this.historyService.createUserHistory(userHistoryValue)
                .then(
                  res => {
                    $('#loading').hide();
                    $('#update-work-modal').modal('hide');
                    $('#remark-save-success').modal('show');
                    console.log("createUserHistory success : "+res.id);
                    //Clear value
                    //this.getBranch();
                    this.technicianWorkList = [];
                  });
      });
    }
  }


  //load default script
  loadDefaultScript() {
    $('#dateSchedule2').daterangepicker({
      singleDatePicker: true,
      locale: {
        format: 'DD/MM/YYYY'
      }
    });

    $('#dateSchedule').daterangepicker({
      singleDatePicker: true,
      locale: {
        format: 'DD/MM/YYYY'
      }
    });

    $('#dateSchedule').change(function(){
          //console.log($(this).val());
          //this.changeDateTechWork();
    });

    $('#leaveDateRange').daterangepicker();

    $('#leaveDate').daterangepicker({
      singleDatePicker: true
    });

    $('#leaveStartTime').daterangepicker({
            timePicker : true,
            singleDatePicker:true,
            timePicker24Hour : true,
            timePickerIncrement : 1,
            timePickerSeconds : true,
            locale : {
                format : 'HH:mm:ss'
            }
    }).on('show.daterangepicker', function(ev, picker) {
      picker.container.find(".calendar-table").hide();
    });

    $('#leaveEndTime').daterangepicker({
          timePicker : true,
          singleDatePicker:true,
          timePicker24Hour : true,
          timePickerIncrement : 1,
          timePickerSeconds : true,
          locale : {
              format : 'HH:mm:ss'
          }
    }).on('show.daterangepicker', function(ev, picker) {
      picker.container.find(".calendar-table").hide();
    });

    $('#techStartTime').daterangepicker({
      timePicker : true,
      singleDatePicker:true,
      timePicker24Hour : true,
      timePickerIncrement : 1,
      timePickerSeconds : false,
      locale : {
          format : 'HH:mm'
      }
      }).on('show.daterangepicker', function(ev, picker) {
      picker.container.find(".calendar-table").hide();
      });

      $('#techEndTime').daterangepicker({
          timePicker : true,
          singleDatePicker:true,
          timePicker24Hour : true,
          timePickerIncrement : 1,
          timePickerSeconds : false,
          locale : {
              format : 'HH:mm'
          }
      }).on('show.daterangepicker', function(ev, picker) {
      picker.container.find(".calendar-table").hide();
      });
  }
  //end load default script
}
