export class User{
    id: string;
    fname: string;
    lname: string;
    branchname: string;
    email: string;
    phone: string;
    identificationCode: string;
    username: string;
    password: string;
}
