import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';

@Injectable({
    providedIn: 'root'
})
export class HistoryPhotoService {

    constructor(public db: AngularFirestore) {}

    getPhotoByHistoryId(historyId){
      return this.db.collection('historyPhoto',ref => ref.where('historyId', '==', historyId))
        .get()
    }
}
