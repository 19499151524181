import { Component, OnInit } from "@angular/core";
declare var jquery: any;
declare var $: any;
import { AngularFirestore } from "@angular/fire/firestore";
import { TechnicianService } from "../service/technician.service";
import { BranchService } from "../service/branch.service";
import { UserService } from "../service/user.service";
import { HistoryService } from "../service/history.service";
import { User } from "../model/user.model";
import { CompanyService } from "../service/company.service";
import {Subject, Observable} from 'rxjs';
import {WebcamImage} from '../webcam_default/domain/webcam-image';
import {WebcamUtil} from '../webcam_default/util/webcam.util';
import {WebcamInitError} from '../webcam_default/domain/webcam-init-error';
import { throwError } from 'rxjs';
import { retry, catchError, count } from 'rxjs/operators';
import { HttpClient } from "@angular/common/http";
import { HttpErrorResponse } from "@angular/common/http";
import { FileManagerService } from '../service/fileManager.service';

import {
  FormBuilder,
  FormGroup,
  Validators,
  FormControl
} from "@angular/forms";

@Component({
  selector: "app-technician",
  templateUrl: "./technician.component.html",
  styleUrls: ["./technician.component.css"]
})
export class TechnicianComponent implements OnInit {
  authen;
  technician;
  userList = new Array();
  form: FormGroup;
  editForm: FormGroup;
  historyList = new Array();
  rid: string;
  branchList = new Array();
  userID: string;
  submitted_edit = false;
  submitted_add = false;

  //upload file add
  selectedFile: File;
  imgURL1: any;
  // latest snapshot
  public webcamImage: WebcamImage = null;
  // webcam snapshot trigger
  private trigger: Subject<void> = new Subject<void>();
  // switch to next / previous / specific webcam; true/false: forward/backwards, string: deviceId
  private nextWebcam: Subject<boolean|string> = new Subject<boolean|string>();
  public showWebcam = false;
  public allowCameraSwitch = true;
  public multipleWebcamsAvailable = false;
  public deviceId: string;
  public facingMode: string = 'environment';
  public errors: WebcamInitError[] = [];
  isStart = false;
  carmera = [];

  constructor(
    private fb: FormBuilder,
    private db: AngularFirestore,
    private technicianService: TechnicianService,
    private userService: UserService,
    private historyService: HistoryService,
    private branchService: BranchService,
    public users: User,
    private companyService: CompanyService,
    private fileManagerService: FileManagerService,
    private httpService: HttpClient
  ) {
    this.editForm = fb.group({
      id: ["", Validators.required],
      fname: ["", Validators.required],
      lname: ["", Validators.required],
      identificationCode: ["", [Validators.required, Validators.minLength(13)]],
      phone: ["", [Validators.required, Validators.minLength(10)]],
      email: ["", [Validators.required, Validators.email]],
      username: ["", Validators.required],
      password: ["", Validators.required]
    });

    this.form = fb.group({
      fname: ["", Validators.required],
      lname: ["", Validators.required],
      identificationCode: ["", [Validators.required, Validators.minLength(13)]],
      phone: ["", [Validators.required, Validators.minLength(10)]],
      email: ["", [Validators.required, Validators.email]]
    });
  }

  get fe() {
    return this.editForm.controls;
  }

  get fa() {
    return this.form.controls;
  }

  ngOnInit() {
    this.authen = JSON.parse(localStorage.getItem("authenMenbarberToken"));
    let authRole = this.authen.role;
    if ("admin" == authRole) {
      this.rid = this.authen.branchId;
    } else {
      this.rid = "";
    }
    this.loadDefaultScript();
    this.getTechnician();
    this.getBranch();
    $("#datefrom").daterangepicker();
    this.imgURL1 = "../assets/img/default-user.jpg";
  }

  getBranch() {
    this.branchService.get().subscribe(data => {
      data.forEach(doc => {
        let newItem = doc.data();
        let branch = {};
        branch["id"] = doc.id;
        branch["name"] = newItem.name;
        this.branchList.push(branch);
      });
    });
  }

  getTechnician() {
    $("#loading").show();
    this.userList = [];
    let role = "tech";
    $("#datatables")
      .DataTable()
      .clear()
      .destroy();

    this.userService
      .searchTechnicainByBranch(role, this.rid)
      .subscribe(data => {
        if (data.size == 0) {
          $("#loading").hide();
          setTimeout(() => {
            $("#datatables")
              .DataTable({
                retrieve: true,
                paging: true,
                responsive: true
              })
              .clear()
              .draw();
          }, 0);
        }
        data.forEach(doc => {
          let newItem = doc.data();
          let user = {};
          user["id"] = doc.id;
          user["fname"] = newItem.fname;
          user["lname"] = newItem.lname;
          user["email"] = newItem.email;
          user["phone"] = newItem.phone;
          user["identificationCode"] = newItem.identificationCode;
          user["openTime"] = newItem['openTime'].slice(0,2) + ":" + newItem['openTime'].slice(2);
          user['closeTime'] = newItem['closeTime'].slice(0,2) + ":" + newItem['closeTime'].slice(2);
          user["username"] = newItem.username;
          user["password"] = newItem.password;
          user["branchId"] = newItem.branchId;
          if(typeof(newItem['image_avartar']) === "undefined"){
            user['image_avartar'] = "../assets/img/default-user.jpg";
          }else{
            user['image_avartar'] = newItem['image_avartar'];
          }

          if (newItem.branchRef) {
            newItem.branchRef.get().then(res => {
              user["branchname"] = res.data().name;
              this.userList.push(user);
              $("#loading").hide();
              setTimeout(() => {
                $("#datatables").DataTable({
                  retrieve: true,
                  paging: true,
                  responsive: true
                });
              }, 0);
            });
          } else {
            $("#loading").hide();
            setTimeout(() => {
              $("#datatables")
                .DataTable({
                  retrieve: true,
                  paging: true,
                  responsive: true
                })
                .clear()
                .draw();
            }, 0);
          }
        });
      });
  }

  search() {
    let searchBanch = $("#s_branch").val();
    this.rid = searchBanch;
    this.getTechnician();
  }

  onSubmit(value) {
    this.submitted_add = true;

    // stop here if form is invalid
    if (this.form.invalid) {
      return;
    }
    let role = this.authen.role;
    let branchId = "";
    let technician = {};
    if ("admin" == role) {
      branchId = this.rid;
      technician["branchId"] = this.authen.branchId;
    } else {
      branchId = $("#branch").val();
      technician["branchId"] = this.authen.branchId;
    }

    this.branchService.getByKey(branchId).subscribe(data => {
      let code = data.data().code;
      let techSEQ = data.data().techSeq + 1;
      let branchCode = this.decimalFormat2Digit(Number(code));
      let techSeq = this.decimalFormat4Digit(techSEQ);
      let technicianCode = "T" + branchCode + "-" + techSeq;

      value["branchRef"] = this.db.doc("/branch/" + branchId).ref;
      value["branchId"] = branchId;
      value["role"] = "tech";
      value["username"] = value["phone"];
      value["password"] = value["phone"];
      value["code"] = technicianCode;

      let branch = {};
      branch["techSeq"] = techSEQ;
      this.branchService.update(branchId, branch).then(res => {
        console.log("success");
      }); 
 
      this.technicianService.create(technician).then(res => {
        var technicianId = res.id;

        value["openTime"] = $('#techStartTime').val().slice(0,2) + $('#techStartTime').val().slice(3);
        value["closeTime"] = $('#techEndTime').val().slice(0,2) + $('#techEndTime').val().slice(3);
        this.userService.create(value).then(res => {
          technician["active"] = true;
          technician["userId"] = res.id;
          technician["usersRef"] = this.db.doc("/users/" + res.id).ref;
          technician["branchRef"] = this.db.doc("/branch/" + branchId).ref;
          this.technicianService.update(technicianId, technician).then(res => {
            console.log("success");
            $("#add-modal").modal("hide");
            this.getTechnician();
            //window.location.href = "#/technician";
          });
        });
      });
    });
  }

  decimalFormat2Digit(value) {
    let result = "";
    result = value < 10 ? "0" + value : value;

    return result;
  }

  decimalFormat4Digit(value) {
    let result = "";
    if (value < 10) {
      result = "000" + value;
    } else if (value > 10 && value < 100) {
      result = "00" + value;
    } else if (value > 100 && value < 1000) {
      result = "0" + value;
    } else if (value > 1000) {
      result = "" + value;
    }

    return result;
  }

  submitEditUser(value) {
    this.submitted_edit = true;

    // stop here if form is invalid
    if (this.editForm.invalid) {
      return;
    }
    if (typeof this.selectedFile !== "undefined") {
        const formData = new FormData();
        console.log(this.selectedFile);
        formData.append('image', this.selectedFile);
        formData.append('type', "barber");
        this.fileManagerService.uploadFile(formData)
              .subscribe(res => {
                if (res.code == "200") {
                      value["image_avartar"] = res.data;
                      let role = this.authen.role;
                      let branchId = "";
                      if ("superadmin" == role) {
                        branchId = $("#branchedit").val();
                        value["branchId"] = branchId;
                        value["branchRef"] = this.db.doc("/branch/" + branchId).ref;
                      }
                      value["openTime"] = $('#techStartTimeEdit').val().slice(0,2) + $('#techStartTimeEdit').val().slice(3);
                      value["closeTime"] = $('#techEndTimeEdit').val().slice(0,2) + $('#techEndTimeEdit').val().slice(3);
                  
                      this.userService.updateUser(value.id, value).then(res => {
                        console.log("success");
                        $("#edit-modal").modal("hide");
                        this.getTechnician();
                        //window.location.href = "#/technician";
                      });
                  }//end upload photo
              });
        }else{
              let role = this.authen.role;
              let branchId = "";
              if ("superadmin" == role) {
                branchId = $("#branchedit").val();
                value["branchId"] = branchId;
                value["branchRef"] = this.db.doc("/branch/" + branchId).ref;
              }
              value["openTime"] = $('#techStartTimeEdit').val().slice(0,2) + $('#techStartTimeEdit').val().slice(3);
              value["closeTime"] = $('#techEndTimeEdit').val().slice(0,2) + $('#techEndTimeEdit').val().slice(3);
          
              this.userService.updateUser(value.id, value).then(res => {
                console.log("success");
                $("#edit-modal").modal("hide");
                this.getTechnician();
                //window.location.href = "#/technician";
              });
        }
  }

  deleteTech(userId) {
    this.userID = userId;
  }

  confirmDeleteTech() {
    let userId = this.userID;
    this.userService.delete(userId).then(
      res => {
        this.technicianService.getByUserId(userId).subscribe(
          data => {
            data.forEach(doc => {
              this.technicianService.delete(doc.id).then(
                res => {
                  console.log("success");
                  this.getTechnician();
                  $("#delete-modal").modal("hide");
                  //window.location.href = "#/technician";
                },
                err => {
                  console.log(err);
                }
              );
            });
          },
          err => {
            console.log(err);
          }
        );
      },
      err => {
        console.log(err);
      }
    );
  }

  view(user) {
    this.users = user;
    this.imgURL1 = user['image_avartar'];
    this.historyList = [];
    $(".datatables2")
      .DataTable()
      .clear()
      .destroy();
    this.historyService.searchHistoryByUserId(user.id).subscribe(data => {
      if (data.size == 0) {
        this.historyList = [];
        setTimeout(() => {
          $(".datatables2")
            .DataTable({
              paging: true,
              retrieve: true,
              responsive: true
            })
            .clear()
            .draw();
          $("#view-modal").modal();
        }, 0);
      } else {
        data.forEach(doc => {
          let userHistory = doc.data();
          let historys = {};
          historys["historyId"] = userHistory.historyId;
          if (userHistory.historyRef) {
            userHistory.historyRef.get().then(res => {
              let history = res.data();
              let month = "";
              let day = "";
              if (history.startDate && history.endDate) {
                let startDate = new Date(history.startDate.seconds * 1000);
                let endDate = new Date(history.endDate.seconds * 1000);
                var dd = startDate.getDate();
                var mm = startDate.getMonth() + 1; //January is 0!
                var yyyy = startDate.getFullYear();
                if (dd < 10) {
                  day = "0" + dd;
                } else {
                  day = "" + dd;
                }
                if (mm < 10) {
                  month = "0" + mm;
                } else {
                  month = "" + mm;
                }
                var today =
                  day +
                  " " +
                  this.convertMonthTH(month) +
                  " " +
                  (Number(yyyy) + 543);
                var startTime =
                  (startDate.getHours() < 10
                    ? "0" + startDate.getHours()
                    : startDate.getHours()) +
                  "." +
                  (startDate.getMinutes() < 10
                    ? "0" + startDate.getMinutes()
                    : startDate.getMinutes());
                var endTime =
                  (endDate.getHours() < 10
                    ? "0" + endDate.getHours()
                    : endDate.getHours()) +
                  "." +
                  (endDate.getMinutes() < 10
                    ? "0" + endDate.getMinutes()
                    : endDate.getMinutes());
                historys["createDate"] =
                  today + "  " + startTime + " - " + endTime + " น.";

                historys["price"] = history.price + " บาท";

                // customer
                if (userHistory.customerRef) {
                  userHistory.customerRef.get().then(res => {
                    let customer = res.data();
                    historys["customername"] =
                      customer.fname + " " + customer.lname;
                    historys["customerid"] = res.id;

                    this.historyList.push(historys);
                    setTimeout(() => {
                      $(".datatables2").DataTable({
                        paging: true,
                        retrieve: true,
                        responsive: true
                      });
                    }, 0);
                    $("#view-modal").modal();
                  });
                }
              }
            });
          }
        });
      }
    });
  }

  closeView(){
    $('#view-modal').modal('hide');
  }

  searchHistory(id) {
    let datefrom = $("#datefrom").val();
    let date = datefrom.split("-");
    let startDate = Date.parse(date[0].trim()) / 1000;
    let endDate = Date.parse(date[1].trim()) / 1000;

    console.log(id);

    this.historyList = [];
    $(".datatables2")
      .DataTable()
      .clear()
      .destroy();

    this.historyService.searchHistoryByUserId(id).subscribe(data => {
      if (data.size == 0) {
        this.historyList = [];
        setTimeout(() => {
          $(".datatables2")
            .DataTable({
              paging: true,
              retrieve: true,
              responsive: true
            })
            .clear()
            .draw();
        }, 0);
      } else {
        data.forEach(doc => {
          let userHistory = doc.data();
          let historys = {};
          historys["historyId"] = userHistory.historyId;
          if (userHistory.historyRef) {
            userHistory.historyRef.get().then(res => {
              let history = res.data();
              let start = Number(history.startDate.seconds);
              let month = "";
              let day = "";
              if (
                Number(startDate) <= Number(start) &&
                Number(endDate) >= Number(start)
              ) {
                let startDate = new Date(history.startDate.seconds * 1000);
                let endDate = new Date(history.endDate.seconds * 1000);
                var dd = startDate.getDate();
                var mm = startDate.getMonth() + 1; //January is 0!
                var yyyy = startDate.getFullYear();
                if (dd < 10) {
                  day = "0" + dd;
                } else {
                  day = "" + dd;
                }
                if (mm < 10) {
                  month = "0" + mm;
                } else {
                  month = "" + mm;
                }
                var today =
                  day +
                  " " +
                  this.convertMonthTH(month) +
                  " " +
                  (Number(yyyy) + 543);
                var startTime =
                  (startDate.getHours() < 10
                    ? "0" + startDate.getHours()
                    : startDate.getHours()) +
                  "." +
                  (startDate.getMinutes() < 10
                    ? "0" + startDate.getMinutes()
                    : startDate.getMinutes());
                var endTime =
                  (endDate.getHours() < 10
                    ? "0" + endDate.getHours()
                    : endDate.getHours()) +
                  "." +
                  (endDate.getMinutes() < 10
                    ? "0" + endDate.getMinutes()
                    : endDate.getMinutes());
                historys["createDate"] =
                  today + "  " + startTime + " - " + endTime + " น.";

                historys["price"] = history.price + " บาท";

                // customer
                if (userHistory.customerRef) {
                  userHistory.customerRef.get().then(res => {
                    let customer = res.data();
                    console.log(customer);
                    historys["customername"] =
                      customer.fname + " " + customer.lname;
                    historys["customerid"] = res.id;

                    this.historyList.push(historys);
                    setTimeout(() => {
                      $(".datatables2").DataTable({
                        paging: true,
                        retrieve: true,
                        responsive: true
                      });
                    }, 0);
                  });
                }
              }
            });
          }
        });
      }

      setTimeout(() => {
        if (this.historyList.length == 0) {
          $("#loading").hide();
          setTimeout(() => {
            $(".datatables2").DataTable({
              retrieve: true,
              paging: true,
              responsive: true
            });
          }, 0);
        }
      }, 1000);
    });
  }

  closeModal() {
    $("#view-modal").modal("hide");
  }

  editTech(user) {
    this.users = user;
    $('#techStartTimeEdit').val(user['openTime']);
    $('#techEndTimeEdit').val(user['closeTime']);
    if(typeof(user['image_avartar']) === "undefined"){
      this.imgURL1 = "../assets/img/default-user.jpg";
    }else{
      this.imgURL1 = user['image_avartar'];
    }
  }

  add() {
    this.technician = "";
  }


  onFileChanged(event) {
    this.selectedFile = event.target.files[0];
    console.log(this.selectedFile);
    this.preview(event.target.files,null);
    var ss = this.dataURLtoFile(this.imgURL1,'test.jpg');
    console.log(ss);
  }

  dataURLtoFile(dataurl, filename) {
    try {
      var arr = dataurl.split(','),
          mime = arr[0].match(/:(.*?);/)[1],
          bstr = atob(arr[1]), 
          n = bstr.length, 
          u8arr = new Uint8Array(n);
          
      while(n--){
          u8arr[n] = bstr.charCodeAt(n);
      }
    }
    catch(err) {
      console.log(err);
      // document.getElementById("demo").innerHTML = err.message;
    }
    return new File([u8arr], filename, {type:mime});
  }

  preview(files, type) {
    if (files.length === 0)
      return;
 
    if(type == 'WEBCAM'){
      var mimeType = files.type;
      if (mimeType.match(/image\/*/) == null) {
        //this.message1 = "Only images are supported.";
        return;
      }
  
      var reader = new FileReader();
      reader.readAsDataURL(files); 
      reader.onload = (_event) => { 
        this.imgURL1 = reader.result; 
        // console.log(this.imgURL1);
      }
    }else{
      var mimeType = files[0].type;
      if (mimeType.match(/image\/*/) == null) {
        //this.message1 = "Only images are supported.";
        return;
      }
  
      var reader = new FileReader();
      reader.readAsDataURL(files[0]); 
      reader.onload = (_event) => { 
        this.imgURL1 = reader.result; 
        // console.log(this.imgURL1);
      }
    }
  }
  

  convertMonthTH(m) {
    var month = "";
    if ("01" == m) {
      month = "มกราคม";
    } else if ("02" == m) {
      month = "กุมภาพันธ์";
    } else if ("03" == m) {
      month = "มีนาคม";
    } else if ("04" == m) {
      month = "เมษายน";
    } else if ("05" == m) {
      month = "พฤษภาคม";
    } else if ("06" == m) {
      month = "มิถุนายน";
    } else if ("07" == m) {
      month = "กรกฎาคม";
    } else if ("08" == m) {
      month = "สิงหาคม";
    } else if ("09" == m) {
      month = "กันยายน";
    } else if ("10" == m) {
      month = "ตุลาคม";
    } else if ("11" == m) {
      month = "พฤศจิกายน";
    } else if ("12" == m) {
      month = "ธันวาคม";
    }
    return month;
  }

  //load default script
  loadDefaultScript() {
    $("#add-modal").modal({
      show: false,
      backdrop: "static"
    });

    $("#edit-modal").modal({
      show: false,
      backdrop: "static"
    });

    $("#view-modal").modal({
      show: false,
      backdrop: "static"
    });

    $("#delete-modal").modal({
      show: false,
      backdrop: "static"
    });

    $('#techStartTimeEdit').daterangepicker({
      timePicker : true,
      singleDatePicker:true,
      timePicker24Hour : true,
      timePickerIncrement : 1,
      timePickerSeconds : false,
      locale : {
          format : 'HH:mm'
      }
      }).on('show.daterangepicker', function(ev, picker) {
      picker.container.find(".calendar-table").hide();
      });

      $('#techEndTimeEdit').daterangepicker({
          timePicker : true,
          singleDatePicker:true,
          timePicker24Hour : true,
          timePickerIncrement : 1,
          timePickerSeconds : false,
          locale : {
              format : 'HH:mm'
          }
      }).on('show.daterangepicker', function(ev, picker) {
      picker.container.find(".calendar-table").hide();
      });


      $('#techStartTime').daterangepicker({
        timePicker : true,
        singleDatePicker:true,
        timePicker24Hour : true,
        timePickerIncrement : 1,
        timePickerSeconds : false,
        locale : {
            format : 'HH:mm'
        }
        }).on('show.daterangepicker', function(ev, picker) {
        picker.container.find(".calendar-table").hide();
        });
  
        $('#techEndTime').daterangepicker({
            timePicker : true,
            singleDatePicker:true,
            timePicker24Hour : true,
            timePickerIncrement : 1,
            timePickerSeconds : false,
            locale : {
                format : 'HH:mm'
            }
        }).on('show.daterangepicker', function(ev, picker) {
        picker.container.find(".calendar-table").hide();
        });
  }
  //end load default script
}
