import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { CusHistoryComponent } from './cushistory.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule
  ],
  declarations: [CusHistoryComponent],
  exports: [
    CusHistoryComponent
  ],
  providers: [
  ]
})
export class CusHistoryModule { }
