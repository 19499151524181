import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';

@Injectable({
    providedIn: 'root'
})
export class CustomerConfigService {

    constructor(public db: AngularFirestore) {}

    update(key, value){
      return this.db.collection('customerSettings').doc(key).update(value);
    }

    get(){
      return this.db.collection('customerSettings').snapshotChanges();
    }
}
