import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';

@Injectable({
    providedIn: 'root'
})
export class BanchServicepackageBanchMenuService {

    constructor(public db: AngularFirestore) {}

    create(value){
      return this.db.collection('branchServicepackagebranchMenu').add(value);
    }

    getByKey(key){
      return this.db.collection('branchServicepackagebranchMenu').doc(key).get();
    }

    set(key, value){
      return this.db.collection('branchServicepackagebranchMenu').doc(key).set(value);
    }

    update(key, value){
      return this.db.collection('branchServicepackagebranchMenu').doc(key).update(value);
    }

    delete(key){
      return this.db.collection('branchServicepackagebranchMenu').doc(key).delete();
    }

    get(){
      return this.db.collection('branchServicepackagebranchMenu').get();
    }

    getByBranchServicepackage(value){
      return this.db.collection('branchServicepackagebranchMenu',ref => ref.where('branchServicepackageId', '==', value)).get();
    }

}
