import { Component } from '@angular/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'Menbarber';
  authenMenbarberToken = "";
  startMenprocess = "";
  ngOnInit() {
    this.authenMenbarberToken = localStorage.getItem('authenMenbarberToken');
    this.startMenprocess = localStorage.getItem('startMenprocess');
  }
}
