import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';

@Injectable({
    providedIn: 'root'
})
export class HistoryService {

    constructor(public db: AngularFirestore) {}

    create(value){
      return this.db.collection('history').add(value);
    }

    createUserHistory(value){
      return this.db.collection('userHistory').add(value);
    }

    createHistoryPhoto(value){
      return this.db.collection('historyPhoto').add(value);
    }

    update(key, value){
      return this.db.collection('history').doc(key).update(value);
    }

    get(){
      return this.db.collection('userHistory').get();
    }

    getByKey(key){
      return this.db.collection('history').doc(key).snapshotChanges();
    }

    delete(key){
      return this.db.collection('history').doc(key).delete();
    }

    deleteUserHistory(key){
      return this.db.collection('userHistory').doc(key).delete();
    }

    searchHistoryByUserId(value){
      return this.db.collection('userHistory',ref => ref.where('userId', '==', value)).get();
    }

    getUserHistoryByUserId(userId){
      return this.db.collection('userHistory',ref => ref.where('userId', '==', userId))
        .get()
    }

    getHistoryByBranchIdAndStatus(branchId,status){
      return this.db.collection('history',ref => ref.where('branchId', '==', branchId)
        .where('status', '==', status)
        ).get()
    }

    getHistoryByStatus(status){
      return this.db.collection('history',ref => ref.where('status', '==', status)
        ).get()
    }

    getUserHistoryBycriteria(criteria){
      if (criteria.technician == "" || criteria.technician == "all"){
        return this.db.collection('userHistory').get();
      }  else if (criteria.technician != "" && criteria.technician != "all") {
        return this.db.collection('userHistory',ref => ref.where('userId', '==', criteria.technician))
          .get();
      }
    }

    getUserHistoryByCustomerId(customerId){
      return this.db.collection('userHistory',ref => ref.where('customerId', '==', customerId))
        .get()
    }

    getUserHistoryByHistoryId(historyId){
      return this.db.collection('userHistory',ref => ref.where('historyId', '==', historyId))
        .get()
    }

    /*searchByBookDate(value){
      return this.db.collection('history',ref => ref.orderBy('role').startAt(value)).get();
    }*/

    searchByStartLeaveDate(value){
      return this.db.collection('history',ref => ref.where('startLeaveDate', '>=', value)).get()
    }

    searchByEndLeaveDate(value){
      return this.db.collection('history',ref => ref.where('endLeaveDate', '<=', value)).get()
    }

    searchByStatus(value){
      return this.db.collection('history',ref => ref.where('status', '==', value)).get()
    }
}
