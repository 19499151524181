import { Component, OnInit } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { BranchService } from "../../service/branch.service";
import { UserService } from "../../service/user.service";
import { TechnicianService } from "../../service/technician.service";
import { AngularFirestore } from "@angular/fire/firestore";
import { PhotoService } from "../../service/photo.service";
import { BranchPhotoService } from "src/app/service/branchphoto.service";
import { FileManagerService } from "../../service/fileManager.service";
import { BranchMenuService } from "../../service/branchmenu.service";
import { MouseEvent } from "@agm/core";
declare var jquery: any;
declare var $: any;
import {
  FormBuilder,
  FormGroup,
  Validators,
  FormControl
} from "@angular/forms";

import { HttpClient } from "@angular/common/http";
import { HttpErrorResponse } from "@angular/common/http";

@Component({
  selector: "app-branch-edit",
  templateUrl: "./branch_edit.component.html",
  styleUrls: ["./branch_edit.component.css"]
})
export class BranchEditComponent implements OnInit {
  rid;
  provinceId;
  amphurId;
  authen = {};
  branch = {};
  manager = {};
  technicianList = [];
  users = {};
  editForm: FormGroup;
  addForm: FormGroup;
  form: FormGroup;
  countryJson;
  amphurJson;
  thumbonJson;
  postcode;
  submitted = false;
  submitted_edit = false;
  submitted_add = false;
  userID: string;
  captureFlg = "";
  photoList = [];

  urlPhoto = [];
  urlPhotoUpload = [];
  initIndex = 0;
  branchMenu = [];

  constructor(
    private fb: FormBuilder,
    private db: AngularFirestore,
    private activatedRoute: ActivatedRoute,
    private branchService: BranchService,
    private userService: UserService,
    private photoService: PhotoService,
    private technicianService: TechnicianService,
    private branchPhotoService: BranchPhotoService,
    private fileManagerService: FileManagerService,
    private httpService: HttpClient,
    private branchMenuService: BranchMenuService
  ) {
    this.editForm = fb.group({
      id: ["", Validators.required],
      fname: ["", Validators.required],
      lname: ["", Validators.required],
      identificationCode: ["", [Validators.required, Validators.minLength(13)]],
      phone: ["", [Validators.required, Validators.minLength(10)]],
      email: ["", [Validators.required, Validators.email]],
      username: ["", Validators.required],
      password: ["", Validators.required]
    });

    this.addForm = fb.group({
      id: ["", Validators.required],
      tax: ["", [Validators.required, Validators.minLength(13)]],
      name: ["", Validators.required],
      address: ["", Validators.required],
      phone: ["", [Validators.required, Validators.minLength(10)]],
      email: ["", [Validators.required, Validators.email]],
      fname: ["", Validators.required],
      lname: ["", Validators.required],
      memail: ["", [Validators.required, Validators.email]],
      postcode: ["", Validators.required],
      password: ["", Validators.required],
      province: ["", Validators.required],
      amphur: ["", Validators.required],
      thumbon: ["", Validators.required],
      chairamt: [0]
    });

    this.form = fb.group({
      fname: ["", Validators.required],
      lname: ["", Validators.required],
      identificationCode: ["", [Validators.required, Validators.minLength(13)]],
      phone: ["", [Validators.required, Validators.minLength(10)]],
      email: ["", [Validators.required, Validators.email]]
    });
  }
  // convenience getter for easy access to form fields
  get f() {
    return this.addForm.controls;
  }

  get fe() {
    return this.editForm.controls;
  }

  get fa() {
    return this.form.controls;
  }

  ngOnInit() {
    this.loadDefaultScript();
    this.getProvince();

    //get params
    this.activatedRoute.params.forEach(urlParams => {
      let param = urlParams["rid"].replace("#", "");
      this.rid = param.split(",")[0];
      this.provinceId = param.split(",")[1];
      this.amphurId = param.split(",")[2];
    });
    this.getBranchDetails(this.rid);
    this.getTechnicianList(this.rid);
    this.getManagerBranch(this.rid);
    this.getBranchPhoto(this.rid);
    this.getBranchMenuService();
  }

  getBranchDetails(branchID) {
    $("#loading").show();
    this.branchService.getByKey(branchID).subscribe(data => {
      this.branch = data.data();
      this.postcode = data.data().postcode;
      this.branch["province"] =
        data.data().provinceId + "|" + data.data().province;
      this.branch["amphur"] = data.data().amphurId + "|" + data.data().amphur;
      this.branch["thumbon"] =
        data.data().thumbonId + "|" + data.data().thumbon;

      if (data.data().capturesys) {
        this.captureFlg = "checked";
      }
      this.branch['openTime'] = this.branch['openTime'].slice(0,2) + ":" + this.branch['openTime'].slice(2);
      this.branch['closeTime'] = this.branch['closeTime'].slice(0,2) + ":" + this.branch['closeTime'].slice(2);
      
      this.lat = data.data().lat;
      this.lng = data.data().lng;
      this.markers = [];
      this.markers.push({
        lat: data.data().lat,
        lng: data.data().lng,
        draggable: true
      });
      $("#loading").hide();
    });
  }

  getProvince() {
    this.httpService.get("./assets/json/data.json").subscribe(
      data => {
        this.countryJson = data as string[]; // FILL THE ARRAY WITH DATA.
        this.amphurJson = data[this.provinceId][1];
        this.thumbonJson = data[this.provinceId][1][this.amphurId][1];
      },
      (err: HttpErrorResponse) => {
        console.log(err.message);
      }
    );
  }

  getAmphur(e) {
    this.thumbonJson = [];
    if (e == "") {
      this.amphurJson = [];
    } else {
      this.postcode = "";
      let value = e.split("|");
      this.amphurJson = this.countryJson[value[0]][1];
    }
  }

  getThumbon(e) {
    if (e == "") {
      this.postcode = "";
    } else {
      let value = e.split("|");
      this.getLatLong(value[1]);
      this.thumbonJson = this.amphurJson[value[0]][1];
      let thumbons = this.thumbonJson[1];
      this.postcode = thumbons[1][0];
    }
  }

  getPost(e) {
    let value = e.split("|");
    let thumbons = this.thumbonJson[value[0]][1];
    this.postcode = thumbons[0];
    //console.log(this.postcode)
  }

  getTechnicianList(branchID) {
    this.technicianList = [];
    $("#datatables")
      .DataTable()
      .clear()
      .destroy();
    $("#loading").show();
    this.userService
      .searchTechnicainByBranch("tech", branchID)
      .subscribe(data => {
        if (data.size == 0) {
          $("#loading").hide();
          setTimeout(() => {
            $("#datatables")
              .DataTable({
                retrieve: true,
                paging: false,
                responsive: true
              })
              .clear()
              .draw();
          }, 0);
        }
        data.forEach(doc => {
          let newItem = doc.data();
          newItem["id"] = doc.id;
          newItem["openTime"] = newItem['openTime'].slice(0,2) + ":" + newItem['openTime'].slice(2);
          newItem['closeTime'] = newItem['closeTime'].slice(0,2) + ":" + newItem['closeTime'].slice(2);
          this.technicianList.push(newItem);
          $("#loading").hide();

          setTimeout(() => {
            $("#datatables").DataTable({
              retrieve: true,
              paging: true,
              responsive: true
            });
          }, 0);
        });
      });
  }

  getManagerBranch(branchID) {
    this.userService
      .searchTechnicainByBranch("admin", branchID)
      .subscribe(data => {
        data.forEach(doc => {
          let newItem = doc.data();
          newItem["id"] = doc.id;
          this.manager = newItem;
        });
      });
  }

  submitEditBranch(value) {
    this.submitted = true;

    // stop here if form is invalid
    if (this.addForm.invalid) {
      $("#branchName").focus();
      return;
    }
    let branch = {};
    let manager = {};
    branch["name"] = value.name;
    branch["tax"] = value.tax;
    branch["address"] = value.address;
    branch["phone"] = value.phone;
    branch["email"] = value.email;
    branch["province"] = value.province.split("|")[1];
    branch["amphur"] = value.amphur.split("|")[1];
    branch["thumbon"] = value.thumbon.split("|")[1];
    branch["postcode"] = value.postcode;
    branch["provinceId"] = Number(value.province.split("|")[0]);
    branch["amphurId"] = Number(value.amphur.split("|")[0]);
    branch["thumbonId"] = Number(value.thumbon.split("|")[0]);

    branch["chairamt"] = value.chairamt;
    branch["capturesys"] = $("#check_id").is(":checked");
    branch["openTime"] = $('#leaveStartTime').val().slice(0,2) + $('#leaveStartTime').val().slice(3);
    branch["closeTime"] = $('#leaveEndTime').val().slice(0,2) + $('#leaveEndTime').val().slice(3);

    branch["lat"] = this.markers[0].lat;
    branch["lng"] = this.markers[0].lng;

    manager["fname"] = value.fname;
    manager["lname"] = value.lname;
    manager["email"] = value.memail;
    manager["password"] = value.password;

    //insert photo
    let branchID = this.rid;
    // console.log(this.urlPhotoUpload);
    if (this.urlPhotoUpload.length > 0) {
      for (let i = 0; i < this.urlPhotoUpload.length; i++) {
        const formData = new FormData();
        formData.append("image", this.urlPhotoUpload[i]);
        formData.append("type", "branch");

        this.fileManagerService.uploadFile(formData).subscribe(res => {
          if (res.code == "200") {
            var photoValue = {};
            photoValue["pathUrl"] = res.data;
            photoValue["type"] = "BRANCH";
            this.photoService.create(photoValue).then(res => {
              var photoId = res.id;
              var branchPhoto = {};
              branchPhoto["seq"] = this.initIndex + (i + 1);
              branchPhoto["branchId"] = branchID;
              branchPhoto["branchRef"] = this.db.doc("/branch/" + branchID).ref;
              branchPhoto["photoId"] = photoId;
              branchPhoto["photoRef"] = this.db.doc("/photo/" + photoId).ref;
              this.branchPhotoService.create(branchPhoto).then(res => {
                console.log("upload photo " + (i + 1) + " success.");
              });
            });
          }
        });
      }
    }

    let managerID = value.id;
    this.branchService.update(this.rid, branch).then(res => {
      console.log("success");
      this.userService.update(managerID, manager).then(res => {
        console.log("success");
        window.location.href = "#/branch";
      });
    });
  }

  view(user) {
    this.users = user;
  }

  editTech(user) {
    console.log(this.users);
    $('#techStartTimeEdit').val(user['openTime']);
    $('#techEndTimeEdit').val(user['closeTime']);
    this.users = user;
  }

  onSubmit(value) {
    this.submitted_add = true;

    // stop here if form is invalid
    if (this.form.invalid) {
      $("#branchName").focus();
      return;
    }
    let branchId = this.rid;
    this.branchService.getByKey(branchId).subscribe(data => {
      let code = data.data().code;
      let techSEQ = data.data().techSeq + 1;
      let branchCode = this.decimalFormat2Digit(Number(code));
      let techSeq = this.decimalFormat4Digit(techSEQ);
      let technicianCode = "T" + branchCode + "-" + techSeq;

      var technician = {};
      value["branchRef"] = this.db.doc("/branch/" + branchId).ref;
      value["branchId"] = this.rid;
      value["role"] = "tech";
      value["username"] = technicianCode;
      value["password"] = "P@ssword";
      value["code"] = technicianCode;
      value["openTime"] = $('#techStartTime').val().slice(0,2) + $('#techStartTime').val().slice(3);
      value["closeTime"] = $('#techEndTime').val().slice(0,2) + $('#techEndTime').val().slice(3);

      let branch = {};
      branch["techSeq"] = techSEQ;
      this.branchService.update(branchId, branch).then(res => {
        console.log("success");
      });

      this.technicianService.create(technician).then(res => {
        var technicianId = res.id;
        this.userService.create(value).then(res => {
          technician["active"] = true;
          technician["userId"] = res.id;
          technician["usersRef"] = this.db.doc("/users/" + res.id).ref;
          technician["branchRef"] = this.db.doc("/branch/" + branchId).ref;
          this.technicianService.update(technicianId, technician).then(res => {
            console.log("success");
            $("#add-modal").modal("hide");

            this.getTechnicianList(this.rid);
          });
        });
      });
      //console.log(value);
    });
  }

  decimalFormat2Digit(value) {
    let result = "";
    result = value < 10 ? "0" + value : value;

    return result;
  }

  decimalFormat4Digit(value) {
    let result = "";
    if (value < 10) {
      result = "000" + value;
    } else if (value > 10 && value < 100) {
      result = "00" + value;
    } else if (value > 100 && value < 1000) {
      result = "0" + value;
    } else if (value > 1000) {
      result = "" + value;
    }

    return result;
  }

  submitEditUser(value) {
    this.submitted_edit = true;

    // stop here if form is invalid
    if (this.editForm.invalid) {
      return;
    }

    value["openTime"] = $('#techStartTimeEdit').val().slice(0,2) + $('#techStartTimeEdit').val().slice(3);
    value["closeTime"] = $('#techEndTimeEdit').val().slice(0,2) + $('#techEndTimeEdit').val().slice(3);
    this.userService.updateUser(value.id, value).then(res => {
      console.log("success");
      $("#edit-modal").modal("hide");
      this.getTechnicianList(this.rid);
    });
  }

  deleteTech(userId) {
    this.userID = userId;
  }

  getBranchPhoto(branchID) {
    this.branchPhotoService.getPhotoByBranchId(branchID).subscribe(data => {
      data.forEach(doc => {
        let branchPhoto = doc.data();
        if (branchPhoto.photoRef) {
          branchPhoto.photoRef.get().then(res => {
            console.log(doc.id);
            this.photoService.getByKey(res.id).subscribe(data => {
              let item = data.payload.data();
              //console.log(data.payload.data());
              let photo = {};
              photo["pathUrl"] = item["pathUrl"];
              photo["seq"] = branchPhoto.seq;
              photo["id"] = doc.id;
              this.photoList.push(photo);
              setTimeout(() => {
                this.photoList.sort((a, b) => Number(a.seq) - Number(b.seq));
                for (let i = 0; i < this.photoList.length; i++) {
                  if (this.photoList[i].seq > this.initIndex) {
                    this.initIndex = this.photoList[i].seq;
                  }
                }
              }, 1);
            });
          });
        }
      });
    });
  }

  getBranchMenuService(){
    this.branchMenu = [];
    this.branchMenuService.getByBranch(this.rid).subscribe(data=>{
        data.forEach(doc =>{
            let branchMenu = doc.data();
            let menu = {};
            menu['id'] = doc.id;
            menu['price'] = branchMenu['price'];
            console.log(menu);
            if(branchMenu.menuRef){
              branchMenu.menuRef.get().then(res=>{
                menu['name'] = res.data().name;
                this.branchMenu.push(menu);
              });
            }
        })
    })
  }

  unSelectedPhotoFile(position) {
    // console.log(position);
    this.urlPhoto.splice(position, 1);
    this.urlPhotoUpload.splice(position, 1);
  }

  unSelectedPhotoFileFromBase(position, id) {
    console.log(id);
    this.photoList.splice(position, 1);
    this.branchPhotoService.delete(id);
  }

  onSelectPhotoFile(event) {
    if (event.target.files && event.target.files[0]) {
      var filesAmount = event.target.files.length;
      for (let i = 0; i < filesAmount; i++) {
        this.urlPhotoUpload.push(event.target.files[i]);
        var reader = new FileReader();

        reader.onload = (event: any) => {
          //console.log(event.target.result);
          this.urlPhoto.push(event.target.result);
        };
        console.log(this.urlPhoto);

        reader.readAsDataURL(event.target.files[i]);
      }
    }
  }

  confirmDeleteTech() {
    let userId = this.userID;
    this.userService.delete(userId).then(
      res => {
        this.technicianService.getByUserId(userId).subscribe(
          data => {
            data.forEach(doc => {
              this.technicianService.delete(doc.id).then(
                res => {
                  console.log("success");
                  this.getTechnicianList(this.rid);
                  $("#delete-modal").modal("hide");
                },
                err => {
                  console.log(err);
                }
              );
            });
          },
          err => {
            console.log(err);
          }
        );
      },
      err => {
        console.log(err);
      }
    );
  }

  back() {
    window.location.href = "#/branch";
  }

  //load default script
  loadDefaultScript() {
    $("#add-modal").modal({
      show: false,
      backdrop: "static"
    });

    $("#edit-modal").modal({
      show: false,
      backdrop: "static"
    });

    $("#delete-modal").modal({
      show: false,
      backdrop: "static"
    });

    $('#leaveStartTime').daterangepicker({
      timePicker : true,
      singleDatePicker:true,
      timePicker24Hour : true,
      timePickerIncrement : 1,
      timePickerSeconds : false,
      locale : {
          format : 'HH:mm'
      }
      }).on('show.daterangepicker', function(ev, picker) {
      picker.container.find(".calendar-table").hide();
      });

      $('#leaveEndTime').daterangepicker({
          timePicker : true,
          singleDatePicker:true,
          timePicker24Hour : true,
          timePickerIncrement : 1,
          timePickerSeconds : false,
          locale : {
              format : 'HH:mm'
          }
      }).on('show.daterangepicker', function(ev, picker) {
      picker.container.find(".calendar-table").hide();
      });

      $('#techStartTime').daterangepicker({
        timePicker : true,
        singleDatePicker:true,
        timePicker24Hour : true,
        timePickerIncrement : 1,
        timePickerSeconds : false,
        locale : {
            format : 'HH:mm'
        }
        }).on('show.daterangepicker', function(ev, picker) {
        picker.container.find(".calendar-table").hide();
        });
  
        $('#techEndTime').daterangepicker({
            timePicker : true,
            singleDatePicker:true,
            timePicker24Hour : true,
            timePickerIncrement : 1,
            timePickerSeconds : false,
            locale : {
                format : 'HH:mm'
            }
        }).on('show.daterangepicker', function(ev, picker) {
        picker.container.find(".calendar-table").hide();
        });

        $('#techStartTimeEdit').daterangepicker({
          timePicker : true,
          singleDatePicker:true,
          timePicker24Hour : true,
          timePickerIncrement : 1,
          timePickerSeconds : false,
          locale : {
              format : 'HH:mm'
          }
          }).on('show.daterangepicker', function(ev, picker) {
          picker.container.find(".calendar-table").hide();
          });
    
          $('#techEndTimeEdit').daterangepicker({
              timePicker : true,
              singleDatePicker:true,
              timePicker24Hour : true,
              timePickerIncrement : 1,
              timePickerSeconds : false,
              locale : {
                  format : 'HH:mm'
              }
          }).on('show.daterangepicker', function(ev, picker) {
          picker.container.find(".calendar-table").hide();
          });
  }
  //end load default script

  // google maps zoom level
  zoom: number = 8;

  // initial center position for the map
  lat: number = 13.756331;
  lng: number = 100.501762;

  getLatLong(district) {
    this.httpService.get("./assets/json/lat-long.json").subscribe(
      data => {
        let chk = 0;
        for (let i = 0; i < 1163; i++) {
          if (data[i].district == district) {
            chk++;
            if (chk == 1) {
              this.markers = [];
              this.markers.push({
                lat: data[i].lat,
                lng: data[i].lng,
                draggable: true
              });
              this.lat = data[i].lat;
              this.lng = data[i].lng;
            }
          }
        }
      },
      (err: HttpErrorResponse) => {
        console.log(err.message);
      }
    );
  }

  clickedMarker(label: string, index: number) {
    console.log(`clicked the marker: ${label || index}`);
  }

  mapClicked($event: MouseEvent) {
    this.markers = [];
    this.markers.push({
      lat: $event.coords.lat,
      lng: $event.coords.lng,
      draggable: true
    });
  }

  markerDragEnd(m: marker, $event: MouseEvent) {
    this.markers = [];
    this.markers.push({
      lat: $event.coords.lat,
      lng: $event.coords.lng,
      draggable: true
    });
    console.log(this.markers);
  }

  markers: marker[] = [];
}

// just an interface for type safety.
interface marker {
  lat: number;
  lng: number;
  label?: string;
  draggable: boolean;
}
