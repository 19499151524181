import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { FreelanceListComponent } from './freelancelist.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule
  ],
  declarations: [FreelanceListComponent],
  exports: [
    FreelanceListComponent
  ],
  providers: [
  ]
})
export class FreelanceListModule { }
