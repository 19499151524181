import { Component, OnInit } from "@angular/core";
import { BranchService } from "../service/branch.service";
import { UserService } from "../service/user.service";
import { TechnicianService } from "../service/technician.service";
declare var jquery: any;
declare var $: any;
import { HttpClient } from "@angular/common/http";
import { HttpErrorResponse } from "@angular/common/http";

@Component({
  selector: "app-branch",
  templateUrl: "./branch.component.html",
  styleUrls: ["./branch.component.css"]
})
export class BranchComponent implements OnInit {
  branchList = new Array();
  countryJson;
  branchID: string;
  authen;
  role = "";
  branchAuthen = "";

  constructor(
    private branchService: BranchService,
    private technicianService: TechnicianService,
    private userService: UserService,
    private httpService: HttpClient
  ) {}

  ngOnInit() {
    this.loadDefaultScript();
    this.authen = JSON.parse(localStorage.getItem("authenMenbarberToken"));
    this.role = this.authen.role;
    this.branchAuthen = this.authen.branchId;
    this.getProvince();
    this.getBranch("");
  }

  getProvince() {
    this.httpService.get("./assets/json/data.json").subscribe(
      data => {
        this.countryJson = data as string[]; // FILL THE ARRAY WITH DATA.
      },
      (err: HttpErrorResponse) => {
        console.log(err.message);
      }
    );
  }

  getBranch(provinceId) {
    this.branchList = [];
    $("#branch_datatables")
      .DataTable()
      .clear()
      .destroy();
    $("#loading").show();
    this.branchService.searchByCriteria(provinceId).subscribe(data => {
      data.forEach(doc => {
        let newItem = doc.data();
        newItem["id"] = doc.id;
        if (newItem.province != undefined && newItem.province != "undefined") {
          newItem["thumbon"] =
            newItem.provinceId == 1
              ? "แขวง" + newItem.thumbon
              : "ตำบล" + newItem.thumbon;
          newItem["amphur"] =
            newItem.provinceId == 1
              ? "เขต" + newItem.amphur
              : "อำเภอ" + newItem.amphur;
          newItem["province"] = "จังหวัด" + newItem.province;
        }

        this.userService
          .searchTechnicainByBranch("tech", doc.id)
          .subscribe(res => {
            newItem["amount"] = res.size;
            if(this.role == 'superadmin'){
              this.branchList.push(newItem);
            }else{
              if(this.branchAuthen == newItem["id"]){
                this.branchList.push(newItem);
              }
            }
            
            $("#loading").hide();
            setTimeout(() => {
              $("#branch_datatables").DataTable({
                retrieve: true,
                paging: true,
                responsive: true
              });
            }, 1000);
          });
      });

      if (data.size == 0) {
        $("#loading").hide();
        setTimeout(() => {
          $("#branch_datatables")
            .DataTable({
              retrieve: true,
              paging: false,
              responsive: true
            })
            .clear()
            .draw();
        }, 1000);
      }
    });
  }

  search() {
    let provinceId = $("#province").val();
    this.getBranch(provinceId);
  }

  deleteBranch(id) {
    this.branchID = id;
  }

  confirmDeleteBranch() {
    let branchId = this.branchID;
    this.branchService.delete(branchId)
    .then(
      res => {
        this.technicianService.getByBranchId(branchId).subscribe(
          data => {
            data.forEach(doc => {
              this.technicianService.delete(doc.id).then(
                res => {
                  console.log("success");
                  $("#delete-modal").modal("hide");
                });
            });
          });
    });
    setTimeout(() => {
      this.branchService.delete(branchId)
      .then(
        res => {
          this.getBranch("");
      });   
    }, 0);
  }

  //load default script
  loadDefaultScript() {
    $("#delete-modal").modal({
      show: false,
      backdrop: "static"
    });
  }
  //end load default script
}
