import { Component, OnInit } from "@angular/core";
declare var jquery: any;
declare var $: any;
import { AngularFirestore } from "@angular/fire/firestore";
import { TechnicianService } from "../service/technician.service";
import { BranchService } from "../service/branch.service";
import { UserService } from "../service/user.service";
import { HistoryService } from "../service/history.service";
import { User } from "../model/user.model";
import { CompanyService } from "../service/company.service";
import {Subject, Observable} from 'rxjs';
import {WebcamImage} from '../webcam_default/domain/webcam-image';
import {WebcamUtil} from '../webcam_default/util/webcam.util';
import {WebcamInitError} from '../webcam_default/domain/webcam-init-error';
import { throwError } from 'rxjs';
import { retry, catchError, count } from 'rxjs/operators';
import { HttpClient } from "@angular/common/http";
import { HttpErrorResponse } from "@angular/common/http";
import { FileManagerService } from '../service/fileManager.service';
import { Router, ActivatedRoute } from '@angular/router';
import { PackageService } from "../service/package.service";
import { UserMenuService } from "../service/userMenu.service";

import {
  FormBuilder,
  FormGroup,
  Validators,
  FormControl
} from "@angular/forms";
import { BranchMenuService } from '../service/branchmenu.service';

@Component({
  selector: "app-technicianedit",
  templateUrl: "./technicianedit.component.html",
  styleUrls: ["./technicianedit.component.css"]
})
export class TechnicianEditComponent implements OnInit {
  authen;
  technician;
  userList = new Array();
  form: FormGroup;
  editForm: FormGroup;
  historyList = new Array();
  rid: string;
  branchList = new Array();
  userID: string;
  submitted_edit = false;
  submitted_add = false;

  //upload file add
  selectedFile: File;
  imgURL1: any;
  // latest snapshot
  public webcamImage: WebcamImage = null;
  // webcam snapshot trigger
  private trigger: Subject<void> = new Subject<void>();
  // switch to next / previous / specific webcam; true/false: forward/backwards, string: deviceId
  private nextWebcam: Subject<boolean|string> = new Subject<boolean|string>();
  public showWebcam = false;
  public allowCameraSwitch = true;
  public multipleWebcamsAvailable = false;
  public deviceId: string;
  public facingMode: string = 'environment';
  public errors: WebcamInitError[] = [];
  isStart = false;
  carmera = [];
  userId;
  users;
  menuList = [];
  techMenuList = [];
  currentBranchId = "";
  isFranchise = false;

  constructor(
    private fb: FormBuilder,
    private db: AngularFirestore,
    private technicianService: TechnicianService,
    private userService: UserService,
    private historyService: HistoryService,
    private branchService: BranchService,
    private companyService: CompanyService,
    private fileManagerService: FileManagerService,
    private httpService: HttpClient,
    private activatedRoute: ActivatedRoute,
    private packageService: PackageService,
    private userMenuService: UserMenuService,
    private branchMenuService: BranchMenuService
  ) {
    this.editForm = fb.group({
      id: ["", Validators.required],
      fname: ["", Validators.required],
      lname: ["", Validators.required],
      identificationCode: ["", [Validators.required, Validators.minLength(13)]],
      phone: ["", [Validators.required, Validators.minLength(10)]],
      email: ["", [Validators.required, Validators.email]],
      username: ["", Validators.required],
      password: ["", Validators.required]
    }); 
  }

  get fe() {
    return this.editForm.controls;
  } 

  ngOnInit() {
    this.authen = JSON.parse(localStorage.getItem("authenMenbarberToken"));
    console.log(this.authen);
    this.isFranchise = this.authen.franchise;
    let authRole = this.authen.role;
    if ("admin" == authRole) {
      this.rid = this.authen.branchId;
    } else {
      this.rid = "";
    }

    //get params
    this.activatedRoute.params.forEach((urlParams) => { 
      this.userId = urlParams['cid'].replace('#', '');
      console.log("userId : "+this.userId);
      this.getUser(this.userId);
      this.getMenuTech(this.userId);
    });
    
    this.loadDefaultScript();
    $("#datefrom").daterangepicker();
    this.imgURL1 = "../assets/img/default-user.jpg";
  }

  getMenuTech(userId){
    this.userMenuService.getByUser(userId).subscribe(data=>{
      if(data.size==0){
        this.getMenu();
      }
      let count = 0;
      data.forEach(doc => {
          this.techMenuList.push(doc.data());
          count++;
          if(count==data.size){
            this.getMenu();
          }
      });
    });
  }

  selectMenu(menu){
    this.techMenuList.push(menu);
    for(let i=0;i<this.menuList.length;i++){
      if(menu['id'] == this.menuList[i]['id']){
        this.menuList.splice(i, 1);   
      }
    }
  }

  removeMenu(menu){
    this.menuList.push(menu);
    for(let i=0;i<this.techMenuList.length;i++){
      if(menu['id'] == this.techMenuList[i]['id']){
        this.techMenuList.splice(i, 1);   
      }
    }
  }

  getUser(userId){
    this.userService.getByKey(userId).subscribe(doc=>{
      this.users = doc.payload.data();
      this.users['id'] = doc.payload.id;
      console.log(this.users);
      this.currentBranchId = this.users['branchId'];
      $('#techStartTimeEdit').val(this.users['openTime'].slice(0,2) + ":" + this.users['openTime'].slice(2));
      $('#techEndTimeEdit').val(this.users['closeTime'].slice(0,2) + ":" + this.users['closeTime'].slice(2));
      if(typeof(this.users['image_avartar']) === "undefined"){
        this.imgURL1 = "../assets/img/default-user.jpg";
      }else{
        this.imgURL1 = this.users['image_avartar'];
      }
    });
  }

  getMenu(){ 
    this.menuList = [];
    console.log(this.techMenuList);
      let count = 0;
      this.branchMenuService.get().subscribe(data=>{
        if (data.size == 0) { 
           
        }
        data.forEach(doc => {
          let branchMenu = doc.data();
          count++;
          let dataMenu = doc.data();
          let menu = {};
          menu['id'] = dataMenu['menuId'];
          menu['price'] = dataMenu['price'];
          //console.log(menu);
          
          if(doc.data().branchRef){
            doc.data().branchRef.get().then(res=>{
              console.log(res.data());
              menu['branchName'] = res.data().name;
              menu['franchise'] = res.data().franchise;
            });
          }

          if(doc.data().menuRef){
              doc.data().menuRef.get().then(res=>{
                //console.log(res)
                menu['name'] = res.data().name;
                if(this.techMenuList.length>0){
                  let countDup = 0;
                  for(let i=0;i<this.techMenuList.length;i++){
                    if(menu['id'] == this.techMenuList[i]['id']){
                      countDup++;
                    }
                    if((i+1)==this.techMenuList.length){
                      if(countDup==0){
                        if(!menu['franchise'] && !this.isFranchise){
                          this.menuList.push(menu);
                        }else{
                          if((branchMenu['branchId'] == this.currentBranchId) && this.isFranchise){
                              this.menuList.push(menu);
                          }
                        }
                      }
                    }
                  }
                }else{
                  if(!menu['franchise'] && !this.isFranchise){
                    this.menuList.push(menu);
                  }else{
                    if((branchMenu['branchId'] == this.currentBranchId) && this.isFranchise){
                      this.menuList.push(menu);
                    }
                  }
                }
      
                if(count == data.size){
                  
                }
            });
            
          } //end if menu ref

        });
      });
  }

  getBranch() {
    this.branchService.get().subscribe(data => {
      data.forEach(doc => {
        let newItem = doc.data();
        let branch = {};
        branch["id"] = doc.id;
        branch["name"] = newItem.name;
        this.branchList.push(branch);
      });
    });
  } 

  decimalFormat2Digit(value) {
    let result = "";
    result = value < 10 ? "0" + value : value;

    return result;
  }

  decimalFormat4Digit(value) {
    let result = "";
    if (value < 10) {
      result = "000" + value;
    } else if (value > 10 && value < 100) {
      result = "00" + value;
    } else if (value > 100 && value < 1000) {
      result = "0" + value;
    } else if (value > 1000) {
      result = "" + value;
    }

    return result;
  }

  submitEditUser(value) {
    this.submitted_edit = true;
    /*console.log("tech id : " + this.userId);
    for(let i=0;i<this.techMenuList.length;i++){
      console.log(this.techMenuList[i]);
    }
    */ 
 
    // stop here if form is invalid
    if (this.editForm.invalid) {
      return;
    } 
    $('#loading').show();
    //clear old user menu
    this.userMenuService.getByUser(this.userId).subscribe(data=>{
      let countRemove = 0;
      if(data.size == 0){
          //add new user menu
          for(let i=0;i<this.techMenuList.length;i++){
            let userMenu = this.techMenuList[i];
            userMenu['userId'] = this.userId;
            this.userMenuService.create(userMenu).then(res=>{
              console.log(userMenu['id'] + " Create success");
            });
        }
      }
      data.forEach(doc =>{
        this.userMenuService.delete(doc.id).then(res=>{
          console.log(doc.id + " Remove success");
          countRemove++;
          if(countRemove==data.size){
              //add new user menu
                for(let i=0;i<this.techMenuList.length;i++){
                  let userMenu = this.techMenuList[i];
                  userMenu['userId'] = this.userId;
                  this.userMenuService.create(userMenu).then(res=>{
                    console.log(userMenu['id'] + " Create success");
                  });
              }
          }

        });
      });
    }); 

    if (typeof this.selectedFile !== "undefined") {
        const formData = new FormData();
        console.log(this.selectedFile);
        formData.append('image', this.selectedFile);
        formData.append('type', "barber");
        this.fileManagerService.uploadFile(formData)
              .subscribe(res => {
                if (res.code == "200") {
                      value["image_avartar"] = res.data;
                      let role = this.authen.role;
                      let branchId = "";
                      if ("superadmin" == role) {
                        branchId = $("#branchedit").val();
                        value["branchId"] = branchId;
                        value["branchRef"] = this.db.doc("/branch/" + branchId).ref;
                      }
                      value["openTime"] = $('#techStartTimeEdit').val().slice(0,2) + $('#techStartTimeEdit').val().slice(3);
                      value["closeTime"] = $('#techEndTimeEdit').val().slice(0,2) + $('#techEndTimeEdit').val().slice(3);
                  
                      this.userService.updateUser(value.id, value).then(res => {
                        console.log("success");
                        $("#edit-modal").modal("hide");
                        $('#loading').hide();
                        //this.getTechnician();
                        //window.location.href = "#/technician";
                      });
                  }//end upload photo
              });
        }else{
              let role = this.authen.role;
              let branchId = "";
              if ("superadmin" == role) {
                branchId = $("#branchedit").val();
                value["branchId"] = branchId;
                value["branchRef"] = this.db.doc("/branch/" + branchId).ref;
              }
              value["openTime"] = $('#techStartTimeEdit').val().slice(0,2) + $('#techStartTimeEdit').val().slice(3);
              value["closeTime"] = $('#techEndTimeEdit').val().slice(0,2) + $('#techEndTimeEdit').val().slice(3);
          
              this.userService.updateUser(value.id, value).then(res => {
                console.log("success");
                $("#edit-modal").modal("hide");
                $('#loading').hide();
                //this.getTechnician();
                //window.location.href = "#/technician";
              });
        }
  }  

  onFileChanged(event) {
    this.selectedFile = event.target.files[0];
    console.log(this.selectedFile);
    this.preview(event.target.files,null);
    var ss = this.dataURLtoFile(this.imgURL1,'test.jpg');
    console.log(ss);
  }

  dataURLtoFile(dataurl, filename) {
    try {
      var arr = dataurl.split(','),
          mime = arr[0].match(/:(.*?);/)[1],
          bstr = atob(arr[1]), 
          n = bstr.length, 
          u8arr = new Uint8Array(n);
          
      while(n--){
          u8arr[n] = bstr.charCodeAt(n);
      }
    }
    catch(err) {
      console.log(err);
      // document.getElementById("demo").innerHTML = err.message;
    }
    return new File([u8arr], filename, {type:mime});
  }

  preview(files, type) {
    if (files.length === 0)
      return;
 
    if(type == 'WEBCAM'){
      var mimeType = files.type;
      if (mimeType.match(/image\/*/) == null) {
        //this.message1 = "Only images are supported.";
        return;
      }
  
      var reader = new FileReader();
      reader.readAsDataURL(files); 
      reader.onload = (_event) => { 
        this.imgURL1 = reader.result; 
        // console.log(this.imgURL1);
      }
    }else{
      var mimeType = files[0].type;
      if (mimeType.match(/image\/*/) == null) {
        //this.message1 = "Only images are supported.";
        return;
      }
  
      var reader = new FileReader();
      reader.readAsDataURL(files[0]); 
      reader.onload = (_event) => { 
        this.imgURL1 = reader.result; 
        // console.log(this.imgURL1);
      }
    }
  }
  

  convertMonthTH(m) {
    var month = "";
    if ("01" == m) {
      month = "มกราคม";
    } else if ("02" == m) {
      month = "กุมภาพันธ์";
    } else if ("03" == m) {
      month = "มีนาคม";
    } else if ("04" == m) {
      month = "เมษายน";
    } else if ("05" == m) {
      month = "พฤษภาคม";
    } else if ("06" == m) {
      month = "มิถุนายน";
    } else if ("07" == m) {
      month = "กรกฎาคม";
    } else if ("08" == m) {
      month = "สิงหาคม";
    } else if ("09" == m) {
      month = "กันยายน";
    } else if ("10" == m) {
      month = "ตุลาคม";
    } else if ("11" == m) {
      month = "พฤศจิกายน";
    } else if ("12" == m) {
      month = "ธันวาคม";
    }
    return month;
  }

  //load default script
  loadDefaultScript() {
    $("#add-modal").modal({
      show: false,
      backdrop: "static"
    }); 

    $('#techStartTimeEdit').daterangepicker({
      timePicker : true,
      singleDatePicker:true,
      timePicker24Hour : true,
      timePickerIncrement : 1,
      timePickerSeconds : false,
      locale : {
          format : 'HH:mm'
      }
      }).on('show.daterangepicker', function(ev, picker) {
      picker.container.find(".calendar-table").hide();
      });

      $('#techEndTimeEdit').daterangepicker({
          timePicker : true,
          singleDatePicker:true,
          timePicker24Hour : true,
          timePickerIncrement : 1,
          timePickerSeconds : false,
          locale : {
              format : 'HH:mm'
          }
      }).on('show.daterangepicker', function(ev, picker) {
      picker.container.find(".calendar-table").hide();
      }); 
  }
  //end load default script
}
