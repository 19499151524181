import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';

@Injectable({
    providedIn: 'root'
})
export class PackageService {

  constructor(public db: AngularFirestore) {}

  create(value){
    return this.db.collection('templateServicePackage').add(value);
  }

  createMenu(value){
    return this.db.collection('menu').add(value);
  }

  get(){
    return this.db.collection('servicepackage').get();
  }

  getMenu(){
    return this.db.collection('menu').get();
  }

  getAllPackageTemplate(){
    return this.db.collection('templateServicePackage').get();
  }

  getAllMenuTemplate(){
    return this.db.collection('templateMenu').get();
  }

  remove(id) {
    return this.db.collection("templateServicePackage")
       .doc(id)
       .delete();
  }

  removeMenu(id) {
    return this.db.collection("menu")
       .doc(id)
       .delete();
  }

  searchByCriteria(criteria) {
    if(criteria.status == "" && criteria.packageName == "") {
      return this.db.collection('templateServicePackage').get();
    } else if(criteria.status != "" && criteria.packageName != "") {
      return this.db.collection('templateServicePackage',ref => ref.where('nameTh', '==', criteria.packageName)
      .where('status', '==', criteria.status)).get();
    } else if(criteria.status != "" && criteria.packageName == "") {
      return this.db.collection('templateServicePackage',ref => ref.where('status', '==', criteria.status)).get();
    } else if(criteria.status == "" && criteria.packageName != "") {
      return this.db.collection('templateServicePackage',ref => ref.where('nameTh', '==', criteria.packageName)).get();
    }

  }

  searchMenuByCriteria(criteria) {
    if(criteria.menuName == "") {
      return this.db.collection('menu').get();
    } else if(criteria.menuName != "") {
      return this.db.collection('menu',ref => ref.where('name', '==', criteria.menuName)).get();
    }
  }

  getBranchByUserId(servicePackageId){
    return this.db.collection('branchServicepackage',ref => ref.where('servicePackageId', '==', servicePackageId)).get();
  }

  updateServicePackage(key, value){
    return this.db.collection('templateServicePackage').doc(key).set(value);
  }

  updateMenu(key, value){
    return this.db.collection('menu').doc(key).set(value);
  }
}
