import { Component, OnInit } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { BranchService } from "../../service/branch.service";
import { UserService } from "../../service/user.service";
import { HistoryService } from "../../service/history.service";
import { BranchServicepackageService } from "../../service/branchservicepackage.service";
import { PhotoService } from "../../service/photo.service";
import { BranchPhotoService } from "src/app/service/branchphoto.service";
import { BranchMenuService } from "../../service/branchmenu.service";

import { MouseEvent } from "@agm/core";
declare var jquery: any;
declare var $: any;

@Component({
  selector: "app-branch-view",
  templateUrl: "./branch_view.component.html",
  styleUrls: ["./branch_view.component.css"]
})
export class BranchViewComponent implements OnInit {
  rid;
  authen;
  branch = {};
  manager = {};
  technicianList = [];
  users = {};
  historyList = [];
  photoList = [];
  history = {};
  branchList = new Array();
  captureFlg = "";
  branchMenu = [];

  constructor(
    private activatedRoute: ActivatedRoute,
    private branchService: BranchService,
    private branchServicepackageService: BranchServicepackageService,
    private historyService: HistoryService,
    private userService: UserService,
    private photoService: PhotoService,
    private branchPhotoService: BranchPhotoService,
    private branchMenuService: BranchMenuService
  ) {}

  ngOnInit() {
    //this.loadDefaultScript();
    this.authen = JSON.parse(localStorage.getItem("authenMenbarberToken"));
    //get params
    this.activatedRoute.params.forEach(urlParams => {
      this.rid = urlParams["rid"].replace("#", "");
    });
    this.getBranchDetails(this.rid);
    this.getTechnicianList(this.rid);
    this.getManagerBranch(this.rid);
    this.getUserService(this.rid, "", "");
    this.getBranchPhoto(this.rid);
    this.getBranch();
    $(".daterange").daterangepicker({
      format: "dd/mm/yyyy"
    });
    this.getBranchMenuService();
  }

  getBranch() {
    this.branchService.get().subscribe(data => {
      data.forEach(doc => {
        let newItem = doc.data();
        let branch = {};
        branch["id"] = doc.id;
        branch["name"] = newItem.name;
        this.branchList.push(branch);
      });
    });
  }

  getBranchDetails(branchID) {
    $("#loading").show();
    this.branchService.getByKey(branchID).subscribe(data => {
      this.branch = data.data();
      //console.log(this.branch);
      this.branch["id"] = data.id;
      if (data.data().capturesys) {
        this.captureFlg = "checked";
      }
      this.branch['openTime'] = this.branch['openTime'].slice(0,2) + ":" + this.branch['openTime'].slice(2);
      this.branch['closeTime'] = this.branch['closeTime'].slice(0,2) + ":" + this.branch['closeTime'].slice(2);

      this.lat = data.data().lat;
      this.lng = data.data().lng;
      this.markers = [];
      this.markers.push({
        lat: data.data().lat,
        lng: data.data().lng,
        draggable: false
      });
      $("#loading").hide();
    });
  }

  getBranchPhoto(branchID) {
    this.branchPhotoService.getPhotoByBranchId(branchID).subscribe(data => {
      data.forEach(doc => {
        let branchPhoto = doc.data();
        if (branchPhoto.photoRef) {
          branchPhoto.photoRef.get().then(res => {
            this.photoService.getByKey(res.id).subscribe(data => {
              let item = data.payload.data();
              //console.log(data.payload.data());
              let photo = {};
              photo["pathUrl"] = item["pathUrl"];
              photo["seq"] = branchPhoto.seq;
              this.photoList.push(photo);
              setTimeout(() => {
                this.photoList.sort((a, b) => Number(a.seq) - Number(b.seq));
              }, 1);
            });
          });
        }
      });
    });
  }

  getUserService(branchID, startDate, endDate) {
    let price = 0;
    this.history = {};
    this.history["amount"] = 0;
    this.history["totalprice"] = 0;

    this.userService
      .searchTechnicainByBranch("tech", branchID)
      .subscribe(data => {
        let amount = 0;
        data.forEach(doc => {
          let newItem = doc.data();
          if (newItem.branchRef) {
            newItem.branchRef.get().then(res => {
              let branch = res.data();
              this.history["branchname"] = branch.name;
            });
          }

          this.historyService.getUserHistoryByUserId(doc.id).subscribe(res => {
            res.forEach(doc => {
              let userHistory = doc.data();
              // history
              if (userHistory.historyRef) {
                userHistory.historyRef.get().then(res => {
                  let h = res.data();
                  if (
                    (Number(startDate) <= Number(h.startDate.seconds) &&
                      Number(endDate) >= Number(h.startDate.seconds)) ||
                    (startDate == "" && endDate == "")
                  ) {
                    amount = amount + 1;
                    price += Number(h.price);
                    this.history["amount"] = this.addCommas(amount);
                    this.history["totalprice"] = this.addCommas(price);
                  }
                });
              }
            });
          });
        });
      });
  }

  searchAmount() {
    let branchSelected = $("#branchSelected").val();
    console.log(branchSelected);
    let date = $("#datefrom")
      .val()
      .split("-");
    let startDate = Date.parse(date[0].trim()) / 1000;
    let endDate = Date.parse(date[1].trim()) / 1000;
    this.getUserService(branchSelected, startDate, endDate);
  }

  getTechnicianList(branchID) {
    this.technicianList = [];
    $("#datatables")
      .DataTable()
      .clear()
      .destroy();
    this.userService
      .searchTechnicainByBranch("tech", branchID)
      .subscribe(data => {
        if (data.size) {
          setTimeout(() => {
            $("#datatables").DataTable({
              retrieve: true,
              paging: true,
              responsive: true
            });
          }, 0);
        }
        data.forEach(doc => {
          let newItem = doc.data();
          newItem["openTime"] = newItem['openTime'].slice(0,2) + ":" + newItem['openTime'].slice(2);
          newItem['closeTime'] = newItem['closeTime'].slice(0,2) + ":" + newItem['closeTime'].slice(2);
          this.technicianList.push(newItem);

          setTimeout(() => {
            $("#datatables").DataTable({
              retrieve: true,
              paging: true,
              responsive: true
            });
          }, 0);
        });
      });
  }


  getBranchMenuService(){
    this.branchMenu = [];
    this.branchMenuService.getByBranch(this.rid).subscribe(data=>{
        data.forEach(doc =>{
            let branchMenu = doc.data();
            let menu = {};
            menu['price'] = branchMenu['price'];
            if(branchMenu.menuRef){
              branchMenu.menuRef.get().then(res=>{
                menu['name'] = res.data().name;
                this.branchMenu.push(menu);
              });
            }
        })
    })
  }

  back() {
    window.location.href = "#/branch";
  }

  getManagerBranch(branchID) {
    this.userService
      .searchTechnicainByBranch("admin", branchID)
      .subscribe(data => {
        data.forEach(doc => {
          let newItem = doc.data();
          this.manager = newItem;
        });
      });
  }

  view(user) {
    this.users = user;
  }

  addCommas(nStr) {
    nStr += "";
    let x = nStr.split(".");
    let x1 = x[0];
    let x2 = x.length > 1 ? "." + x[1] : "";
    var rgx = /(\d+)(\d{3})/;
    while (rgx.test(x1)) {
      x1 = x1.replace(rgx, "$1" + "," + "$2");
    }
    return x1 + x2;
  }

  //load default script
  loadDefaultScript() {
    $("#datatables").DataTable({
      pagingType: "full_numbers",
      lengthMenu: [
        [10, 25, 50, -1],
        [10, 25, 50, "All"]
      ],
      responsive: true,
      language: {
        search: "_INPUT_",
        searchPlaceholder: "Search records"
      }
    });

    $("#datatables2").DataTable({
      pagingType: "full_numbers",
      lengthMenu: [
        [10, 25, 50, -1],
        [10, 25, 50, "All"]
      ],
      responsive: true,
      language: {
        search: "_INPUT_",
        searchPlaceholder: "Search records"
      }
    });

    $(".datepicker").datetimepicker({
      format: "MM/DD/YYYY",
      icons: {
        time: "fa fa-clock-o",
        date: "fa fa-calendar",
        up: "fa fa-chevron-up",
        down: "fa fa-chevron-down",
        previous: "fa fa-chevron-left",
        next: "fa fa-chevron-right",
        today: "fa fa-screenshot",
        clear: "fa fa-trash",
        close: "fa fa-remove"
      }
    });
  }
  //end load default script

  // google maps zoom level
  zoom: number = 8;

  // initial center position for the map
  lat: number = 13.756331;
  lng: number = 100.501762;

  clickedMarker(label: string, index: number) {
    console.log(`clicked the marker: ${label || index}`);
  }

  mapClicked($event: MouseEvent) {
    this.markers = [];
    this.markers.push({
      lat: $event.coords.lat,
      lng: $event.coords.lng,
      draggable: true
    });
    console.log(this.markers);
  }

  markers: marker[] = [];
}

// just an interface for type safety.
interface marker {
  lat: number;
  lng: number;
  label?: string;
  draggable: boolean;
}
