import { Component, OnInit } from '@angular/core';
declare var jquery: any;
declare var $: any;
@Component({
  selector: 'layout-menu-left',
  templateUrl: './menu-left.component.html'
})


export class MenuLeftComponent { 
  authenMenbarber;
  public isCustomerCollapsed = true;
  public isBranchCollapsed = true;
  public isSettingCollapsed = true;
  public isFreelanceAreaCollapsed = true;
  public isFCollapsed1 = true;
  public isFCollapsed2 = true;

  constructor( 
  ) { 
  }

  ngOnInit() {
    this.authenMenbarber = JSON.parse(localStorage.getItem('authenMenbarberToken'));
  }

  logoutUser() {
    localStorage.clear();
    localStorage.removeItem('authenMenbarberToken');
    localStorage.removeItem('startMenprocess');
    window.location.href = "/";
  }

}
