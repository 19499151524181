import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { BranchComponent } from './branch.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule
  ],
  declarations: [BranchComponent],
  exports: [
    BranchComponent
  ],
  providers: [
  ]
})
export class BranchModule { }
