import { Component, OnInit } from "@angular/core";
import { Subject, Observable } from "rxjs";
declare var jquery: any;
declare var $: any;
import { throwError } from "rxjs";
import { retry, catchError } from "rxjs/operators";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { PhotoService } from "../service/photo.service";

@Component({
  selector: "app-hairmodel",
  templateUrl: "./hairmodel.component.html",
  styleUrls: ["./hairmodel.component.css"]
})
export class HairmodelComponent implements OnInit {
  public imagePath;
  public imagePath2;
  public imagePath3;
  public imagePath4;
  imgFront: any;
  imgBack: any;
  imgLeft: any;
  imgRight: any;
  fileFront: File;
  fileBack: File;
  fileLeft: File;
  fileRight: File;
  hairmodelList = new Array();
  hairmodels = new Array();
  hairModelID;
  public message1: string;
  hairModel;
  public messageimg: string;
  public messagehairname: string;
  rid: string;

  constructor(private http: HttpClient, private photoService: PhotoService) {}

  apiURL = "https://hairline7.com/menbarber/api";
  // Http Options
  httpOptions = {
    headers: new HttpHeaders({
      "Content-Type": "application/json"
    })
  };

  ngOnInit() {
    this.loadDefaultScript();
    this.getHairModel("", "");
  }

  getHairModel(hairModelName, status) {
    $("#loading").show();
    this.hairmodels = [];

    $("#datatables")
      .DataTable()
      .clear()
      .destroy();
    this.photoService
      .getHairModelBycriteria(hairModelName, status)
      .subscribe(data => {
        data.forEach(doc => {
          let newItem = doc.data();
          newItem["id"] = doc.id;
          this.hairmodels.push(newItem);
          $("#loading").hide();
          setTimeout(() => {
            $("#datatables").DataTable({
              paging: true,
              retrieve: true,
              responsive: true
            });
          }, 0);
        });
        if (data.size == 0) {
          $("#loading").hide();
          setTimeout(() => {
            $("#datatables").DataTable({
              retrieve: true,
              paging: false,
              responsive: true
            });
          }, 0);
        }
      });
  }

  searchByCriteria() {
    let s_hairmodelname = $("#s_hairmodelname").val();
    let s_status = $("#s_status").val();
    this.getHairModel(s_hairmodelname, s_status);
  }

  editHairModel(hm) {
    $("#edit-modal").modal();
    this.imgFront = hm.front_img;
    this.imgBack = hm.back_img;
    this.imgLeft = hm.left_img;
    this.imgRight = hm.right_img;
    $("#e_hairname").val(hm.hairmodelname);
    $('#e_status option[value="' + hm.status + '"]').attr(
      "selected",
      "selected"
    );
    this.hairModelID = hm.id;
  }

  closeModal() {
    $("input").val("");
    this.imgFront = "";
    this.imgBack = "";
    this.imgLeft = "";
    this.imgRight = "";

    this.fileFront = null;
    this.fileBack = null;
    this.fileLeft = null;
    this.fileRight = null;
    $("#add-modal").modal("hide");
  }

  preview(event, type) {
    this.messageimg = null;
    var mimeType1 = event.target.files[0].type;
    if (mimeType1.match(/image\/*/) == null) {
      this.message1 = "Only images are supported.";
      return;
    }
    var reader = new FileReader();
    reader.readAsDataURL(event.target.files[0]);
    reader.onload = _event => {
      if (type == "front") {
        this.imgFront = reader.result;
        this.fileFront = event.target.files[0];
      } else if (type == "back") {
        this.imgBack = reader.result;
        this.fileBack = event.target.files[0];
      } else if (type == "left") {
        this.imgLeft = reader.result;
        this.fileLeft = event.target.files[0];
      } else if (type == "right") {
        this.imgRight = reader.result;
        this.fileRight = event.target.files[0];
      }
    };
  }

  saveHairModel() {
    if (
      this.fileFront == undefined ||
      this.fileBack == undefined ||
      this.fileLeft == undefined ||
      this.fileRight == undefined
    ) {
      this.messageimg = "เลือกรูปภาพไม่ครบ";
      return;
    }

    if ($("#hairmodelname").val() === "") {
      this.messagehairname = "ยังไม่ได้ใส่ชื่อทรงผม";
      return;
    }

    let hairmodels = {};
    let hairmodel = [
      { file: this.fileFront, name: "front" },
      { file: this.fileBack, name: "back" },
      { file: this.fileLeft, name: "left" },
      { file: this.fileRight, name: "right" }
    ];
    let hairmodelname = $("#hairmodelname").val();
    let status = $("#status").val();

    hairmodels["status"] = status;
    hairmodels["hairmodelname"] = hairmodelname;
    let count = 1;
    for (var i = 0; i < hairmodel.length; i++) {
      const date = new Date().valueOf();
      let text = "";
      const possibleText =
        "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
      for (let i = 0; i < 5; i++) {
        text += possibleText.charAt(
          Math.floor(Math.random() * possibleText.length)
        );
      }
      const imageName = date + text + ".jpeg";
      let formData = new FormData();
      formData.append("image", hairmodel[i].file, imageName);
      formData.append("type", "hairmodel");

      let name = hairmodel[i].name;
      this.uploadFile(formData).subscribe(res => {
        if (res.code == "200") {
          var photoId = res.id;
          if (name == "front") {
            hairmodels["front_img"] = res.data;
          } else if (name == "back") {
            hairmodels["back_img"] = res.data;
          } else if (name == "left") {
            hairmodels["left_img"] = res.data;
          } else if (name == "right") {
            hairmodels["right_img"] = res.data;
          }
          if (hairmodel.length == count) {
            setTimeout(() => {
              console.log(hairmodels);
              this.photoService.createHairModel(hairmodels).then(res => {
                console.log("success");
                // window.location.href = "#/hairmodel";
                $("#add-modal").modal("hide");
                this.getHairModel("", "");
              });
            }, 0);
          }
          count++;
        }
      });
    }
  }

  updateHairModel() {
    let hairmodel = [];
    let hairmodels = {};
    let hairmodelname = $("#e_hairname").val();
    let status = $("#e_status").val();
    hairmodels["status"] = status;
    hairmodels["hairmodelname"] = hairmodelname;
    if (hairmodelname === "") {
      this.messagehairname = "ยังไม่ได้ใส่ชื่อทรงผม";
      return;
    }
    if (!(this.fileFront == undefined)) {
      let hm = {};
      hm["file"] = this.fileFront;
      hm["name"] = "front";
      hairmodel.push(hm);
    }
    if (!(this.fileBack == undefined)) {
      let hm = {};
      hm["file"] = this.fileBack;
      hm["name"] = "back";
      hairmodel.push(hm);
    }
    if (!(this.fileLeft == undefined)) {
      let hm = {};
      hm["file"] = this.fileLeft;
      hm["name"] = "left";
      hairmodel.push(hm);
    }
    if (!(this.fileRight == undefined)) {
      let hm = {};
      hm["file"] = this.fileRight;
      hm["name"] = "right";
      hairmodel.push(hm);
    }

    for (var i = 0; i < hairmodel.length; i++) {
      const date = new Date().valueOf();
      let text = "";
      const possibleText =
        "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
      for (let i = 0; i < 5; i++) {
        text += possibleText.charAt(
          Math.floor(Math.random() * possibleText.length)
        );
      }
      const imageName = date + text + ".jpeg";
      let formData = new FormData();
      formData.append("image", hairmodel[i].file, imageName);
      formData.append("type", "hairmodel");

      let name = hairmodel[i].name;
      this.uploadFile(formData).subscribe(res => {
        if (res.code == "200") {
          console.log(res);
          var photoId = res.id;
          if (name == "front") {
            hairmodels["front_img"] = res.data;
          } else if (name == "back") {
            hairmodels["back_img"] = res.data;
          } else if (name == "left") {
            hairmodels["left_img"] = res.data;
          } else if (name == "right") {
            hairmodels["right_img"] = res.data;
          }

          this.photoService
            .updateHairModel(this.hairModelID, hairmodels)
            .then(res => {
              console.log("success");
              // window.location.href = "#/hairmodel";
              $("#edit-modal").modal("hide");

              this.hairmodels = [];
              this.getHairModel("", "");
            });
        }
      });
    }

    if (hairmodel.length == 0) {
      this.photoService
        .updateHairModel(this.hairModelID, hairmodels)
        .then(res => {
          console.log("success");
          // window.location.href = "#/hairmodel";

          $("#edit-modal").modal("hide");

          this.hairmodels = [];
          this.getHairModel("", "");
        });
    }
  }

  keyvalue() {
    this.messagehairname = null;
  }

  deleteHairModel(id) {
    this.rid = id;
  }

  confirmDeleteHairModel() {
    let id = this.rid;
    this.photoService.removeHairModel(id).then(res => {
      console.log("success");
      $("#delete-modal").modal("hide");
      this.getHairModel("", "");
      //location.reload();
    });
  }

  //load default script
  loadDefaultScript() {
    $("#add-modal").modal({
      show: false,
      backdrop: "static"
    });

    $("#edit-modal").modal({
      show: false,
      backdrop: "static"
    });

    $("#delete-modal").modal({
      show: false,
      backdrop: "static"
    });
  }
  //end load default script

  b64toBlob(b64Data, contentType, sliceSize) {
    contentType = contentType || "";
    sliceSize = sliceSize || 512;

    var byteCharacters = atob(b64Data);
    var byteArrays = [];

    for (var offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      var slice = byteCharacters.slice(offset, offset + sliceSize);

      var byteNumbers = new Array(slice.length);
      for (var i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      var byteArray = new Uint8Array(byteNumbers);

      byteArrays.push(byteArray);
    }

    var blob = new Blob(byteArrays, { type: contentType });
    return blob;
  }

  // upload
  uploadFile(file): Observable<any> {
    console.log(file);
    return this.http
      .post<any>(this.apiURL + "/v1/upload", file)
      .pipe(retry(1), catchError(this.handleError));
  }

  // Error handling
  handleError(error) {
    let errorMessage = "";
    if (error.error instanceof ErrorEvent) {
      // Get client-side error
      errorMessage = error.status;
    } else {
      // Get server-side error
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    //window.alert(errorMessage);
    return throwError(errorMessage);
  }
}
