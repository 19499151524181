import { Component, OnInit } from "@angular/core";
declare var jquery: any;
declare var $: any;
import { HttpClient } from "@angular/common/http";
import { HttpErrorResponse } from "@angular/common/http";

@Component({
  selector: "app-freelanceHistory",
  templateUrl: "./freelanceHistory.component.html",
  styleUrls: ["./freelanceHistory.component.css"]
})
export class FreelanceHistoryComponent implements OnInit {

  constructor(
    private httpService: HttpClient
  ) {}

  ngOnInit() {
    this.loadDefaultScript(); 
  } 

  //load default script
  loadDefaultScript() {
    setTimeout(() => {
      $("#tb_freelance_history_all_list").DataTable({
        retrieve: true,
        paging: true,
        responsive: true
      });
    }, 0);
  }
  //end load default script
}
