import { Injectable } from "@angular/core";
import { AngularFirestore } from "@angular/fire/firestore";

@Injectable({
  providedIn: "root"
})
export class UserService {
  constructor(public db: AngularFirestore) {}

  create(value) {
    return this.db.collection("users").add(value);
  }

  getByKey(key) {
    return this.db
      .collection("users")
      .doc(key)
      .snapshotChanges();
  }

  set(key, value) {
    return this.db
      .collection("users")
      .doc(key)
      .set(value);
  }

  update(key, value) {
    return this.db
      .collection("users")
      .doc(key)
      .update(value);
  }

  delete(key) {
    return this.db
      .collection("users")
      .doc(key)
      .delete();
  }

  get() {
    return this.db.collection("users").snapshotChanges();
  }

  getAll() {
    return this.db.collection("users").get();
  }

  getTech() {
    return this.db
      .collection("users", ref => ref.where("role", "==", "tech"))
      .get();
  }

  searchTechnicainByBranch(role, branchId) {
    if (branchId == "") {
      return this.db
        .collection("users", ref => ref.where("role", "==", role))
        .get();
    } else {
      return this.db
        .collection("users", ref =>
          ref.where("role", "==", role).where("branchId", "==", branchId)
        )
        .get();
    }
  }

  searchByRole(value) {
    return this.db
      .collection("users", ref => ref.orderBy("role").startAt(value))
      .get();
  }

  updateUser(key, value) {
    return this.db
      .collection("users")
      .doc(key)
      .update(value);
  }

  search(searchValue) {
    return this.db
      .collection("users", ref =>
        ref
          .where("nameToSearch", ">=", searchValue)
          .where("nameToSearch", "<=", searchValue + "\uf8ff")
      )
      .snapshotChanges();
  }

  getByUserNameAndPassword(username, password) {
    return this.db
      .collection("users", ref =>
        ref.where("username", "==", username).where("password", "==", password)
      )
      .get();
  }
}
