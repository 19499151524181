import { Component, OnInit } from '@angular/core';
import { CustomerService } from '../../service/customer.service';
import { HistoryService } from '../../service/history.service'
declare var jquery: any;
declare var $: any;

@Component({
  selector: 'app-customer-detail',
  templateUrl: './customerdetail.component.html',
  styleUrls: ['./customerdetail.component.css']
})
export class CustomerDetailComponent implements OnInit {
  containerActive=false;
  customerList = [];

  constructor(
    private customerService: CustomerService,
    private historyService: HistoryService
  ) { }

  ngOnInit() {
    this.search();
    this.loadDefaultScript();
  } 

  search(){
    $('#loading').show();
    this.customerList = [];
    this.customerService.get()
      .subscribe(data => {
        data.forEach(doc => {
          let customer = doc.payload.doc.data();
          customer["id"] = doc.payload.doc.id;

          //console.log(customer);
          //history user
          let historyCount = 0;
          let historyTotalPaid = 0;
          let historyTotalAvgPaid = 0;
          let lastHistoryDate = "";
          let historyList = [];

          this.historyService.getUserHistoryByCustomerId(customer["id"]).subscribe(res => {
            res.forEach(doc => {
              let historyUser = doc.data();
                if(historyUser.historyRef){
                  historyUser.historyRef.get().then(res =>{
                    let historyObj = res.data();
                      if(historyObj.status == 'HIS'){
                        historyList.push(historyObj);

                        setTimeout(() => {
                          historyList.sort((a, b) => Number(b.startDate.seconds) - Number(a.startDate.seconds));
                          lastHistoryDate = this.convertToThDateTime(historyList[0].startDate);
                          //console.log("lastHistoryDate:  " + lastHistoryDate);
                          customer["lastHistoryDate"] = lastHistoryDate;
                        }, 100);

                        historyCount++;
                        historyTotalPaid = historyTotalPaid + historyObj['price'];
                        historyTotalAvgPaid = historyTotalPaid/historyCount;
                        //console.log(historyList[0].startDate);
                        customer["historyCount"] = historyCount;
                        customer["historyTotalPaid"] = historyTotalPaid;
                        customer["historyTotalAvgPaid"] = historyTotalAvgPaid;
                      }
                  })
                }
            })
          });

          this.customerList.push(customer);
        });

        setTimeout(() => {
          $('#loading').hide();
          $('#customer-detail-datatables').DataTable({
            retrieve: true,
            paging: true,
            responsive: true
          });
        }, 0);

      },
      err => {
        console.log(err);
      });
  }



  convertToThDateTime(date) {
    let start = Number(date.seconds);
    let month = "";
    let day = "";
    let startDate = new Date(start * 1000);
    var dd = startDate.getDate();
    var mm = startDate.getMonth() + 1; //January is 0!
    var yyyy = startDate.getFullYear();
    if (dd < 10) {
      day = "0" + dd;
    } else {
      day = "" + dd;
    }
    if (mm < 10) {
      month = "0" + mm;
    } else {
      month = "" + mm;
    }
    var today =
      day +
      " " +
      this.convertMonthTH(month) +
      " " +
      (Number(yyyy) + 543);
    var startTime =
      (startDate.getHours() < 10
        ? "0" + startDate.getHours()
        : startDate.getHours()) +
      "." +
      (startDate.getMinutes() < 10
        ? "0" + startDate.getMinutes()
        : startDate.getMinutes());
    //console.log(today + "  " + startTime + " น.");
      return today + "  " + startTime + " น.";
  }

  convertMonthTH(m) {
    var month = "";
    if ("01" == m) {
      month = "มกราคม";
    } else if ("02" == m) {
      month = "กุมภาพันธ์";
    } else if ("03" == m) {
      month = "มีนาคม";
    } else if ("04" == m) {
      month = "เมษายน";
    } else if ("05" == m) {
      month = "พฤษภาคม";
    } else if ("06" == m) {
      month = "มิถุนายน";
    } else if ("07" == m) {
      month = "กรกฎาคม";
    } else if ("08" == m) {
      month = "สิงหาคม";
    } else if ("09" == m) {
      month = "กันยายน";
    } else if ("10" == m) {
      month = "ตุลาคม";
    } else if ("11" == m) {
      month = "พฤศจิกายน";
    } else if ("12" == m) {
      month = "ธันวาคม";
    }
    return month;
  }

  //load default script
  loadDefaultScript(){
    setTimeout(() => { 
      
      $(".quick-btn").click(function(){
        $(".quick-btn").removeClass("active");
        $(this).addClass("active");
    });
  });
}
//end load default script
}
