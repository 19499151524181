import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';

@Injectable({
    providedIn: 'root'
})
export class UserMenuService {

    constructor(public db: AngularFirestore) {}

      getUserMenu(){
        return this.db.collection('userMenu').valueChanges();
      }
  
      create(value){
        return this.db.collection('userMenu').add(value);
      }
  
      getByKey(key){
        return this.db.collection('userMenu').doc(key).snapshotChanges();
      }
  
      set(key, value){
        return this.db.collection('userMenu').doc(key).set(value);
      }
  
      update(key, value){
        return this.db.collection('userMenu').doc(key).update(value);
      }
  
      delete(key){
        return this.db.collection('userMenu').doc(key).delete();
      }
  
      get(){
        return this.db.collection('userMenu').snapshotChanges();
      } 

      getByUser(userId){
        return this.db.collection('userMenu',ref => ref.where('userId', '==', userId)).get();
      }
}
