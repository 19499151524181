import { Component, OnInit } from "@angular/core";
declare var jquery: any;
declare var $: any;
import {
  FormBuilder,
  FormGroup,
  Validators,
  FormControl
} from "@angular/forms";
import { BranchService } from "../../service/branch.service";
import { UserService } from "../../service/user.service";
import { TechnicianService } from "../../service/technician.service";
import { AngularFirestore } from "@angular/fire/firestore";

import { HttpClient } from "@angular/common/http";
import { HttpErrorResponse } from "@angular/common/http";
import { FileManagerService } from "../../service/fileManager.service";
import { PhotoService } from "../../service/photo.service";
import { BranchPhotoService } from "src/app/service/branchphoto.service";
import { PackageService } from "../../service/package.service";
import { MouseEvent } from "@agm/core";

@Component({
  selector: "app-branch-add",
  templateUrl: "./branch_add.component.html",
  styleUrls: ["./branch_add.component.css"]
})
export class BranchAddComponent implements OnInit {
  form: FormGroup;
  addForm: FormGroup;
  userList = [];
  countryJson;
  amphurJson;
  thumbonJson;
  postcode;
  urlPhoto = [];
  urlPhotoUpload = [];
  submitted = false;
  submitted_edit = false;
  checkUser = false;
  servicePackageTemplate = [];

  constructor(
    private db: AngularFirestore,
    private branchService: BranchService,
    private technicianService: TechnicianService,
    private userService: UserService,
    private httpService: HttpClient,
    private fb: FormBuilder,
    private fileManagerService: FileManagerService,
    private photoService: PhotoService,
    private branchPhotoService: BranchPhotoService,
    private packageService: PackageService
  ) {
    this.form = fb.group({
      fname: ["", Validators.required],
      lname: ["", Validators.required],
      identificationCode: ["", [Validators.required, Validators.minLength(13)]],
      phone: ["", [Validators.required, Validators.minLength(10)]],
      email: ["", [Validators.required, Validators.email]]
    });

    this.addForm = fb.group({
      branchname: ["", Validators.required],
      tax: ["", [Validators.required, Validators.minLength(13)]],
      address: ["", Validators.required],
      phone: ["", [Validators.required, Validators.minLength(10)]],
      email: ["", [Validators.required, Validators.email]],
      fname: ["", Validators.required],
      lname: ["", Validators.required],
      manageremail: ["", [Validators.required, Validators.email]],
      password: ["", Validators.required],
      postcode: ["", Validators.required],
      amphur: ["", Validators.required],
      province: ["", Validators.required],
      thumbon: ["", Validators.required],
      chairamt: [0]
    });
  }

  // convenience getter for easy access to form fields
  get f() {
    return this.addForm.controls;
  }

  get fe() {
    return this.form.controls;
  }

  ngOnInit() {
    this.loadDefaultScript();
    this.userList = [];
    this.getProvince();
    this.getServicePackageTemplate();
  }

  getProvince() {
    this.httpService.get("./assets/json/data.json").subscribe(
      data => {
        this.countryJson = data as string[]; // FILL THE ARRAY WITH DATA.
        //console.log(this.countryJson);
      },
      (err: HttpErrorResponse) => {
        console.log(err.message);
      }
    );
  }

  getAmphur(e) {
    this.thumbonJson = [];
    if (e == "") {
      this.amphurJson = [];
    } else {
      this.postcode = "";
      let value = e.split("|");
      this.amphurJson = this.countryJson[value[0]][1];
    }
  }

  getThumbon(e) {
    if (e == "") {
      this.postcode = "";
    } else {
      let value = e.split("|");
      this.getLatLong(value[1]);
      this.thumbonJson = this.amphurJson[value[0]][1];
      let thumbons = this.thumbonJson[1];
      this.postcode = thumbons[1][0];
    }

    //console.log(thumbons[1][0])
  }

  getPost(e) {
    let value = e.split("|");
    let thumbons = this.thumbonJson[value[0]][1];
    this.postcode = thumbons[0];
    //console.log(this.postcode)
  }

  onSubmit(value) {
    this.submitted_edit = true;

    // stop here if form is invalid
    if (this.form.invalid) {
      return;
    }
    this.checkUser = true;

    //value["openTime"] = $('#techStartTime').val().slice(0,2) + $('#techStartTime').val().slice(3);
    //value["closeTime"] = $('#techEndTime').val().slice(0,2) + $('#techEndTime').val().slice(3);
    value["openTime"] = $('#techStartTime').val();
    value["closeTime"] = $('#techEndTime').val();
    this.userList.push(value);

    setTimeout(() => {
      $("#add-modal").modal("hide");
    }, 0);
  }

  removeUser(i) {
    const items = this.userList;
    const filteredItems = items
      .slice(0, i)
      .concat(items.slice(i + 1, items.length));
    this.userList = filteredItems;
  }

  onSelectPhotoFile(event) {
    if (event.target.files && event.target.files[0]) {
      this.urlPhotoUpload.push(event.target.files[0]);
      var filesAmount = event.target.files.length;
      for (let i = 0; i < filesAmount; i++) {
        var reader = new FileReader();

        reader.onload = (event: any) => {
          //console.log(event.target.result);
          this.urlPhoto.push(event.target.result);
        };

        reader.readAsDataURL(event.target.files[i]);
      }
    }
  }

  unSelectedPhotoFile(position) {
    console.log(position);
    this.urlPhoto.splice(position, 1);
    this.urlPhotoUpload.splice(position, 1);
  }

  submitAddBranch(value) {
    this.submitted = true;

    // stop here if form is invalid
    if (this.addForm.invalid) {
      $("#branchName").focus();
      return;
    }

    let branch = {};
    let manager = {};
    branch["name"] = value.branchname;
    branch["tax"] = value.tax;
    branch["email"] = value.email;
    branch["phone"] = value.phone;
    branch["province"] = value.province.split("|")[1];
    branch["amphur"] = value.amphur.split("|")[1];
    branch["thumbon"] = value.thumbon.split("|")[1];
    branch["postcode"] = value.postcode;
    branch["provinceId"] = Number(value.province.split("|")[0]);
    branch["amphurId"] = Number(value.amphur.split("|")[0]);
    branch["thumbonId"] = Number(value.thumbon.split("|")[0]);
    branch["techSeq"] = this.userList.length;
    branch["address"] = value.address;

    branch["chairamt"] = value.chairamt;
    branch["capturesys"] = $("#check_id").is(":checked");
    branch["openTime"] = $('#leaveStartTime').val().slice(0,2) + $('#leaveStartTime').val().slice(3);
    branch["closeTime"] = $('#leaveEndTime').val().slice(0,2) + $('#leaveEndTime').val().slice(3);

    branch["lat"] = this.markers[0].lat;
    branch["lng"] = this.markers[0].lng;

    manager["fname"] = value.fname;
    manager["lname"] = value.lname;
    manager["email"] = value.manageremail;
    manager["role"] = "admin";
    manager["password"] = value.password;

    this.branchService.getTemp().subscribe(res => {
      res.forEach(doc => {
        let newItem = doc.data();
        let tempID = doc.id;
        let temp = {};
        let branchSeq = newItem.branchSeq + 1;
        let code = this.decimalFormat2Digit(branchSeq);
        let managerCode = "M" + code + "-0001";
        branch["code"] = code;
        temp["branchSeq"] = branchSeq;
        manager["username"] = managerCode;
        manager["code"] = managerCode;

        // auto run branch code
        this.branchService.updateTemp(tempID, temp).then(res => {
          console.log("insert temp success");
        });

        // insert branch
        this.branchService.create(branch).then(res => {
          console.log("insert branch success");
          let branchID = res.id;
          manager["branchRef"] = this.db.doc("/branch/" + branchID).ref;
          manager["branchId"] = branchID;

          // insert users (Manager)
          this.userService.create(manager).then(res => {
            console.log("insert users (Manager) success");
            let branchUsers = {};
            let managerID = res.id;
            branchUsers["active"] = true;
            branchUsers["userId"] = managerID;
            branchUsers["usersRef"] = this.db.doc("/users/" + managerID).ref;
            branchUsers["branchRef"] = this.db.doc("/branch/" + branchID).ref;
            branchUsers["branchId"] = branchID;

            // insert branch user
            this.technicianService.create(branchUsers).then(res => {
              console.log("insert branchUsers success");
            });
          });

          //insert photo
          if (this.urlPhotoUpload.length > 0) {
            for (let i = 0; i < this.urlPhotoUpload.length; i++) {
              const formData = new FormData();
              formData.append("image", this.urlPhotoUpload[i]);
              formData.append("type", "branch");

              this.fileManagerService.uploadFile(formData).subscribe(res => {
                if (res.code == "200") {
                  var photoValue = {};
                  console.log(res);
                  photoValue["pathUrl"] = res.data;
                  photoValue["type"] = "BRANCH";
                  this.photoService.create(photoValue).then(res => {
                    var photoId = res.id;
                    var branchPhoto = {};
                    branchPhoto["seq"] = i + 1;
                    branchPhoto["branchId"] = branchID;
                    branchPhoto["branchRef"] = this.db.doc(
                      "/branch/" + branchID
                    ).ref;
                    branchPhoto["photoId"] = photoId;
                    branchPhoto["photoRef"] = this.db.doc(
                      "/photo/" + photoId
                    ).ref;
                    this.branchPhotoService.create(branchPhoto).then(res => {
                      console.log("upload photo " + (i + 1) + " success.");
                    });
                  });
                }
              });
            }
          }

          // insert Users (thecnician)
          if (this.userList.length > 0) {
            for (let i = 0; i < this.userList.length; i++) {
              let users = {};
              let technicianCode =
                "T" + code + "-" + this.decimalFormat4Digit(i + 1);
              users["branchRef"] = this.db.doc("/branch/" + branchID).ref;
              users["branchId"] = branchID;
              users["role"] = "tech";
              users["username"] = technicianCode;
              users["password"] = "P@ssword";
              users["code"] = technicianCode;

              users["fname"] = this.userList[i].fname;
              users["lname"] = this.userList[i].lname;
              users["email"] = this.userList[i].email;
              users["phone"] = this.userList[i].phone;
              users["identificationCode"] = this.userList[i].identificationCode;
              users["openTime"] = this.userList[i].openTime;
              users["closeTime"] = this.userList[i].closeTime;

              this.userService.create(users).then(res => {
                console.log("insert users (technician) success");
                let branchUsers = {};
                let technicianID = res.id;
                branchUsers["active"] = true;
                branchUsers["userId"] = technicianID;
                branchUsers["usersRef"] = this.db.doc(
                  "/users/" + technicianID
                ).ref;
                branchUsers["branchRef"] = this.db.doc(
                  "/branch/" + branchID
                ).ref;
                branchUsers["branchId"] = branchID;

                // insert branch user
                this.technicianService.create(branchUsers).then(res => {
                  console.log("insert branchUsers success");
                });
              });
            }
            setTimeout(() => {
              window.location.href = "#/branch";
            }, 0);
          } else {
            window.location.href = "#/branch";
          }
        }); //res for insert branch
      });
    });
  }

  getServicePackageTemplate() {
    let criteria = {};
    this.packageService.getAllPackageTemplate().subscribe(data => {
      data.forEach(doc => {
        let newItem = doc.data();
        newItem["type"] = "PACKAGE";
        newItem["checked"] = false;
        if ((newItem["status"] = "เปิดใช้บริการ")) {
          this.servicePackageTemplate.push(newItem);
        }
      });
    });

    this.packageService.getAllMenuTemplate().subscribe(data => {
      data.forEach(doc => {
        let newItem = doc.data();
        newItem["type"] = "MENU";
        newItem["checked"] = false;
        if ((newItem["status"] = "เปิดใช้บริการ")) {
          this.servicePackageTemplate.push(newItem);
        }
      });
    });

    setTimeout(() => {
      // console.log(this.servicePackageTemplate.length);
      // console.log(this.servicePackageTemplate);
    }, 1000);
  }

  back() {
    window.location.href = "#/branch";
  }

  decimalFormat2Digit(value) {
    let result = "";
    result = value < 10 ? "0" + value : value;

    return result;
  }

  decimalFormat4Digit(value) {
    let result = "";
    if (value < 10) {
      result = "000" + value;
    } else if (value > 10 && value < 100) {
      result = "00" + value;
    } else if (value > 100 && value < 1000) {
      result = "0" + value;
    } else if (value > 1000) {
      result = "" + value;
    }

    return result;
  }

  //load default script
  loadDefaultScript() {
    $("#add-modal").modal({
      show: false,
      backdrop: "static"
    });

    $('#leaveStartTime').daterangepicker({
      timePicker : true,
      singleDatePicker:true,
      timePicker24Hour : true,
      timePickerIncrement : 1,
      timePickerSeconds : false,
      locale : {
          format : 'HH:mm'
      }
      }).on('show.daterangepicker', function(ev, picker) {
      picker.container.find(".calendar-table").hide();
      });

      $('#leaveEndTime').daterangepicker({
          timePicker : true,
          singleDatePicker:true,
          timePicker24Hour : true,
          timePickerIncrement : 1,
          timePickerSeconds : false,
          locale : {
              format : 'HH:mm'
          }
      }).on('show.daterangepicker', function(ev, picker) {
      picker.container.find(".calendar-table").hide();
      });

      $('#techStartTime').daterangepicker({
        timePicker : true,
        singleDatePicker:true,
        timePicker24Hour : true,
        timePickerIncrement : 1,
        timePickerSeconds : false,
        locale : {
            format : 'HH:mm'
        }
        }).on('show.daterangepicker', function(ev, picker) {
        picker.container.find(".calendar-table").hide();
        });
  
        $('#techEndTime').daterangepicker({
            timePicker : true,
            singleDatePicker:true,
            timePicker24Hour : true,
            timePickerIncrement : 1,
            timePickerSeconds : false,
            locale : {
                format : 'HH:mm'
            }
        }).on('show.daterangepicker', function(ev, picker) {
        picker.container.find(".calendar-table").hide();
        });
  }

  // google maps zoom level
  zoom: number = 8;

  // initial center position for the map
  lat: number = 13.756331;
  lng: number = 100.501762;

  getLatLong(district) {
    this.httpService.get("./assets/json/lat-long.json").subscribe(
      data => {
        let chk = 0;
        for (let i = 0; i < 1163; i++) {
          if (data[i].district == district) {
            chk++;
            if (chk == 1) {
              this.markers = [];
              this.markers.push({
                lat: data[i].lat,
                lng: data[i].lng,
                draggable: true
              });
              this.lat = data[i].lat;
              this.lng = data[i].lng;
            }
          }
        }
      },
      (err: HttpErrorResponse) => {
        console.log(err.message);
      }
    );
  }

  clickedMarker(label: string, index: number) {
    console.log(`clicked the marker: ${label || index}`);
  }

  mapClicked($event: MouseEvent) {
    this.markers = [];
    this.markers.push({
      lat: $event.coords.lat,
      lng: $event.coords.lng,
      draggable: true
    });
  }

  markerDragEnd(m: marker, $event: MouseEvent) {
    this.markers = [];
    this.markers.push({
      lat: $event.coords.lat,
      lng: $event.coords.lng,
      draggable: true
    });
    console.log(this.markers);
  }

  markers: marker[] = [];
}

// just an interface for type safety.
interface marker {
  lat: number;
  lng: number;
  label?: string;
  draggable: boolean;
}
