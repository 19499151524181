// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,

  //firebaseConfig: {
    //apiKey: "AIzaSyCvFn9D-Y_jGClNS2rJzguMfD_VtPJdar4",
    //authDomain: "menbarber-1e8c9.firebaseapp.com",
    //databaseURL: "https://menbarber-1e8c9.firebaseio.com",
    //projectId: "menbarber-1e8c9",
    //storageBucket: "menbarber-1e8c9.appspot.com",
    //messagingSenderId: "698478030708",
    //appId: "1:698478030708:web:b1e83f9d8d2abf3d794621"
  //},

    // prod
      firebaseConfig: {
        apiKey: "AIzaSyDYvZ1lsd8PkegXaPMPWRaWoos5P_DgQK8",
        authDomain: "menbarber-146c5.firebaseapp.com",
        databaseURL: "https://menbarber-146c5.firebaseio.com",
        projectId: "menbarber-146c5",
        storageBucket: "menbarber-146c5.appspot.com",
        messagingSenderId: "741747853552",
        appId: "1:741747853552:web:e62fe18e6da89cd9e2da35",
        measurementId: "G-X0EDMW9DH4"
      },
    
    //map
    mapKey: "AIzaSyB2-BChozMOQm7Ro99dse8kJRyTMnbQU4g"

};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
