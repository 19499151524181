import {Component, OnInit} from '@angular/core';
import {Subject, Observable} from 'rxjs';
import {WebcamImage} from '../../webcam/domain/webcam-image';
import {WebcamUtil} from '../../webcam/util/webcam.util';
import {WebcamInitError} from '../../webcam/domain/webcam-init-error';
import { throwError } from 'rxjs';
import { retry, catchError, count } from 'rxjs/operators';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { PhotoService } from '../../service/photo.service';
import { HistoryService } from '../../service/history.service';
import { AngularFirestore } from '@angular/fire/firestore';
import { HistoryPhotoService } from 'src/app/service/historyphoto.service';
import { CustomerService } from '../../service/customer.service';
import {BranchService} from '../../service/branch.service';
declare var jquery: any;
declare var $: any;

@Component({
  selector: 'start-menprocess',
  templateUrl: './start-menprocess.component.html',
  styleUrls: ['./start-menprocess.component.scss']
})
export class StartMenprocessComponent  implements OnInit {
// toggle webcam on/off
  public showWebcam = true;
  public allowCameraSwitch = true;
  public multipleWebcamsAvailable = false;
  public deviceId: string;
  public facingMode: string = 'environment';
  public errors: WebcamInitError[] = [];
  public showProfile = true;
  public photoGallery = [
    {
      "pathUrl": "../assets/img/default-take-photo.png",
      "name": "",
      "order": "",
      "position_name": "ด้านหน้า"
    },
    {
      "pathUrl": "../assets/img/default-take-photo.png",
      "name": "",
      "order": "",
      "position_name": "ด้านหลัง"
    },
    {
      "pathUrl": "../assets/img/default-take-photo.png",
      "name": "",
      "order": "",
      "position_name": "ด้านขวา"
    },
    {
      "pathUrl": "../assets/img/default-take-photo.png",
      "name": "",
      "order": "",
      "position_name": "ด้านซ้าย"
    }

  ];


  sDateSearch = "";
  eDateSearch = "";
  nameSearch = "";
  remarkHistory = "";
  historyRemarkSelect;
  currentLots = 1;
  saved = false;

  // latest snapshot
  public webcamImage: WebcamImage = null;
  // webcam snapshot trigger
  private trigger: Subject<void> = new Subject<void>();
  // switch to next / previous / specific webcam; true/false: forward/backwards, string: deviceId
  private nextWebcam: Subject<boolean|string> = new Subject<boolean|string>();

  startMenprocess;
  carmera = [];
  images = [];
  imagesFinal = [];
  historyPhoto = [];
  customerItem;
  currentHistoryItem;
  customerAvatar = "../assets/img/default-user.jpg";
  remarkList = [];
  flagRemark = false;
  branchObj;
  capturesys = true;

  // Define API
  //apiURL = 'https://menbarber-api.herokuapp.com/api';
  apiURL = 'https://hairline7.com/menbarber/api';
  constructor(private http: HttpClient,
    private photoService: PhotoService,
    private historyService: HistoryService,
    private db: AngularFirestore,
    private historyPhotoService: HistoryPhotoService,
    private customerService: CustomerService,
    private branchService: BranchService
    ) { }

  // Http Options
  httpOptions = {
    headers: new HttpHeaders({
        'Content-Type': 'application/json'
    })
  }

  branchId;
  public ngOnInit(): void {
    this.startMenprocess = JSON.parse(localStorage.getItem('startMenprocess'));
    console.log(this.startMenprocess);

    let token = JSON.parse(localStorage.getItem("authenMenbarberToken"));
    this.branchId = token["branchId"];

    this.showWebcam = !this.showWebcam;
    WebcamUtil.getAvailableVideoInputs()
    .then((mediaDevices: MediaDeviceInfo[]) => {
      this.multipleWebcamsAvailable = mediaDevices && mediaDevices.length > 1;
      console.log(mediaDevices);
      for(let i=0;i<mediaDevices.length;i++){
        this.carmera.push(mediaDevices[i]['deviceId']);
        console.log("mediaDevices: " + mediaDevices[i]['deviceId']);
      }
    });

    //get history customer
    this.getPhotoHistoryByCustomer().then(() => {
      $('#loading').show();
      //sort
      setTimeout(() => {
        this.historyPhoto.sort((c, d) => Number(d.lots) - Number(c.lots));
      }, 1000);
      setTimeout(() => {
        this.historyPhoto[0].item.sort((a, b) => Number(a.seq) - Number(b.seq));
        console.log(this.historyPhoto[0].item)
        if(this.historyPhoto.length > 0){
          //this.selectedImageItems(this.historyPhoto[0].historyId, true, "parent");
          this.historyPhoto[0].selected = true;
          this.remarkList.push(this.historyPhoto[0].remark);
          for (let j = 0; j < this.historyPhoto[0].item.length; j++) {
            this.historyPhoto[0].item[j].disable = true;
            this.historyPhoto[0].item[j].selected = true;
            this.photoGallery[(this.historyPhoto[0].item[j].seq - 1)].pathUrl = this.historyPhoto[0].item[j].pathUrl;
          }
          $('#loading').hide();
        }

      }, 500);
    });
    //this.getLastPhoto();

    //get customer
    this.getCustomerByKey();

    //load default script
    this.loadDefaultScript();

    //get current lots
    this.getCurrentLost();

    //get current history
    this.getCurrentHistory();

    //get current branch model
    this.getCurrentBranch();
  }

  getCurrentBranch(){
    this.branchService.getByKey(this.branchId).subscribe(res => {
      this.branchObj = res.data();
      //console.log("branch obj");
      //console.log(this.branchObj);
      this.capturesys = this.branchObj.capturesys;
    });
  }

  loadDefaultScript(){
    $('.sDate').datetimepicker({
      format: 'DD/MM/YYYY',
      icons: {
          time: "fa fa-clock-o",
          date: "fa fa-calendar",
          up: "fa fa-chevron-up",
          down: "fa fa-chevron-down",
          previous: 'nc-icon nc-stre-left',
          next: 'nc-icon nc-stre-right',
          today: 'fa fa-screenshot',
          clear: 'fa fa-trash',
          close: 'fa fa-remove'
      }
    });

  $('.eDate').datetimepicker({
    format: 'DD/MM/YYYY',
    icons: {
        time: "fa fa-clock-o",
        date: "fa fa-calendar",
        up: "fa fa-chevron-up",
        down: "fa fa-chevron-down",
        previous: 'fa fa-chevron-left',
        next: 'fa fa-chevron-right',
        today: 'fa fa-screenshot',
        clear: 'fa fa-trash',
        close: 'fa fa-remove'
    }
  });
}

getCurrentLost(){
    this.historyService.getUserHistoryByCustomerId(this.startMenprocess["customerId"]).subscribe(data => {
      data.forEach(doc => {
        let newItem = doc.data(); 
        if (newItem.historyRef) {
          newItem.historyRef.get().then(res => {
            var hisRef = res.data(); 
            if (hisRef!== undefined && hisRef.status == "HIS") {
              this.currentLots = this.currentLots + 1;
               console.log(this.currentLots);
            }//end check his ref
          })
        }
      });
       
    },err => {
        console.log(err);
      });
}

getCurrentHistory(){
  this.historyService.getByKey(this.startMenprocess["historyId"]).subscribe(data =>{
    console.log("currentHistoryItem : ");
    console.log(data.payload.data());
    this.currentHistoryItem = data.payload.data();
  });
}

getCustomerByKey(){
  this.customerService.getByKey(this.startMenprocess["customerId"]).subscribe(data => {
    console.log("customerItem : ");
    console.log(data.payload.data());
    this.customerItem = data.payload.data();
    if(this.customerItem.image_avartar){
      this.customerAvatar = this.customerItem.image_avartar;
    }

  });
}

  searchPhotoHistoryByCustomer(){
    $('#loading').show();
    console.log(this.sDateSearch);
    console.log(this.eDateSearch);
    console.log(this.nameSearch);

    if(this.nameSearch == ""){
      for(let i=0;i<this.historyPhoto.length;i++){
        for(let j=0;j<this.historyPhoto[i].item.length;j++){
          this.historyPhoto[i].item[j].show = true;
        }
      }
    }else{
      for(let i=0;i<this.historyPhoto.length;i++){
        for(let j=0;j<this.historyPhoto[i].item.length;j++){
           if(this.historyPhoto[i].item[j].name.includes(this.nameSearch)){
              this.historyPhoto[i].item[j].show = true;
           }else{
            this.historyPhoto[i].item[j].show = false;
           }
        }
      }
      console.log(this.historyPhoto);
    }
    $('#loading').hide();
  }

  getLastPhoto(){
    this.historyService.getUserHistoryByCustomerId(this.startMenprocess["customerId"]).subscribe(data => {
      data.forEach(doc => {
        let newItem = doc.data();
        if(newItem.historyRef){
          newItem.historyRef.get().then(res => {
            var hisRef = res.data();
            if(hisRef.status == "HIS"){
              this.historyPhotoService.getPhotoByHistoryId(newItem.historyId).subscribe(data =>{
                data.forEach(doc => {
                  let historyPhotoItem = doc.data();

                })
              })
            }
          })
        }
      });
    })
  }

  getPhotoHistoryByCustomer(){
    var promise = new Promise((resolve, reject) => {
    this.historyPhoto = [];
    this.historyService.getUserHistoryByCustomerId(this.startMenprocess["customerId"]).subscribe(data => {
       data.forEach(doc => {
        let newItem = doc.data();
        console.log(newItem);
        if(newItem.historyRef){
          newItem.historyRef.get().then(res => {
            var hisRef = res.data();
            if(hisRef.status == "HIS"){
              let history = {"item":[],"historyId":"","endDate":null,"lots":null,"remark":null,"selected":false,"disable":false,"show":true,"order":0};
              //console.log(newItem);
              history.historyId = newItem.historyId;
              history.endDate = hisRef.endDate;
              history.lots = hisRef.lots;
              history.remark = hisRef.remark;

              this.historyPhotoService.getPhotoByHistoryId(newItem.historyId).subscribe(data =>{
                //console.log(data.docs);
                data.forEach(doc => {
                    //console.log(doc.data());
                    let historyPhotoItem = doc.data();
                    //console.log(historyPhotoItem);
                    if(historyPhotoItem.photoRef){
                      historyPhotoItem.photoRef.get().then(res => {
                        let newItem = {};
                        newItem['lots'] = historyPhotoItem.lots;
                        newItem['seq'] = historyPhotoItem.seq;
                        newItem['name'] = historyPhotoItem.name;
                        newItem['pathUrl'] = res.data().pathUrl;
                        newItem['type'] = res.data().type;
                        newItem['selected'] = false;
                        newItem['disable'] = false;
                        newItem['show'] = true;
                        newItem['order'] = 0;
                        //console.log(newItem);
                        history.item.push(newItem);
                        resolve();
                      })
                    }//end check photo ref
                })
              })
              this.historyPhoto.push(history);
            }//end check his ref
          })
        }
      })
    },
    err => {
      console.log(err);
    })
  })
  return promise;
  }

  openHistoryPhoto(){
    //sort
    setTimeout(() => {
      this.historyPhoto.sort((c, d) => Number(d.lots) - Number(c.lots));
    }, 1);
    for(let i=0;i<this.historyPhoto.length;i++){
      setTimeout(() => {
        this.historyPhoto[i].item.sort((a, b) => Number(a.seq) - Number(b.seq));
      }, 1);
    }
    $('#history-album-modal').modal('show');
  }

  openRemarkDialog(){
    $('#remark-modal').modal('show');
  }

  startProcess(){
    if(!this.isStart){
      this.isStart = true;
      var historyUpdate = {};
        historyUpdate["startDate"] = new Date();
        this.historyService.update(this.startMenprocess.historyId, historyUpdate)
        .then(
          res => {
            console.log("start process already.")
          }
        )
    }
  }


  backProfile(){
    if(this.capturesys){
      this.showWebcam = false;
      this.isShowImage = false;
      this.showProfile = true;
    }else{
          alert("สาขาบริการไม่รองรับการถ่ายภาพ");
    }
  }


  loadHistoryPhotoToPlan(){
    $('#history-album-modal').modal('hide');
  }

  togglePreview = false;
  previewImgPath = "";
  openPreview(img){
    this.previewImgPath = img;
    this.togglePreview = !this.togglePreview;
  }

  selectedImageItems(imageItemName:string, isChecked: boolean, type: string) {
    console.log(isChecked);
    console.log(imageItemName);
    let seq = 0;
    this.historyRemarkSelect = "";
    if(type == "parent"){
      this.remarkList = [];
      if(isChecked){
        for(let i=0;i<this.historyPhoto.length;i++){
          if(this.historyPhoto[i].historyId != imageItemName){
            this.historyPhoto[i].disable = true;
            this.historyPhoto[i].selected = false;

            for(let j=0;j<this.historyPhoto[i].item.length;j++){
              this.historyPhoto[i].item[j].disable = true;
              this.historyPhoto[i].item[j].selected = false;
            }
          }else{
            this.historyPhoto[i].selected = true;
            this.remarkList.push(this.historyPhoto[i].remark);
            for(let j=0;j<this.historyPhoto[i].item.length;j++){
              this.historyPhoto[i].item[j].disable = true;
              this.historyPhoto[i].item[j].selected = true;
              //console.log(this.historyPhoto[i].item);
              this.photoGallery[(this.historyPhoto[i].item[j].seq - 1)].pathUrl = this.historyPhoto[i].item[j].pathUrl;
            }
          }
        }
      }else{
          for(let i=0;i<this.historyPhoto.length;i++){
            this.historyPhoto[i].disable = false;
            this.historyPhoto[i].selected = false;
            for(let j=0;j<this.historyPhoto[i].item.length;j++){
              this.historyPhoto[i].item[j].disable = false;
              this.historyPhoto[i].item[j].selected = false;
            }
          }
          for(let i=0;i<this.photoGallery.length;i++){
            this.photoGallery[i].pathUrl = "../assets/img/default-take-photo.png";
          }
      }
    }else if(type == "item"){
      let count = 0;
      for(let i=0;i<this.historyPhoto.length;i++){
        this.historyPhoto[i].disable = true;
        this.historyPhoto[i].selected = false;
        
        for(let j=0;j<this.historyPhoto[i].item.length;j++){
          if(this.historyPhoto[i].item[j].selected){
            count++;
          }
        }

        for(let j=0;j<this.historyPhoto[i].item.length;j++){
          if(imageItemName == this.historyPhoto[i].item[j].name){
            if(isChecked){
              this.historyPhoto[i].item[j].selected = true;
              this.photoGallery[(this.historyPhoto[i].item[j].seq - 1)].pathUrl = this.historyPhoto[i].item[j].pathUrl;
              this.remarkList.push(this.historyPhoto[i].remark);
              console.log(this.remarkList);
              count++;
            }else{
              this.historyPhoto[i].item[j].selected = false;
              this.photoGallery[(this.historyPhoto[i].item[j].seq - 1)].pathUrl = "../assets/img/default-take-photo.png";
              count--;
            }
            seq = this.historyPhoto[i].item[j].seq;
          }
        }
      }

      console.log("seq:"+seq);
        //start disable and enable position
        for(let i=0;i<this.historyPhoto.length;i++){
          for(let j=0;j<this.historyPhoto[i].item.length;j++){
            if(imageItemName != this.historyPhoto[i].item[j].name){
              if(isChecked){
                if(this.historyPhoto[i].item[j].seq == seq){
                    this.historyPhoto[i].item[j].disable = true;
                }
              }else{
                if(this.historyPhoto[i].item[j].seq == seq){
                  this.historyPhoto[i].item[j].disable = false;
                }
              }
            }
          }
        }
        //end disable and enable position


      if(count == 4){

        for(let i=0;i<this.historyPhoto.length;i++){
          this.historyPhoto[i].disable = true;
          this.historyPhoto[i].selected = false;
          for(let j=0;j<this.historyPhoto[i].item.length;j++){
            if(this.historyPhoto[i].item[j].selected){
              this.historyPhoto[i].item[j].disable = false;
              this.historyPhoto[i].item[j].selected = true;
            }else{
              this.historyPhoto[i].item[j].disable = true;
              this.historyPhoto[i].item[j].selected = false;
            }
          }
        }

      }else if(count == 0){
        for(let i=0;i<this.historyPhoto.length;i++){
          this.historyPhoto[i].disable = false;
          this.historyPhoto[i].selected = false;
          for(let j=0;j<this.historyPhoto[i].item.length;j++){
            this.historyPhoto[i].item[j].disable = false;
            this.historyPhoto[i].item[j].selected = false;
          }
        }

      }

    }
}
  

selectedNoRemark(){
  this.flagRemark = !this.flagRemark;
}

processSave(mode){
  $('#loading').show();
      //update point to customer
        var customerUpdate = {};
        
        //update lots to history
        var historyUpdate = {};
            historyUpdate["lots"] = this.currentLots;
            historyUpdate["remark"] = this.remarkHistory;
            this.historyService.update(this.startMenprocess.historyId, historyUpdate)
            .then(
              res => {
                console.log("Update lots and remark to history success.")
              }
          );


        for(let i=0;i<this.imagesFinal.length;i++){
          
          var image = this.imagesFinal[i];
          var ImageURL = image._imageAsDataUrl;
          const date = new Date().valueOf();
          let text = '';
          const possibleText = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
          for (let i = 0; i < 5; i++) {
            text += possibleText.charAt(Math.floor(Math.random() *    possibleText.length));
          }
          const imageName = date + text + '.jpeg';
          var block = ImageURL.split(";");
          var contentType = block[0].split(":")[1];
          var realData = block[1].split(",")[1];
          var blob = this.b64toBlob(realData, contentType,null);
          const imageFile = new File([blob], imageName, { type: contentType });
          
          if(mode == 'STANDARD'){
            const formData = new FormData();
            formData.append('image', imageFile);
            formData.append('type', "customer");
          
            this.uploadFile(formData)
              .subscribe(res => {
                if (res.code == "200") {
                  var photoValue = {};
                  console.log(res);
                  photoValue["pathUrl"] = res.data;
                  photoValue["type"] = "HIS";
                  this.photoService.create(photoValue)
                  .then(
                    res => {
                      var photoId = res.id;
                      console.log(photoId);
                      var historyUpdate = {};
                      historyUpdate["status"] = "HIS";
                      historyUpdate["branchId"] = this.branchId;
                      historyUpdate["photoMode"] = mode;
                      historyUpdate["endDate"] = new Date();
                      this.historyService.update(this.startMenprocess.historyId, historyUpdate)
                      .then(
                        res => {
                            var historyPhotoValue = {};
                            historyPhotoValue["historyId"] = this.startMenprocess.historyId;
                            historyPhotoValue["historyRef"] = this.db.doc('/history/'+this.startMenprocess.historyId).ref;
                            historyPhotoValue["photoId"] = photoId;
                            historyPhotoValue["photoRef"] = this.db.doc('/photo/'+photoId).ref;
                            historyPhotoValue["seq"] = (i+1);
                            historyPhotoValue["lots"] = this.currentLots;
                            historyPhotoValue["name"] = this.currentLots + "_" + (i+1);
                            this.historyService.createHistoryPhoto(historyPhotoValue)
                            .then(
                              res => {
                                if(i==(this.imagesFinal.length - 1)){
                                  this.saved = true;
                                  $('#loading').hide();
                                  $('#process-save-modal').modal('hide');
                                  $('#remark-save-success').modal('show');
                                }
                              })
                        }
                      )

                    }
                  )

                }
              })
          }else if(mode == 'VIP'){
            const formData = new FormData();
            formData.append('image', imageFile);
          
            this.uploadVipFile(formData)
              .subscribe(res => {
                if (res.code == "200") {
                  var photoValue = {};
                  console.log(res);
                  photoValue["pathUrl"] = res.data;
                  photoValue["type"] = "HIS";
                  this.photoService.create(photoValue)
                  .then(
                    res => {
                      var photoId = res.id;
                      console.log(photoId);
                      var historyUpdate = {};
                      historyUpdate["status"] = "HIS";
                      historyUpdate["branchId"] = this.branchId;
                      historyUpdate["photoMode"] = mode;
                      historyUpdate["endDate"] = new Date();
                      this.historyService.update(this.startMenprocess.historyId, historyUpdate)
                      .then(
                        res => {
                            var historyPhotoValue = {};
                            historyPhotoValue["historyId"] = this.startMenprocess.historyId;
                            historyPhotoValue["historyRef"] = this.db.doc('/history/'+this.startMenprocess.historyId).ref;
                            historyPhotoValue["photoId"] = photoId;
                            historyPhotoValue["photoRef"] = this.db.doc('/photo/'+photoId).ref;
                            historyPhotoValue["seq"] = (i+1);
                            historyPhotoValue["lots"] = this.currentLots;
                            historyPhotoValue["name"] = this.currentLots + "_" + (i+1);
                            this.historyService.createHistoryPhoto(historyPhotoValue)
                            .then(
                              res => {
                                if(i==(this.imagesFinal.length - 1)){
                                  this.saved = true;
                                  $('#loading').hide();
                                  $('#process-save-modal').modal('hide');
                                  $('#remark-save-success').modal('show');
                                }
                              })
                        }
                      )

                    }
                  )

                }
              })
          }
             
          
          // console.log(imageAsDataUrl);
        }//end for
}


  endProcess() {
    this.isStart = true;
    $('#loading').show();
    //update point to customer
    var customerUpdate = {};

    //update lots to history
    var historyUpdate = {};
    historyUpdate["lots"] = this.currentLots;
    historyUpdate["remark"] = this.remarkHistory;
    historyUpdate["status"] = "HIS";
    historyUpdate["branchId"] = this.branchId;
    historyUpdate["startDate"] = new Date();
    historyUpdate["endDate"] = new Date();
    this.historyService.update(this.startMenprocess.historyId, historyUpdate)
      .then(
        res => {
          this.saved = true;
          $('#loading').hide();
          $('#process-save-modal').modal('hide');
          $('#remark-save-success').modal('show');

        }
      );
  } 


 save() {
    if(this.capturesys){
      if(this.saved){
        alert("ไม่สามารถบันทึกซ้ำได้");
        return;
       }
        // console.log(this.images);
        if(this.imagesFinal.length == 0){
          alert("ยังไม่ได้เลือกรูป");
          return;
        }
    
        if(!this.flagRemark && (this.remarkHistory == "" || this.remarkHistory == null)){
          $('#remark-modal').modal('show');
        }else{
          $('#process-save-modal').modal('show');
        }

    }else{
        alert("สาขาบริการไม่รองรับการถ่ายภาพ");
    }
  }

  b64toBlob(b64Data, contentType, sliceSize) {
    contentType = contentType || '';
    sliceSize = sliceSize || 512;
  
    var byteCharacters = atob(b64Data);
    var byteArrays = [];
  
    for (var offset = 0; offset < byteCharacters.length; offset += sliceSize) {
        var slice = byteCharacters.slice(offset, offset + sliceSize);
  
        var byteNumbers = new Array(slice.length);
        for (var i = 0; i < slice.length; i++) {
            byteNumbers[i] = slice.charCodeAt(i);
        }
  
        var byteArray = new Uint8Array(byteNumbers);
  
        byteArrays.push(byteArray);
    }
  
  var blob = new Blob(byteArrays, {type: contentType});
  return blob;
  }

  // upload
uploadFile(file): Observable<any> {
  console.log(file);
  return this.http.post<any>(this.apiURL + '/v1/upload', file).pipe(
      retry(1),
      catchError(this.handleError)
  );
}

  // upload
  uploadVipFile(file): Observable<any> {
    console.log(file);
    return this.http.post<any>(this.apiURL + '/v1/dicut', file).pipe(
        retry(1),
        catchError(this.handleError)
    );
  }


// Error handling
handleError(error) {
let errorMessage = '';
if (error.error instanceof ErrorEvent) {
    // Get client-side error
    errorMessage = error.status;
} else {
    // Get server-side error
    errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
}
//window.alert(errorMessage);
return throwError(errorMessage);
}

  public back(): void {
    localStorage.removeItem('startMenprocess');
    window.location.href = "/";
  }

  public triggerSnapshot(): void {
    if(this.capturesys){
    this.index++;
    this.trigger.next();
    }else{
      alert("สาขาบริการไม่รองรับการถ่ายภาพ");
    }
  }

  isStart = false;
  public toggleWebcam(): void {
    if(this.capturesys){
        if(this.isStart){
          this.images = [];
          this.showWebcam = true;
          this.isShowImage = false;
          this.isStart = true;
          this.showProfile = false;
        }else{
          alert("กรุณากดเริ่มโปรแกรมก่อนเปิดกล้อง");
        }
    }else{
      alert("สาขาบริการไม่รองรับการถ่ายภาพ");
    }
  }

  isShowImage = false;
  public toggleImage(): void {
    if(this.capturesys){
      if(this.isStart){
        this.imagesFinal = [];
        this.isShowImage = true;
        for (var i = 0; i < this.images.length; i++) {
          this.imagesFinal.push(this.images[i]);
        }
      }else{
        alert("กรุณากดเริ่มโปรแกรมก่อนถ่ายรูป");
      }
    }else{
          //alert("สาขาบริการไม่รองรับการถ่ายภาพ");
    }
  }

  public handleInitError(error: WebcamInitError): void {
    if (error.mediaStreamError && error.mediaStreamError.name === "NotAllowedError") {
      console.warn("Camera access was not allowed by user!");
    }
    this.errors.push(error);
  }

  showNextWebcamTrack(index,item){
    console.log(item);
    //this.showNextWebcam(item);
    //this.nextWebcam.next(item);
  }
  
  public showNextWebcam(directionOrDeviceId: boolean|string): void {
    // true => move forward through devices
    // false => move backwards through devices
    // string => move to device with given deviceId
    this.nextWebcam.next(directionOrDeviceId);
  }

  index = 0;
  public handleImage(webcamImage: WebcamImage): void {
    console.log('received webcam image', webcamImage);
    webcamImage["id"] = this.index;
    this.images.push(webcamImage);
    this.webcamImage = webcamImage;
  }

  public cameraWasSwitched(deviceId: string): void {
    console.log('active device: ' + deviceId);
    this.deviceId = deviceId;
  }

  public get triggerObservable(): Observable<void> {
    if(this.isStart){
    return this.trigger.asObservable();
    }
  }

  public get nextWebcamObservable(): Observable<boolean|string> {
    return this.nextWebcam.asObservable();
  }

  public get videoOptions(): MediaTrackConstraints {
    const result: MediaTrackConstraints = {};
    if (this.facingMode && this.facingMode !== "") {
      result.facingMode = { ideal: this.facingMode };
    }
    return result;
  }

}
