import { Component, OnInit } from "@angular/core";
declare var jquery: any;
declare var $: any;
import { HttpClient } from "@angular/common/http";
import { HttpErrorResponse } from "@angular/common/http";

@Component({
  selector: "app-freelanceProfile",
  templateUrl: "./freelanceProfile.component.html",
  styleUrls: ["./freelanceProfile.component.css"]
})
export class FreelanceProfileComponent implements OnInit {

  constructor(
    private httpService: HttpClient
  ) {}

  ngOnInit() {
    this.loadDefaultScript(); 
  } 

  //load default script
  loadDefaultScript() {
    
    setTimeout(() => {
      $("#tb_freelance_voice_list").DataTable({
        retrieve: true,
        paging: true,
        responsive: true
      });
    }, 0);

    setTimeout(() => {
      $("#tb_freelance_history_list").DataTable({
        retrieve: true,
        paging: true,
        responsive: true
      });
    }, 0);
  }
  //end load default script
}
