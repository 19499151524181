import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';

@Injectable({
    providedIn: 'root'
})
export class BranchService {

    constructor(public db: AngularFirestore) {}

    create(value){
      return this.db.collection('branch').add(value);
    }

    getByKey(key){
      return this.db.collection('branch').doc(key).get();
    }

    set(key, value){
      return this.db.collection('branch').doc(key).set(value);
    }

    update(key, value){
      return this.db.collection('branch').doc(key).update(value);
    }

    searchByCriteria(provinceId) {
      if (provinceId == "") {
        return this.db.collection('branch').get();
      }  else if (provinceId != "") {
        return this.db.collection('branch',ref => ref.where('provinceId', '==', Number(provinceId)))
          .get();
      }
    }

    delete(key){
      return this.db.collection('branch').doc(key).delete();
    }

    get(){
      return this.db.collection('branch').get();
    }

    search(searchValue){
      return this.db.collection('branch',ref => ref.where('nameToSearch', '>=', searchValue)
        .where('nameToSearch', '<=', searchValue + '\uf8ff'))
        .snapshotChanges()
    }

    searchByTitle(value){
      return this.db.collection('branch',ref => ref.orderBy('title').startAt(value)).snapshotChanges();
    }

    getTemp(){
      return this.db.collection('branchtemp').get();
    }

    updateTemp(key, value){
      return this.db.collection('branchtemp').doc(key).update(value);
    }

}
