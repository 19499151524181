import { Component, OnInit } from '@angular/core';
declare var jquery: any;
declare var $: any;
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { CustomerService } from '../../service/customer.service';
import { FileManagerService } from '../../service/fileManager.service';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { HttpClient } from "@angular/common/http";
import { HttpErrorResponse } from "@angular/common/http";
import {Subject, Observable} from 'rxjs';
import {WebcamImage} from '../../webcam_default/domain/webcam-image';
import {WebcamUtil} from '../../webcam_default/util/webcam.util';
import {WebcamInitError} from '../../webcam_default/domain/webcam-init-error';
import { throwError } from 'rxjs';
import { retry, catchError, count } from 'rxjs/operators';

@Component({
  selector: 'app-newcustomer',
  templateUrl: './newcustomer.component.html',
  styleUrls: ['./newcustomer.component.css']
})
export class NewCustomerComponent implements OnInit {

  selectedFile: File;
  imgURL1: any;
  formAdd: FormGroup;
  customers: any[];
  seq = 0;
  saved = false;
  submitted = false;
  urlSafe: SafeResourceUrl;

  countryJson;
  amphurJson;
  thumbonJson;
  postcode = "";
  birthdate = "";

  // latest snapshot
  public webcamImage: WebcamImage = null;
  // webcam snapshot trigger
  private trigger: Subject<void> = new Subject<void>();
  // switch to next / previous / specific webcam; true/false: forward/backwards, string: deviceId
  private nextWebcam: Subject<boolean|string> = new Subject<boolean|string>();
  public showWebcam = false;
  public allowCameraSwitch = true;
  public multipleWebcamsAvailable = false;
  public deviceId: string;
  public facingMode: string = 'environment';
  public errors: WebcamInitError[] = [];
  isStart = false;
  carmera = [];


  constructor(
    private fb: FormBuilder,
    private customerService: CustomerService,
    private fileManagerService: FileManagerService,
    private httpService: HttpClient,
    public sanitizer: DomSanitizer
    ) { 
    this.formAdd = fb.group({
      'fname': ['', Validators.required ],
      'lname': [''],
      'gender': [''],
      'tel': ['',
          [Validators.required,Validators.minLength(10)],
      ],
      'email': [''],
      'birthdate': [''],
      'address': [''],
      'postcode': [''],
      'amphur': [''],
      'province': [''],
      'thumbon': [''],
    });
  }

  ngOnInit() {
    $('#loading').show();
    this.loadDefaultScript();
    this.getData();
    this.imgURL1 = "../../assets/img/default-user.jpg";
    this.getProvince();

    WebcamUtil.getAvailableVideoInputs()
    .then((mediaDevices: MediaDeviceInfo[]) => {
      this.multipleWebcamsAvailable = mediaDevices && mediaDevices.length > 1;
      console.log(mediaDevices);
      for(let i=0;i<mediaDevices.length;i++){
        this.carmera.push(mediaDevices[i]['deviceId']);
        console.log("mediaDevices: " + mediaDevices[i]['deviceId']);
      }
    });
    
  }

  loadDefaultScript(){
    $('#birthdate').datetimepicker({
      format: 'DD/MM/YYYY',
      icons: {
          time: "fa fa-clock-o",
          date: "fa fa-calendar",
          up: "fa fa-chevron-up",
          down: "fa fa-chevron-down",
          previous: 'nc-icon nc-stre-left',
          next: 'nc-icon nc-stre-right',
          today: 'fa fa-screenshot',
          clear: 'fa fa-trash',
          close: 'fa fa-remove'
      }
    });
    
  }

  getProvince() {
    this.httpService.get("./assets/json/data.json").subscribe(
      data => {
        setTimeout(function() {
          $('#loading').hide();
        }, 500);

        this.countryJson = data as string[]; // FILL THE ARRAY WITH DATA.
        //console.log(this.countryJson);
      },
      (err: HttpErrorResponse) => {
        console.log(err.message);
      }
    );
  }

  getAmphur(e) {
    this.thumbonJson = [];
    if (e == "") {
      this.amphurJson = [];
    } else {
      this.postcode = "";
      let value = e.split("|");
      this.amphurJson = this.countryJson[value[0]][1];
    }
  }

  getThumbon(e) {
    if (e == "") {
      this.postcode = "";
    } else {
      let value = e.split("|");
      this.thumbonJson = this.amphurJson[value[0]][1];
      let thumbons = this.thumbonJson[1];
      this.postcode = thumbons[1][0];
    }

    //console.log(thumbons[1][0])
  }

  getPost(e) {
    let value = e.split("|");
    let thumbons = this.thumbonJson[value[0]][1];
    this.postcode = thumbons[0];
    //console.log(this.postcode)
  }

  getData() {
    this.customerService.getSeqMax().subscribe(data => {
      data.forEach(doc => {
        if(doc.data()){
          this.seq = doc.data().seq+1;
          console.log(this.seq);
        }
      })
    })
  }

  onSubmit(value){
    this.submitted = true;
    if(this.formAdd.valid){
      //validate tel
      this.customerService.search("tel",value["tel"]).subscribe(res =>{
        if(res.size == 0){
          console.log(this.birthdate);
          console.log(value);
          $('#loading').show();
          value["seq"] = this.seq;
          value["no"] = this.gennerateCusNo();
          value["password"] = value["tel"];
          value["name"] = value["fname"] + " " +value["lname"];
          value["provider"] = "menbarber";
          var province = value.province;
          var amphur = value.amphur;
          var thumbon = value.thumbon;
          if(province){
            value["province"] = province.split("|")[1];
          }
          if(amphur){
            value["amphur"] = amphur.split("|")[1];
          }
          if(thumbon){
            value["thumbon"] = thumbon.split("|")[1];
          }
          if (value.postcode){
            value["postcode"] = value.postcode;
          }
          console.log(value);
          if (typeof this.selectedFile !== "undefined") {
            const formData = new FormData();
            console.log(this.selectedFile);
            formData.append('image', this.selectedFile);
            formData.append('type', "customer");
            this.fileManagerService.uploadFile(formData)
              .subscribe(res => {
                console.log(res);
                if (res.code == "200") {
                  value["image_avartar"] = res.data;
                  console.log(value);
                  this.customerService.create(value).then(res => {
                    console.log(res);
                    this.sendSms(value["tel"],value["password"]);
                    $('#loading').hide();
                    this.openSuccess();
                    // window.location.href = "/";
                  })
                }
              })
          }else{
            this.customerService.create(value).then(res => {
              console.log(res);
              this.sendSms(value["tel"],value["password"]);
              $('#loading').hide();
              this.openSuccess();
            })
          }
        }else{
          alert("ลูกค้ามีข้อมูลในระบบแล้ว กรุณาตรวจสอบอีกครั้ง");
        }
      });
    }else{
      $('#loading').hide();
      console.log("in valid data");
    }
  }

  openSuccess(){
    this.saved = true;
    $('#customer-save-success').modal('show');
  }
  
  genPassword(){
    let password = '';
      const possibleText = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
      for (let i = 0; i < 6; i++) {
        password += possibleText.charAt(Math.floor(Math.random() * possibleText.length));
      }
    return password;
  }

  pad(number,size) {
    var s = String(number);
    while (s.length < (size || 2)) {s = "0" + s;}
    return s;
  }

  gennerateCusNo() {
    let result = "";
    if (this.seq > 0) {
      var no = this.seq;
      result = "CUST-" + this.pad(no,7);
    } else {
      result = "CUST-0000001";
    }
    return result;
  }

  onFileChanged(event) {
    this.selectedFile = event.target.files[0];
    console.log(this.selectedFile);
    this.preview(event.target.files,null);
    var ss = this.dataURLtoFile(this.imgURL1,'test.jpg');
    console.log(ss);
  }

  dataURLtoFile(dataurl, filename) {
    try {
      var arr = dataurl.split(','),
          mime = arr[0].match(/:(.*?);/)[1],
          bstr = atob(arr[1]), 
          n = bstr.length, 
          u8arr = new Uint8Array(n);
          
      while(n--){
          u8arr[n] = bstr.charCodeAt(n);
      }
    }
    catch(err) {
      console.log(err);
      // document.getElementById("demo").innerHTML = err.message;
    }
    return new File([u8arr], filename, {type:mime});
  }

  preview(files, type) {
    if (files.length === 0)
      return;
 
    if(type == 'WEBCAM'){
      var mimeType = files.type;
      if (mimeType.match(/image\/*/) == null) {
        //this.message1 = "Only images are supported.";
        return;
      }
  
      var reader = new FileReader();
      reader.readAsDataURL(files); 
      reader.onload = (_event) => { 
        this.imgURL1 = reader.result; 
        // console.log(this.imgURL1);
      }
    }else{
      var mimeType = files[0].type;
      if (mimeType.match(/image\/*/) == null) {
        //this.message1 = "Only images are supported.";
        return;
      }
  
      var reader = new FileReader();
      reader.readAsDataURL(files[0]); 
      reader.onload = (_event) => { 
        this.imgURL1 = reader.result; 
        // console.log(this.imgURL1);
      }
    }
  }

  // sendSms
  sendSms(mobile,password){
    const form = new FormData();
    form.append("mobile", mobile);
    form.append("message", "username="+mobile+"\n"+"password="+password);
    this.fileManagerService.sendSms(form)
      .subscribe(res => {
        console.log(res);
        window.location.href = "/";
      })
  }

  //test pdf
  pdfUrl = "";
  uploadPdf(){
    const form = new FormData();
    form.append("invoiceno", "123456789");
    form.append("order[]", "ตัดผม");
    form.append("order[]", "สระผม");
    form.append("order_price[]", "100.00");
    form.append("order_price[]", "100.00");
    form.append("customer_no", "CUS-00001");
    form.append("customer_name", "พิสิษฐ์ งามการ");
    form.append("cashier", "สมร");
    form.append("customer_phone", "0883339689");
    form.append("point", "1");
    form.append("sum_point", "7");
    form.append("total", "200.00");
    form.append("net", "200.00");
    form.append("cash", "1000.00");
    form.append("change", "800.00");
    form.append("vat_able", "200.00");
    form.append("vat", "21.00");

    this.fileManagerService.createReceipt(form)
      .subscribe(res => {
        console.log(res);
        if (res.code == "200") {
          this.pdfUrl = res.data;
          this.urlSafe= this.sanitizer.bypassSecurityTrustResourceUrl(this.pdfUrl);
          $('#receipt-dialog').modal('show');
        }
      })
  }


  public toggleWebcam(): void {
    this.showWebcam = !this.showWebcam;
    this.isStart = true;
  }

  public cancelWebcam(): void{
    this.showWebcam = !this.showWebcam;
    this.isStart = false;
  }

  public handleInitError(error: WebcamInitError): void {
    if (error.mediaStreamError && error.mediaStreamError.name === "NotAllowedError") {
      console.warn("Camera access was not allowed by user!");
    }
    this.errors.push(error);
  }

  showNextWebcamTrack(index,item){
    console.log(item);
    this.showNextWebcam(item);
    this.nextWebcam.next(item);
  }
  
  public showNextWebcam(directionOrDeviceId: boolean|string): void {
    // true => move forward through devices
    // false => move backwards through devices
    // string => move to device with given deviceId
    this.nextWebcam.next(directionOrDeviceId);
  }

  index = 0;
  currentImageWebcamp;
  public handleImage(webcamImage: WebcamImage): void {
    console.log('received webcam image', webcamImage);
    webcamImage["id"] = this.index;
    this.currentImageWebcamp = webcamImage;
    //this.images.push(webcamImage);
    this.webcamImage = webcamImage;
  }

  public cameraWasSwitched(deviceId: string): void {
    console.log('active device: ' + deviceId);
    this.deviceId = deviceId;
  }

  public get triggerObservable(): Observable<void> {
    if(this.isStart){
    return this.trigger.asObservable();
    }
  }

  public get nextWebcamObservable(): Observable<boolean|string> {
    return this.nextWebcam.asObservable();
  }

  public get videoOptions(): MediaTrackConstraints {
    const result: MediaTrackConstraints = {};
    if (this.facingMode && this.facingMode !== "") {
      result.facingMode = { ideal: this.facingMode };
    }
    return result;
  }

  public triggerSnapshot(): void {
    this.trigger.next();
  }

  public toggleImage(): void {
    var fileTmp = this.dataURLtoFile(this.currentImageWebcamp.imageAsDataUrl,'test.jpg');
    this.selectedFile = fileTmp;
    console.log(fileTmp);
    this.preview(fileTmp,'WEBCAM');
    this.showWebcam = !this.showWebcam;
    this.isStart = false;
  }
  

}
