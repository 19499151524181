import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { ReactiveFormsModule } from '@angular/forms';
import { TechnicianComponent } from './technician.component';
import { User } from '../model/user.model';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule
  ],
  declarations: [TechnicianComponent],
  exports: [
    TechnicianComponent
  ],
  providers: [
    User
  ]
})
export class TechnicianModule { }
