import { Component, OnInit } from '@angular/core';
import { UserService } from '../../service/user.service';
import { User } from '../../model/user';
declare var jquery: any;
declare var $: any;

@Component({
  selector: 'login-init',
  templateUrl: './login.component.html'
})
export class LoginInitComponent implements OnInit {

  submitted = false;
  errorMsg = "";
  logged = true;

  constructor(
    private userService: UserService,
    private user: User
  ) { }

  ngOnInit() {
    setTimeout(function() {
      // after 1000 ms we add the class animated to the login/register card
      $('.card').removeClass('card-hidden');
    }, 700)
  }

  loginUser(e) {
      e.preventDefault();
      var username = e.target.elements[0].value;
      var password = e.target.elements[1].value;
      this.logged = true;
      $('#loading').show();
      this.userService.getByUserNameAndPassword(username,password)
      .subscribe(data => {
        console.log(data);
        if(!data.empty){
          data.forEach(doc => {
            let newItem = doc.data();
            console.log(newItem);
            this.user.id = doc.id;
            this.user.fname = newItem.fname;
            this.user.lname = newItem.lname;
            this.user.identificationCode = newItem.identificationCode;
            this.user.email = newItem.email;
            this.user.password = newItem.password;
            this.user.role = newItem.role;
            if(newItem.branchRef){
              newItem.branchRef.get().then(res => {
                this.user.branchId = res.id;
                //this.user.branchCode = res.data().code;
                console.log(res.data());
                this.user.franchise = res.data().franchise;
                this.user.techSeq = res.data().techSeq;
                setTimeout(() => {
                  console.log(this.user);
                  localStorage.setItem('authenMenbarberToken', JSON.stringify(this.user));
                  console.log(JSON.stringify(this.user))
                  window.location.href = "/";
                }, 100);
              })
            }else{
              setTimeout(() => {
                console.log(this.user);
                localStorage.setItem('authenMenbarberToken', JSON.stringify(this.user));
                console.log(JSON.stringify(this.user))
                window.location.href = "/";
              }, 100);
            }
          });
        }else{
          $('#loading').hide();
          this.logged = false;
        }
      });

    }
}
