import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BranchEditComponent } from './branch_edit.component';
import { AgmCoreModule } from '@agm/core';
import { environment } from '../../../environments/environment';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    AgmCoreModule.forRoot({
      apiKey: environment.mapKey
    }),
  ],
  declarations: [BranchEditComponent],
  exports: [
    BranchEditComponent
  ],
  providers: [
  ]
})
export class BranchEditModule { }
