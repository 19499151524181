import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BranchAddComponent } from './branch_add.component';
import { AgmCoreModule } from '@agm/core';
import { environment } from '../../../environments/environment';

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    AgmCoreModule.forRoot({
      apiKey: environment.mapKey
    }),
  ],
  declarations: [BranchAddComponent],
  exports: [
    BranchAddComponent
  ],
  providers: [
  ]
})
export class BranchAddModule { }
