import { Component, OnInit } from '@angular/core';
import { BranchService } from "../service/branch.service";
import { TechnicianService } from "../service/technician.service";
import { HistoryService } from "../service/history.service";
import { AngularFirestore } from '@angular/fire/firestore';
declare var jquery: any;
declare var $: any;

@Component({
  selector: 'app-workschedule',
  templateUrl: './workschedule.component.html',
  styleUrls: ['./workschedule.component.css']
})
export class WorkscheduleComponent implements OnInit {
  currentBranch;
  currentBranchId;
  branchList = [];
  technicianList = [];

  constructor(
    private branchService: BranchService,
    private technicianService: TechnicianService,
    private historyService: HistoryService,
    private db: AngularFirestore
  ) { }

  ngOnInit() {
    this.loadDefaultScript();
    this.getBranch();
  } 

  getBranch(){
    this.branchList = [];
    this.branchService.get().subscribe(data => {
      data.forEach(doc => {
        let branch = doc.data();
        branch['id'] = doc.id;
        //console.log(branch);
        this.branchList.push(branch);
      })
    });
  }

  getTechnician(){

  }

  selectBranch(currentBranchId:string){
    this.currentBranchId = currentBranchId;
    console.log(this.currentBranchId);

    this.currentBranch = {};
    for(let i=0;i<this.branchList.length;i++){
      if(this.branchList[i].id == this.currentBranchId){
        this.currentBranch = this.branchList[i];
        this.generateDefaultTime(this.currentBranch.openTime,this.currentBranch.closeTime);
      }
    }

    if(this.currentBranchId != "" && this.currentBranchId != null){
      //clear and load new technician list
      this.technicianList = [];
      this.technicianService.getByBranchId(this.currentBranchId).subscribe(data => {
          data.forEach(doc => {
            let branchUser = doc.data();
              if(branchUser.usersRef){
                  branchUser.usersRef.get().then(res => {
                      let technician = res.data();
                      if(technician.role == 'tech'){
                          technician['id'] = res.id;
                          this.technicianList.push(technician);
                      }
                  })
              }
            
          })
      })
      //End clear and load new technician list


  }

  }

  technicianWorkList = [];
  brachWorkSelect = "";
  selectUpdateWorkBranch(currentBranchId:string){
    this.brachWorkSelect = currentBranchId;
    this.technicianWorkList = [];
    this.technicianService.getByBranchId(currentBranchId).subscribe(data => {
        data.forEach(doc => {
          let branchUser = doc.data();
            if(branchUser.usersRef){
                branchUser.usersRef.get().then(res => {
                  let technician = res.data();
                    if(technician.role == 'tech'){
                        technician['id'] = res.id;
                        this.technicianWorkList.push(technician);
                    }
                })
            }
        })
    })
  }

  technicianWorkSelect = "";
  selectUpdateWorkTechnician(technicianId:string){
    this.technicianWorkSelect = technicianId;
  }

  leaveType = "D";
  leaveDateRange = "";
  selectUpdateWorkType(leaveType:string){
    this.leaveType = leaveType;
    console.log(leaveType);
  }

  saveLeave(){
    this.leaveDateRange = $('#leaveDateRange').val();
    //console.log(this.leaveDateRange);
    if(this.technicianWorkSelect == "" || this.leaveType == "" || this.brachWorkSelect == ""){
        alert("กรุณากรอกข้อมูลการลาให้ครบ");
    }else{
      $('#loading').show();
      let sDate;
      let eDate;
      if(this.leaveType == 'D'){
        console.log($('#leaveDateRange').val());
        var leaveDateRange = $('#leaveDateRange').val();
        var arrLeaveDateRange = leaveDateRange.split(" - ");
        sDate = new Date(arrLeaveDateRange[0] + " 00:00:00" );
        eDate = new Date(arrLeaveDateRange[1] + " 23:59:00" );
      }else if(this.leaveType == 'T'){
        sDate = new Date($('#leaveDate').val() + " " +$('#leaveStartTime').val());
        eDate = new Date($('#leaveDate').val() + " " +$('#leaveEndTime').val());
      }

      //Create history
      let historyValue = {};
      historyValue['status'] = 'LEAVE';
      historyValue["userRef"] = this.db.doc('/users/'+this.technicianWorkSelect).ref;
      historyValue["branchRef"] = this.db.doc('/branch/'+this.brachWorkSelect).ref;
      historyValue["startDateBook"] = sDate;
      historyValue["endDateBook"] = eDate;

      this.historyService.create(historyValue).then(res =>{
                var userHistoryValue = {};
                var historyId = res.id;
                userHistoryValue["historyId"] = historyId;
                userHistoryValue["historyRef"] = this.db.doc('/history/'+historyId).ref;
                userHistoryValue["userId"] = this.technicianWorkSelect;
                userHistoryValue["userRef"] = this.db.doc('/users/'+this.technicianWorkSelect).ref;

                this.historyService.createUserHistory(userHistoryValue)
                .then(
                  res => {
                    $('#loading').hide();
                    $('#update-work-modal').modal('hide');
                    $('#remark-save-success').modal('show');
                    console.log("createUserHistory success : "+res.id);
                    //Clear value
                    //this.getBranch();
                    this.technicianWorkList = [];
                  });
      });
    }
  }

  loadSchedule() {
    for (let j = 0; j < this.technicianList.length; j++) {
      let blockTimeList = this.generateDefaultTime2(this.currentBranch.openTime,this.currentBranch.closeTime);
      console.log(this.technicianList[j]['id']);
      let date = $('#dateSchedule').val();
      let currentDate = new Date();
      date = new Date(date);
      if (Number(date.getTime()) < Number(currentDate.getTime()) && !this.isSameDay(currentDate, date)) {
        for (let i = 0; i < blockTimeList.length; i++) {
          blockTimeList[i]['status'] = "INACTIVE";
          //console.log(blockTimeList[i])
        }
        this.technicianList[j]['blockTimeList'] = blockTimeList;
      } else {
        for (let i = 0; i < blockTimeList.length; i++) {
          blockTimeList[i]['status'] = "ACTIVE";
          //console.log(blockTimeList[i])
        }
        //Check booking and leave
        let historyList = [];
        this.historyService.getUserHistoryByUserId(this.technicianList[j]['id']).subscribe(data => {
          let dataSize = data.size;

          console.log("data size : " + dataSize);
          if (dataSize == 0) {
            for (let i = 0; i < blockTimeList.length; i++) {
              let dateTimeCheck = $('#dateSchedule').val() + " " + blockTimeList[i].startTime;
              let date = new Date(dateTimeCheck);
              if (Number(date.getTime()) < Number(currentDate.getTime())) {
                blockTimeList[i]['status'] = "INACTIVE";
              }
              //console.log(blockTimeList[i])
            }
            this.technicianList[j]['blockTimeList'] = blockTimeList;
          }

          let count = 0;
          data.forEach(doc => {
            let userHistory = doc.data();
            if (userHistory.historyRef) {
              userHistory.historyRef.get().then(res => {
                count++;
                let history = res.data();
                history['id'] = res.id;
                if (history['status'] == 'LEAVE' || history['status'] == 'BOOK') {
                  //console.log(history);
                  if (history.startDateBook && history.endDateBook) {
                    historyList.push(history);
                  }
                }

                //////////////////////////////////////
                if (count == dataSize) {
                  for (let i = 0; i < blockTimeList.length; i++) {
                    let dateTimeCheck = $('#dateSchedule').val() + " " + blockTimeList[i].startTime;
                    let date = new Date(dateTimeCheck);
                    let isInactive = false;
                    //console.log(historyList.length);
                    
                    if(historyList.length > 0){
                      for (let k = 0; k < historyList.length; k++) {
                        //Check over datetime in day
                        if (Number(date.getTime()) < Number(currentDate.getTime())) {
                          isInactive = true;
                        } else {
                          if (historyList[k]['status'] == 'LEAVE' || historyList[k]['status'] == 'BOOK') {
                            let startDateCheck = new Date(historyList[k]['startDateBook'].seconds * 1000);
                            let endDateCheck = new Date(historyList[k]['endDateBook'].seconds * 1000);
                            if (Number(date.getTime()) >= Number(startDateCheck.getTime()) && Number(date.getTime()) < Number(endDateCheck.getTime())) {
                              isInactive = true;
                            }
                          }
                        }
                      }
                    }else{
                      if (Number(date.getTime()) < Number(currentDate.getTime())) {
                        isInactive = true;
                      }
                    }
                    //console.log(isInactive);
                    if (isInactive) {
                      blockTimeList[i]['status'] = "INACTIVE";
                    }
                    //console.log('y')
                    if ((i + 1) == blockTimeList.length) {
                      this.technicianList[j]['blockTimeList'] = blockTimeList;
                    }

                  }//End for block time

                }
                //////////////////////////////////////

              })
            }
          })
        });

      }
    }
  }

  checkBlockTimeStatus(blockTimeList, userId){
    console.log(userId);
    let date = $('#dateSchedule').val();
    let currentDate = new Date();
    date = new Date(date);
    if(Number(date.getTime()) < Number(currentDate.getTime()) && !this.isSameDay(currentDate,date)){
      for(let i=0;i<blockTimeList.length;i++){
        blockTimeList[i]['status']= "INACTIVE";
      }
    }else{
      for(let i=0;i<blockTimeList.length;i++){
        blockTimeList[i]['status']= "ACTIVE";
        //console.log(blockTimeList[i])
      }
      //Check booking and leave
      let historyList = [];
      this.historyService.getUserHistoryByUserId(userId).subscribe(data => {
        let dataSize = data.size;
        console.log("data size : " + dataSize)
        let count = 0;
        data.forEach(doc => {
          let userHistory = doc.data();
          if(userHistory.historyRef){
              userHistory.historyRef.get().then(res => {
                  count++;
                  let history = res.data();
                  history['id'] = res.id;
                  if(history['status'] == 'LEAVE' || history['status'] == 'BOOK'){
                      //console.log(history);
                      if(history.startDateBook && history.endDateBook){
                         historyList.push(history);
                      }
                  }

                  //////////////////////////////////////
                  if(count == dataSize){
                    for(let i=0;i<blockTimeList.length;i++){
                      let dateTimeCheck = $('#dateSchedule').val() + " " + blockTimeList[i].startTime;
                      let date = new Date(dateTimeCheck);
                      let isInactive = false;
                      //console.log(historyList.length);
                      for(let j=0;j<historyList.length;j++){
                        //Check over datetime in day
                        if(Number(date.getTime()) < Number(currentDate.getTime())){
                          isInactive = true;
                        }else{
                              if(historyList[j]['status'] == 'LEAVE' || historyList[j]['status'] == 'BOOK'){
                                let startDateCheck = new Date(historyList[j]['startDateBook'].seconds * 1000);
                                let endDateCheck = new Date(historyList[j]['endDateBook'].seconds * 1000);
                                if(Number(date.getTime()) >= Number(startDateCheck.getTime()) && Number(date.getTime()) < Number(endDateCheck.getTime())){
                                  isInactive = true;
                                }
                              }
                        }
                      }
                      //console.log(isInactive);
                      if(isInactive){
                        blockTimeList[i]['status']= "INACTIVE";
                      }
                    }//End for block time
                  }
                  //////////////////////////////////////

              })
          }
        })
        console.log('y')
         
      });

    }
    console.log('x')
    return blockTimeList;
  }


  isSameDay(d1,d2){
    if(d1.getFullYear() == d2.getFullYear() 
    && d1.getDay() == d2.getDay()
    && d1.getMonth() == d2.getMonth()){
      return true;
    }else{
      return false;
    }
  }


  defaultBlockTime = [];
  generateDefaultTime(openTime, closeTime){
    //console.log(openTime);
    //console.log(closeTime);
    this.defaultBlockTime = [];
    openTime = openTime.substring(0, 2);
    closeTime = closeTime.substring(0, 2);
    if(openTime < closeTime){
        for(let i=0;i<23;i++){
          if(i>=openTime && i<closeTime){
              let timeBlock = {"startTime":"", "endTime":"", "status":""}
              let prefixTime = "";
              if(i.toString().length == 1){
                prefixTime = prefixTime + "0";
              }
              timeBlock.startTime = prefixTime+ i + ":" + "00";
              timeBlock.endTime = prefixTime+ (i+1) + ":" + "00";
              timeBlock.status = "ACTIVE";
              this.defaultBlockTime.push(timeBlock);
          }
      }
    }else if(openTime > closeTime){
      for(let i=0;i<closeTime;i++){
            let timeBlock = {"startTime":"", "endTime":"", "status":""}
            let prefixTime = "";
            if(i.toString().length == 1){
              prefixTime = prefixTime + "0";
            }
            timeBlock.startTime = prefixTime+ i + ":" + "00";
            timeBlock.endTime = prefixTime+ (i+1) + ":" + "00";
            timeBlock.status = "ACTIVE";
            this.defaultBlockTime.push(timeBlock);
        }

      for(let i=0;i<23;i++){
          if(i>=openTime){
              let timeBlock = {"startTime":"", "endTime":"", "status":""}
              let prefixTime = "";
              if(i.toString().length == 1){
                prefixTime = prefixTime + "0";
              }
              timeBlock.startTime = prefixTime+ i + ":" + "00";
              timeBlock.endTime = prefixTime+ (i+1) + ":" + "00";
              timeBlock.status = "ACTIVE";
              this.defaultBlockTime.push(timeBlock);
          }
      }
    }
    
    //console.log(this.defaultBlockTime);
  }


  generateDefaultTime2(openTime, closeTime){
    //console.log(openTime);
    //console.log(closeTime);
    let defaultBlockTime = [];
    openTime = openTime.substring(0, 2);
    closeTime = closeTime.substring(0, 2);
    if(openTime < closeTime){
        for(let i=0;i<23;i++){
          if(i>=openTime && i<closeTime){
              let timeBlock = {"startTime":"", "endTime":"", "status":""}
              let prefixTime = "";
              if(i.toString().length == 1){
                prefixTime = prefixTime + "0";
              }
              timeBlock.startTime = prefixTime+ i + ":" + "00";
              timeBlock.endTime = prefixTime+ (i+1) + ":" + "00";
              timeBlock.status = "ACTIVE";
              defaultBlockTime.push(timeBlock);
          }
      }
    }else if(openTime > closeTime){
      for(let i=0;i<closeTime;i++){
            let timeBlock = {"startTime":"", "endTime":"", "status":""}
            let prefixTime = "";
            if(i.toString().length == 1){
              prefixTime = prefixTime + "0";
            }
            timeBlock.startTime = prefixTime+ i + ":" + "00";
            timeBlock.endTime = prefixTime+ (i+1) + ":" + "00";
            timeBlock.status = "ACTIVE";
            defaultBlockTime.push(timeBlock);
        }

      for(let i=0;i<23;i++){
          if(i>=openTime){
              let timeBlock = {"startTime":"", "endTime":"", "status":""}
              let prefixTime = "";
              if(i.toString().length == 1){
                prefixTime = prefixTime + "0";
              }
              timeBlock.startTime = prefixTime+ i + ":" + "00";
              timeBlock.endTime = prefixTime+ (i+1) + ":" + "00";
              timeBlock.status = "ACTIVE";
              defaultBlockTime.push(timeBlock);
          }
      }
    }
    
    //console.log(this.defaultBlockTime);
    return defaultBlockTime;
  }

  //load default script
  loadDefaultScript() {
    $('#dateSchedule').daterangepicker({
      singleDatePicker: true
    });
    $('#leaveDateRange').daterangepicker();

    $('#leaveDate').daterangepicker({
      singleDatePicker: true
    });

    $('#leaveStartTime').daterangepicker({
            timePicker : true,
            singleDatePicker:true,
            timePicker24Hour : true,
            timePickerIncrement : 1,
            timePickerSeconds : true,
            locale : {
                format : 'HH:mm:ss'
            }
    }).on('show.daterangepicker', function(ev, picker) {
      picker.container.find(".calendar-table").hide();
    });

    $('#leaveEndTime').daterangepicker({
          timePicker : true,
          singleDatePicker:true,
          timePicker24Hour : true,
          timePickerIncrement : 1,
          timePickerSeconds : true,
          locale : {
              format : 'HH:mm:ss'
          }
    }).on('show.daterangepicker', function(ev, picker) {
      picker.container.find(".calendar-table").hide();
    });

  }

}
