import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { ReactiveFormsModule } from '@angular/forms';
import { TechnicianEditComponent } from './technicianedit.component';
import { User } from '../model/user.model';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule
  ],
  declarations: [TechnicianEditComponent],
  exports: [
    TechnicianEditComponent
  ],
  providers: [
    User
  ]
})
export class TechnicianEditModule { }
