import { Component, OnInit } from '@angular/core';
import { HistoryService } from '../service/history.service';

declare var jquery: any;
declare var $: any;

@Component({
  selector: 'app-technicaldashboard',
  templateUrl: './technicaldashboard.component.html',
  styleUrls: ['./technicaldashboard.component.css']
})
export class TechnicalDashboardComponent implements OnInit {
  authenMenbarber;
  userWorks = [];
  userHistories = [];
  bookWorks = [];

  constructor(private historyService: HistoryService
    ) { }

  balanceTodayString = '0';
  balanceToday = 0;
  customerToday = 0;
  balanceThisMonth = 0;
  customerThisMonth = 0;

  totalCashString = '0';
  totalCash = 0;
  ngOnInit() {
    $("#tb_history").DataTable().clear().destroy();
    $('#loading').show();
    this.authenMenbarber = JSON.parse(localStorage.getItem('authenMenbarberToken'));

    //console.log(this.authenMenbarber);
    this.historyService.getUserHistoryByUserId(this.authenMenbarber.id).subscribe(data => {
      if(data.size == 0){
        $('#loading').hide();
      }
      data.forEach(doc => {
        let newItem = doc.data();
        newItem["technician"] = '';
        newItem["id"] = doc.id;
        newItem["historyId"] = newItem.historyId;
        newItem["custId"] = newItem.customerId;
        // console.log(newItem);
        if(newItem.historyRef){
          newItem.historyRef.get().then(res => {
            //  console.log(res.data());
            var hisRef = res.data();
            newItem["price"] = hisRef.price;
            newItem["servicepackage"] = "";
            newItem["bookDate"] = "";
            if(hisRef.status == "PENDING"){
              hisRef.customerRef.get().then(res => {
                var custRef = res.data();
                newItem["custCode"] = custRef.no;
                newItem["custName"] = custRef.fname+" "+custRef.lname;
                for(let k=0;k<newItem.menuList.length;k++){
                  newItem["servicepackage"] = newItem["servicepackage"] + " " + newItem.menuList[k]['name'];
                }

                if(hisRef.userRef){
                  hisRef.userRef.get().then(res=>{
                    //console.log(res.data());
                    let technician = res.data();
                    newItem["technician"] = technician['fname']+' '+technician['lname'];
                  })
                }

                let start = Number(hisRef.startDateBook.seconds);
                let end = Number(hisRef.endDateBook.seconds);
                let month = "";
                let day = "";
                let startDate = new Date(start * 1000);
                let endDate = new Date(end * 1000);
                var dd = startDate.getDate();
                var mm = startDate.getMonth() + 1; //January is 0!
                var yyyy = startDate.getFullYear();
                if (dd < 10) {
                  day = "0" + dd;
                } else {
                  day = "" + dd;
                }
                if (mm < 10) {
                  month = "0" + mm;
                } else {
                  month = "" + mm;
                }
                var today =
                  day +
                  " " +
                  this.convertMonthTH(month) +
                  " " +
                  (Number(yyyy) + 543);
                var startTime =
                  (startDate.getHours() < 10
                    ? "0" + startDate.getHours()
                    : startDate.getHours()) +
                  "." +
                  (startDate.getMinutes() < 10
                    ? "0" + startDate.getMinutes()
                    : startDate.getMinutes());
                var endTime =
                  (endDate.getHours() < 10
                    ? "0" + endDate.getHours()
                    : endDate.getHours()) +
                  "." +
                  (endDate.getMinutes() < 10
                    ? "0" + endDate.getMinutes()
                    : endDate.getMinutes());
                    newItem["bookDate"] =
                  today + "  " + startTime + " - " + endTime + " น.";

                  console.log(newItem);
                this.userWorks.push(newItem);
                setTimeout(() => {
                  $('#tb_peding').DataTable();
                  $('#loading').hide();
                },2000);
              })
            }else if(hisRef.status == "BOOK"){
                hisRef.customerRef.get().then(res => {
                  var custRef = res.data();
                  newItem["custCode"] = custRef.no;
                  newItem["custName"] = custRef.fname+" "+custRef.lname;
                  for(let k=0;k<newItem.menuList.length;k++){
                    newItem["servicepackage"] = newItem["servicepackage"] + " " + newItem.menuList[k]['name'];
                  }
                
                if(hisRef.userRef){
                  hisRef.userRef.get().then(res=>{
                    //console.log(res.data());
                    let technician = res.data();
                    newItem["technician"] = technician['fname']+' '+technician['lname'];
                  })
                }

                let start = Number(hisRef.startDateBook.seconds);
                let end = Number(hisRef.endDateBook.seconds);
                let month = "";
                let day = "";
                let startDate = new Date(start * 1000);
                let endDate = new Date(end * 1000);
                var dd = startDate.getDate();
                var mm = startDate.getMonth() + 1; //January is 0!
                var yyyy = startDate.getFullYear();
                if (dd < 10) {
                  day = "0" + dd;
                } else {
                  day = "" + dd;
                }
                if (mm < 10) {
                  month = "0" + mm;
                } else {
                  month = "" + mm;
                }
                var today =
                  day +
                  " " +
                  this.convertMonthTH(month) +
                  " " +
                  (Number(yyyy) + 543);
                var startTime =
                  (startDate.getHours() < 10
                    ? "0" + startDate.getHours()
                    : startDate.getHours()) +
                  "." +
                  (startDate.getMinutes() < 10
                    ? "0" + startDate.getMinutes()
                    : startDate.getMinutes());
                var endTime =
                  (endDate.getHours() < 10
                    ? "0" + endDate.getHours()
                    : endDate.getHours()) +
                  "." +
                  (endDate.getMinutes() < 10
                    ? "0" + endDate.getMinutes()
                    : endDate.getMinutes());
                    newItem["bookDate"] =
                  today + "  " + startTime + " - " + endTime + " น.";

                  //  console.log(newItem);
                  let diff = this.diftDateBook2(hisRef.startDateBook);
                  if(diff[1] != '0 นาที' ){
                    this.bookWorks.push(newItem);
                  }
                  setTimeout(() => {
                    $('#tb_booking').DataTable();
                    $('#loading').hide();
                  },2000);
                })
            }else if(hisRef.status == "HIS"){

                //
                var endDate = hisRef.endDate;
                //console.log(endDate);
                if(endDate){
                  let endDateTodate = endDate.toDate();
                  // console.log(endDateTodate);
                  // today
                  if(this.compareWithoutTime(endDateTodate,new Date())){
                    //console.log(newItem);
                    this.balanceToday += newItem["price"];
                    this.balanceTodayString = this.balanceToday.toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")
                    this.customerToday++;
                  }
                  // ThisMonth
                  
                  if(this.compareMonth(endDateTodate,new Date())){
                    this.balanceThisMonth += newItem["price"];
                    this.customerThisMonth++;
                  }
                }
                //

              
              hisRef.customerRef.get().then(res => {
                var custRef = res.data();
                newItem["custCode"] = custRef.no;
                newItem["custName"] = custRef.fname+" "+custRef.lname;
                newItem["time"] = this.convertDateToString(hisRef.startDate.toDate())+" - "+
                                  this.convertDateToString(hisRef.endDate.toDate());
                newItem["startDate"] = hisRef.startDate.toDate();
                this.totalCash += Number(hisRef.price);
                this.totalCashString = this.totalCash.toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")
                this.userHistories.push(newItem);

                setTimeout(() => {
                  $('#tb_history').DataTable();
                  this.userHistories.sort(function(a, b){
                    return b.startDate-a.startDate
                  });
                  $('#loading').hide();
                },2000);
              })
            } 
          })
        }
      });
      
    },
    err => {
      console.log(err);
      $('#loading').hide();
    }); 
    //this.loadDefaultScript();
  }

  diftDateBook2(time){
   let remainTime = {'1': null,'2': null};
  
   let dateBook = new Date(time.seconds * 1000);
   var diffMS = Math.floor(Math.floor(<any>dateBook - <any>new Date()));

   var diffDays = Math.floor(diffMS / 86400000); // days
   var diffHrs = Math.floor((diffMS % 86400000) / 3600000); // hours
   var diffMins = Math.round(((diffMS % 86400000) % 3600000) / 60000); // minutes
   //console.log(diffDays + " days, " + diffHrs + " hours, " + diffMins + " minutes " )
  if(diffDays > 0){  
    remainTime['1'] = diffDays + ' วัน';
    remainTime['2'] = diffHrs + ' ชั่วโมง';
  }else if(diffDays <= 0 && diffHrs > 0){ 
    remainTime['1'] = diffHrs + ' ชั่วโมง';
    remainTime['2'] = diffMins + ' นาที';
  }else if(diffDays <= 0 && diffHrs <= 0 && diffMins > 0){
    remainTime['1'] = diffMins + ' นาที';
  }else{
    remainTime['1'] = '0 นาที';
  }    
 
    return remainTime;
  }

  compareMonth(now,date){
    var nowStr = now.getYear()+now.getMonth();
    var dateStr = date.getYear()+date.getMonth();
    return nowStr == dateStr;
  }

  compareWithoutTime(now,date){
    now.setHours(0);
    now.setMinutes(0);
    now.setSeconds(0);
    now.setMilliseconds(0);

    date.setHours(0);
    date.setMinutes(0);
    date.setSeconds(0);
    date.setMilliseconds(0);
    // console.log(now);
    // console.log(date);
    return now.getTime() === date.getTime();
  }

  convertDateToString(d){
    return [d.getDate(),
               d.getMonth()+1,
               d.getFullYear()].join('/')+' '+
              [d.getHours(),
               d.getMinutes(),
               d.getSeconds()].join(':');
  }

  startProcess(e){
    var startMenprocess = {};
    startMenprocess["historyId"] = e.historyId;
    startMenprocess["customerId"] = e.custId;
    //console.log(startMenprocess);
    localStorage.setItem('startMenprocess', JSON.stringify(startMenprocess));
    window.location.href = "/";
  }


  historyCanel;
  confirmCancel(e){
    console.log(e);
    this.historyCanel = e;
    $('#cancel-work-modal').modal('show');
  }

  cancelProcess(){
    console.log(this.historyCanel);
    this.historyService.delete(this.historyCanel['historyId']).then(res=>{
       this.historyService.deleteUserHistory(this.historyCanel['id']).then(res=>{
        window.location.href = "/";
       });
    });
  }

  convertMonthTH(m) {
    var month = "";
    if ("01" == m) {
      month = "มกราคม";
    } else if ("02" == m) {
      month = "กุมภาพันธ์";
    } else if ("03" == m) {
      month = "มีนาคม";
    } else if ("04" == m) {
      month = "เมษายน";
    } else if ("05" == m) {
      month = "พฤษภาคม";
    } else if ("06" == m) {
      month = "มิถุนายน";
    } else if ("07" == m) {
      month = "กรกฎาคม";
    } else if ("08" == m) {
      month = "สิงหาคม";
    } else if ("09" == m) {
      month = "กันยายน";
    } else if ("10" == m) {
      month = "ตุลาคม";
    } else if ("11" == m) {
      month = "พฤศจิกายน";
    } else if ("12" == m) {
      month = "ธันวาคม";
    }
    return month;
  }

  //load default script
  loadDefaultScript(){
    setTimeout(() => {
      $('#customer-detail-datatables').DataTable({
        "pagingType": "full_numbers",
        "lengthMenu": [
          [10, 25, 50, -1],
          [10, 25, 50, "All"]
        ],
        responsive: true,
        language: {
          search: "_INPUT_",
          searchPlaceholder: "Search records",
        }
      });
      
      $(".quick-btn").click(function(){
        $(".quick-btn").removeClass("active");
        $(this).addClass("active");
    });
  });
}
//end load default script
}
