import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';

@Injectable({
    providedIn: 'root'
})
export class CustomerService {

    constructor(public db: AngularFirestore) {}

    getCustomers(){
      return this.db.collection('customer').valueChanges();
    }

    create(value){
      return this.db.collection('customer').add(value);
    }

    getByKey(key){
      return this.db.collection('customer').doc(key).snapshotChanges();
    }

    set(key, value){
      return this.db.collection('customer').doc(key).set(value);
    }

    update(key, value){
      return this.db.collection('customer').doc(key).update(value);
    }

    delete(key){
      return this.db.collection('customer').doc(key).delete();
    }

    get(){
      return this.db.collection('customer').snapshotChanges();
    }

    searchByRole(value){
      return this.db.collection('customer',ref => ref.orderBy('role').startAt(value)).get();
    }

    updateUser(key, value){
      return this.db.collection('customer').doc(key).update(value);
    }

    getSeqMax(){
      return this.db.collection('customer',ref => ref.orderBy("seq", "desc").limit(1)).get();
    }

    // search(searchValue){
    //   return this.db.collection('customer',ref => ref.where('nameToSearch', '>=', searchValue)
    //     .where('nameToSearch', '<=', searchValue + '\uf8ff'))
    //     .snapshotChanges()
    // }

    search(key,value){
      return this.db.collection(
        'customer',ref => ref.where(key, '==', value)
        )
        .get()
    }
}
