import { Component, OnInit } from '@angular/core';
declare var jquery: any;
declare var $: any;

@Component({
  selector: 'app-cushistory',
  templateUrl: './cushistory.component.html',
  styleUrls: ['./cushistory.component.css']
})
export class CusHistoryComponent implements OnInit {

  constructor() { }

  ngOnInit() {
    this.loadDefaultScript();
  } 

  //load default script
  loadDefaultScript(){
    $('#datatables').DataTable({
      "pagingType": "full_numbers",
      "lengthMenu": [
        [10, 25, 50, -1],
        [10, 25, 50, "All"]
      ],
      responsive: true,
      language: {
        search: "_INPUT_",
        searchPlaceholder: "Search records",
      }
    });
    
    $('#modal-cus-history').modal("show");
}
//end load default script
}
