import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';

@Injectable({
    providedIn: 'root'
})
export class TechnicianService {

    constructor(public db: AngularFirestore) {}

    create(value){
      return this.db.collection('branchUser').add(value);
    }

    getByKey(key){
      return this.db.collection('branchUser').doc(key).snapshotChanges();
    }

    update(key, value){
      return this.db.collection('branchUser').doc(key).update(value);
    }

    getByUserId(userId){
      return this.db.collection('branchUser',ref => ref.where('userId', '==', userId)).get();
    }

    getByBranchId(userId){
      return this.db.collection('branchUser',ref => ref.where('branchId', '==', userId)).get();
    }

    delete(key){
      return this.db.collection('branchUser').doc(key).delete();
    }

    get(){
      return this.db.collection('branchUser').get();
    }

    get1(){
      return this.db.collection('branchUser').snapshotChanges();
    }

    search(searchValue){
      return this.db.collection('branchUser',ref => ref.where('nameToSearch', '>=', searchValue)
        .where('nameToSearch', '<=', searchValue + '\uf8ff'))
        .snapshotChanges()
    }

    searchByTitle(value){
      return this.db.collection('branchUser',ref => ref.orderBy('title').startAt(value)).snapshotChanges();
    }
}
