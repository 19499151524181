import { Component, OnInit } from "@angular/core";
declare var jquery: any;
declare var $: any;
import { HistoryService } from "../service/history.service";
import { BranchService } from "../service/branch.service";
import { UserService } from "../service/user.service";
import {
  FormBuilder,
  FormGroup,
  Validators,
  FormControl
} from "@angular/forms";

@Component({
  selector: "app-history",
  templateUrl: "./history.component.html",
  styleUrls: ["./history.component.css"]
})
export class HistoryComponent implements OnInit {
  historyList = new Array();
  branchList = new Array();
  technicianList = new Array();
  searchForm: FormGroup;
  authen;
  initBranch = "";
  initTech = "";
  checkHistory = false;
  totalCustService = 0;
  totalAmount = 0.00;

  constructor(
    private fb: FormBuilder,
    private historyService: HistoryService,
    private branchService: BranchService,
    private userService: UserService
  ) {
    this.searchForm = fb.group({
      branch: [""],
      technician: [""]
    });
  }

  ngOnInit() {
    this.checkHistory = false;
    $("#datefrom").daterangepicker();
    this.authen = JSON.parse(localStorage.getItem("authenMenbarberToken"));
    var role = this.authen.role;
    if ("admin" == role) {
      this.initBranch = this.authen.branchId;
      console.log(this.authen.branchId);
      $("#div-search").remove();
    } else {
      this.initBranch = "";
    }
    this.initTech = "";

    let criteria = {
      branch: this.initBranch,
      technician: this.initTech,
      startDate: "",
      endDate: ""
    };
    this.getHistoryService(criteria);
    this.getBranch();
    this.getTechnician(this.initBranch);
  }

  getTechnician(initBranch) {
    this.technicianList = [];
    this.userService.searchByRole("tech").subscribe(data => {
      data.forEach(doc => {
        let newItem = doc.data();
        let technician = {};
        if (newItem.branchId == initBranch || initBranch == "") {
          technician["id"] = doc.id;
          technician["fname"] = newItem.fname;
          technician["lname"] = newItem.lname;
          this.technicianList.push(technician);
        }
      });
    });
  }

  branchSelected(e) {
    let initBranch = e.target.value;
    this.getTechnician(initBranch);
  }

  getBranch() {
    this.branchService.get().subscribe(data => {
      data.forEach(doc => {
        let newItem = doc.data();
        let branch = {};
        branch["id"] = doc.id;
        branch["name"] = newItem.name;
        this.branchList.push(branch);
      });
    });
  }

  getHistoryServiceByCriteria(criteria) {
    let datefrom = $("#datefrom").val();
    let date = datefrom.split("-");
    let startDate = Date.parse(date[0].trim()) / 1000;
    let endDate = Date.parse(date[1].trim()) / 1000;
    criteria["startDate"] = startDate;
    criteria["endDate"] = endDate;
    this.checkHistory = false;
    this.getHistoryService(criteria);
  }

  getHistoryService(criteria) {
    $("#loading").show();
    this.historyList = [];
    $(".datatables")
      .DataTable()
      .clear()
      .destroy();
    let i = 0;
    let count = 0;
    this.totalAmount = 0.00;
    this.totalCustService = 0;
    this.historyService.getUserHistoryBycriteria(criteria).subscribe(data => {
      count = data.size;
      data.forEach(doc => {
        let userHistory = doc.data();
        let historys = {};
        historys["historyId"] = userHistory.historyId;
        // history
        if (userHistory.historyRef) {
          userHistory.historyRef.get().then(res => {
            i++;
            let history = res.data();
            //console.log(history);

            if(typeof(history) != "undefined" && typeof(history.startDate) != "undefined" && typeof(history.endDate) != "undefined"){
                let start = Number(history.startDate.seconds);
                let end = Number(history.endDate.seconds);
                let month = "";
                let day = ""; 

                //let startDateCheck = new Date(startCheck.toLocaleDateString("en-US"));
                //console.log(startDateCheck);
                //console.log(Number(criteria.startDate));
                //console.log(Number(criteria.endDate));

                let startCheck = new Date(start * 1000);
                startCheck = new Date((startCheck.getMonth()+1) + "/" + startCheck.getDate() + "/" + startCheck.getFullYear()+" "+"00:00")
                let cStartCheck = new Date(Number(criteria.startDate)*1000);
                let cEndCheck = new Date(Number(criteria.endDate)*1000); 

                if(history.status == "HIS"){ 
                  if (
                    (cStartCheck.getTime() <= startCheck.getTime() && cEndCheck.getTime() >= startCheck.getTime()) 
                    || (criteria.startDate == "" && criteria.endDate == "")
                  ) { 

                    this.checkHistory = true;
                    let startDate = new Date(start * 1000);
                    let endDate = new Date(end * 1000);
                    var dd = startDate.getDate();
                    var mm = startDate.getMonth() + 1; //January is 0!
                    var yyyy = startDate.getFullYear();
                    if (dd < 10) {
                      day = "0" + dd;
                    } else {
                      day = "" + dd;
                    }
                    if (mm < 10) {
                      month = "0" + mm;
                    } else {
                      month = "" + mm;
                    }
                    var today =
                      day +
                      " " +
                      this.convertMonthTH(month) +
                      " " +
                      (Number(yyyy) + 543);
                    var startTime =
                      (startDate.getHours() < 10
                        ? "0" + startDate.getHours()
                        : startDate.getHours()) +
                      "." +
                      (startDate.getMinutes() < 10
                        ? "0" + startDate.getMinutes()
                        : startDate.getMinutes());
                    var endTime =
                      (endDate.getHours() < 10
                        ? "0" + endDate.getHours()
                        : endDate.getHours()) +
                      "." +
                      (endDate.getMinutes() < 10
                        ? "0" + endDate.getMinutes()
                        : endDate.getMinutes());
                    historys["createDate"] =
                      today + "  " + startTime + " - " + endTime + " น.";

                    historys["price"] = history.price + " บาท";
                    historys["servicepackage"] = history.servicepackage;
                    historys["userhistoryid"] = res.id;

                    // technician
                    if (userHistory.userRef) {
                      userHistory.userRef.get().then(res => {
                        let technician = res.data();
                        historys["technicianname"] =
                          technician.fname + " " + technician.lname;
                      });
                    }

                    // customer
                    if (history.customerRef) {
                      history.customerRef.get().then(res => {
                        let customer = res.data();

                        historys["customername"] = customer.name;
                        historys["customerid"] = res.id;
                      });

                      // branch
                      if (history.branchRef && history.status == "HIS") {
                        history.branchRef.get().then(res => {
                          let branch = res.data();
                          historys["branchname"] = branch.name;
                          historys["branchid"] = res.id;
                          if (criteria.branch === res.id || criteria.branch === "") {
                            this.totalCustService++;
                            this.totalAmount = this.totalAmount + history.price;
                            this.historyList.push(historys);
                            if(count == i){
                                $("#loading").hide();
                                setTimeout(() => {
                                  $(".datatables").DataTable({
                                    retrieve: true,
                                    paging: true,
                                    responsive: true
                                  });
                                }, 0);
                            } 
                          } else {
                            if(count == i){
                              $("#loading").hide();
                              setTimeout(() => {
                                $(".datatables").DataTable({
                                  retrieve: true,
                                  paging: true,
                                  responsive: true
                                });
                              }, 0);
                              }
                          }
                        });
                      } else {
                        if(count == i){
                          $("#loading").hide();
                          setTimeout(() => {
                            $(".datatables").DataTable({
                              retrieve: true,
                              paging: true,
                              responsive: true
                            });
                          }, 0);
                          }
                      }
                    } else {
                      if(count == i){
                        $("#loading").hide();
                        setTimeout(() => {
                          $(".datatables").DataTable({
                            retrieve: true,
                            paging: true,
                            responsive: true
                          });
                        }, 0);
                        }
                    }
                  } 
              }
        }

          });
        }
      }, 0);
      setTimeout(() => {
        console.log(this.historyList.length);
        console.log(count);
        console.log(i);
        if (this.historyList.length == 0 && (count == i)) {
          $("#loading").hide();
          setTimeout(() => {
            $(".datatables").DataTable({
              retrieve: true,
              paging: true,
              responsive: true
            });
          }, 0);
        }
      }, 5000);
      if (count == 0) {
        $("#loading").hide();
        setTimeout(() => {
          $(".datatables")
            .DataTable({
              retrieve: true,
              paging: false,
              responsive: true
            })
            .clear()
            .draw();
        }, 0);
      }
    });
  }

  convertMonthTH(m) {
    var month = "";
    if ("01" == m) {
      month = "มกราคม";
    } else if ("02" == m) {
      month = "กุมภาพันธ์";
    } else if ("03" == m) {
      month = "มีนาคม";
    } else if ("04" == m) {
      month = "เมษายน";
    } else if ("05" == m) {
      month = "พฤษภาคม";
    } else if ("06" == m) {
      month = "มิถุนายน";
    } else if ("07" == m) {
      month = "กรกฎาคม";
    } else if ("08" == m) {
      month = "สิงหาคม";
    } else if ("09" == m) {
      month = "กันยายน";
    } else if ("10" == m) {
      month = "ตุลาคม";
    } else if ("11" == m) {
      month = "พฤศจิกายน";
    } else if ("12" == m) {
      month = "ธันวาคม";
    }
    return month;
  }

  //load default script
  loadDefaultScript() {
    $("#datatables").DataTable({
      pagingType: "full_numbers",
      lengthMenu: [
        [10, 25, 50, -1],
        [10, 25, 50, "All"]
      ],
      responsive: true,
      language: {
        search: "_INPUT_",
        searchPlaceholder: "Search records"
      }
    });
  }
  //end load default script
}
