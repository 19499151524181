import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';

@Injectable({
    providedIn: 'root'
})
export class BranchPhotoService {

    constructor(public db: AngularFirestore) {}

    create(value){
      return this.db.collection('branchPhoto').add(value);
    }

    getByKey(key){
      return this.db.collection('branchPhoto').doc(key).get();
    }

    set(key, value){
      return this.db.collection('branchPhoto').doc(key).set(value);
    }

    update(key, value){
      return this.db.collection('branchPhoto').doc(key).update(value);
    }

    delete(key){
      return this.db.collection('branchPhoto').doc(key).delete();
    }

    get(){
      return this.db.collection('branchPhoto').get();
    }

    getPhotoByBranchId(branchId){
      return this.db.collection('branchPhoto',ref => ref.where('branchId', '==', branchId))
        .get()
    }
}
