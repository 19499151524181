import { Component, OnInit } from "@angular/core";
declare var jquery: any;
declare var $: any;
import { HttpClient } from "@angular/common/http";
import { HttpErrorResponse } from "@angular/common/http";

@Component({
  selector: "app-freelancelist",
  templateUrl: "./freelancelist.component.html",
  styleUrls: ["./freelancelist.component.css"]
})
export class FreelanceListComponent implements OnInit {

  constructor(
    private httpService: HttpClient
  ) {}

  ngOnInit() {
    this.loadDefaultScript(); 
    this.getFreelanceList();
  } 

  getFreelanceList(){
    setTimeout(() => {
      $("#tb_freelance_list").DataTable({
        retrieve: true,
        paging: true,
        responsive: true
      });
    }, 0);
  }

  //load default script
  loadDefaultScript() {
     
  }
  //end load default script
}
