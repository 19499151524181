import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule} from '@angular/forms';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { DashboardModule } from './dashboard/dashboard.module';
import { BranchModule } from './branch/branch.module';
import { BranchAddModule } from './branch/add/branch_add.module';
import { BranchEditModule } from './branch/edit/branch_edit.module';
import { BranchViewModule } from './branch/view/branch_view.module';
import { MenProcessModule } from './menprocess/menprocess.module';
import { TechnicianModule } from './technician/technician.module';
import { ServicePackageModule } from './servicepackage/servicepackage.module';

import {
  HeaderComponent,
  MenuLeftComponent,
  LoginInitComponent,
  FooterComponent,
  StartMenprocessComponent
} from './shared';
import { HistoryModule } from './history/history.module';
import { HistoryViewModule } from './history/view/history_view.module';
import { WorkscheduleModule } from './workschedule/workschedule.module';
import { CustomerModule } from './customer/customer.module';
import { HairmodelModule } from './hairmodel/hairmodel.module';
import { AgmCoreModule } from '@agm/core';

import { AngularFirestore } from '@angular/fire/firestore';
import { AngularFireModule } from '@angular/fire';
import { AngularFireDatabaseModule } from '@angular/fire/database';
import { AngularFireStorage } from '@angular/fire/storage';
import { environment } from '../environments/environment';

import { NewspromotionService } from './service/newspromotion.service';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import { AnalyticsModule } from './customer/analytics/analytics.module';
import { CussettingsModule } from './customer/cussettings/cussettings.module';
import { CustomerDetailModule } from './customer/customerdetail/customerdetail.module';
import { PointRewardModule } from './customer/pointreward/pointreward.module';
import { ReportModule } from './customer/report/report.module';
import { NewsPromotionModule } from './customer/newspromotion/newspromotion.module';
import { NewCustomerModule } from './customer/newcustomer/newcustomer.module';
import { CustomerViewComponentModule } from './customer/customerview/customerview.module';
import { TechnicalDashboardModule } from './technicaldashboard/technicaldashboard.module';
import { CusHistoryModule } from './customer/cushistory/cushistory.module';
import { WebcamModule } from './webcam/webcam.module';
import { HttpClientModule } from '@angular/common/http';
import { CloudinaryModule, CloudinaryConfiguration } from '@cloudinary/angular-5.x';
import { Cloudinary } from '@cloudinary/angular-5.x/src/cloudinary.service';
// import { LineChartComponent } from './line-chart/line-chart.component';
import { ChartsModule } from 'ng2-charts';
import { bookingListComponentModule } from './bookingList/bookingList.module';
import { ApproveListModule } from './freelance/approvelist/approvelist.module';
import { FreelanceListComponent } from './freelance/freelancelist/freelancelist.component';
import { FreelanceListModule } from './freelance/freelancelist/freelancelist.module';
import { FreelanceProfileModule } from './freelance/freelanceProfile/freelanceProfile.module';
import { FreelanceHistoryModule } from './freelance/freelanceHistory/freelanceHistory.module';
import { FreelanceHisDetailModule } from './freelance/freelanceHisDetail/freelanceHisDetail.module';
import { Workschedule2Module } from './workschedule2/workschedule2.module';
import { TechnicianEditModule } from './technicianedit/technicianedit.module';
import { Workschedule3Module } from './workschedule3/workschedule3.module';

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    MenuLeftComponent,
    LoginInitComponent,
    FooterComponent,
    StartMenprocessComponent,
    // LineChartComponent
  ],
  imports: [
    AngularFireModule.initializeApp(environment.firebaseConfig),
    AngularFireDatabaseModule,
    CloudinaryModule.forRoot({Cloudinary}, { cloud_name: 'dffs5iirz' } as CloudinaryConfiguration),
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    ChartsModule,
    DashboardModule,
    BranchModule,
    BranchAddModule,
    BranchEditModule,
    BranchViewModule,
    MenProcessModule,
    TechnicianModule,
    ServicePackageModule,
    HistoryModule,
    HistoryViewModule,
    NewsPromotionModule,
    WorkscheduleModule,
    CustomerModule,
    HairmodelModule,
    AnalyticsModule,
    CussettingsModule,
    CustomerDetailModule,
    PointRewardModule,
    ReportModule,
    NewCustomerModule,
    CustomerViewComponentModule,
    TechnicalDashboardModule,
    CusHistoryModule,
    NgbModule,
    WebcamModule,
    HttpClientModule,
    bookingListComponentModule,
    ApproveListModule,
    FreelanceListModule,
    FreelanceProfileModule,
    FreelanceHistoryModule,
    FreelanceHisDetailModule,
    Workschedule2Module,
    TechnicianEditModule,
    Workschedule3Module,
    AgmCoreModule.forRoot({
      apiKey: environment.mapKey // apiKey is required 
    })
  ],
  providers: [AngularFirestore,AngularFireStorage],
  bootstrap: [AppComponent]
})
export class AppModule { }
