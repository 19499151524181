import { Injectable } from "@angular/core";
import { AngularFirestore } from "@angular/fire/firestore";

@Injectable({
  providedIn: "root"
})
export class BranchServicepackageService {
  constructor(public db: AngularFirestore) {}

  create(value) {
    return this.db.collection("branchServicepackage").add(value);
  }

  getByKey(key) {
    return this.db
      .collection("branchServicepackage")
      .doc(key)
      .get();
  }

  set(key, value) {
    return this.db
      .collection("branchServicepackage")
      .doc(key)
      .set(value);
  }

  update(key, value) {
    return this.db
      .collection("branchServicepackage")
      .doc(key)
      .update(value);
  }

  delete(key) {
    return this.db
      .collection("branchServicepackage")
      .doc(key)
      .delete();
  }

  get() {
    return this.db.collection("branchServicepackage").get();
  }

  getByBranch(value) {
    return this.db
      .collection("branchServicepackage", ref =>
        ref.where("branchId", "==", value)
      )
      .get();
  }

  getByServicePackage(value) {
    return this.db
      .collection("branchServicepackage", ref =>
        ref.where("servicePackageId", "==", value)
      )
      .get();
  }
}
