import { Component, OnInit } from '@angular/core';
declare var jquery: any;
declare var $: any;
import { CustomerService } from '../service/customer.service';
import { UserService } from '../service/user.service';
import { HistoryService } from '../service/history.service';
import { BranchServicepackageService } from '../service/branchservicepackage.service';
import { BranchMenuService } from '../service/branchmenu.service';
import { BranchService } from '../service/branch.service';
import { FileManagerService } from '../service/fileManager.service';
import { BanchServicepackageBanchMenuService } from '../service/branchservicepackagebranchmenu.service';

import { AngularFirestore } from '@angular/fire/firestore';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

@Component({
  selector: 'app-menprocess',
  templateUrl: './menprocess.component.html',
  styleUrls: ['./menprocess.component.css']
})
export class MenProcessComponent implements OnInit {
  user = null;
  customerType = 1;
  step = 1;
  authenMenbarber;
  customers = [];
  users = [];
  servicepackages = [];
  menus = [];
  servicepackageMap = new Map();
  menuMap = new Map();
  customerData;
  value;
  key = 'no';
  servicepackage = 'สแตนดาร์ดตัดโกนเชท';
  desc = '';
  pay_type = 'เงินสด';
  price = 0;
  isDisabled = true;
  authenMenbarberToken;
  branchId;
  totalPrice = 0;
  cash = 0;
  point = 0;
  sum_point = 0;
  pointPrice = 0;
  urlSafe: SafeResourceUrl;
  isPaymentBtn = true;
  invoiceNoRunning = 1;
  branch;
  tableNo = 0;
  processType = "";

  constructor(private customerService: CustomerService,
    private userService: UserService,
    private historyService: HistoryService,
    private branchServicepackageService: BranchServicepackageService,
    private branchMenuService: BranchMenuService,
    private banchServicepackageBanchMenuService: BanchServicepackageBanchMenuService,
    private fileManagerService: FileManagerService,
    private branchService: BranchService,
    public sanitizer: DomSanitizer,
    private db: AngularFirestore) { }

  ngOnInit() {
    this.authenMenbarberToken = JSON.parse(localStorage.getItem('authenMenbarberToken'));
    if(this.authenMenbarberToken){
      this.branchId = this.authenMenbarberToken.branchId;
      console.log(this.branchId);
      this.branchService.getByKey(this.branchId).subscribe(data => {
        this.branch = data.data();
        console.log(this.branch);
        this.invoiceNoRunning = data.data().invoiceNoRunning;
        if(Number.isNaN(this.invoiceNoRunning)){
          this.invoiceNoRunning = 1;
        }
      });
      
      
    }
    this.getUsers();
    this.getbanchServicepackage();
    this.getbanchMenu();
    this.totalPrice = 0;
    this.search();
  }
  
  marked = false;
  selectPackage(item,e){
    console.log(item);
    this.marked = e.target.checked;
    if(this.marked){
      this.servicepackageMap.set(item.id,item);
      this.price += Number(item.price);
    }else{
      this.servicepackageMap.delete(item.id);
      this.price -= Number(item.price);
    }
  }
  selectMenu(item,e){
    console.log(item);
    this.marked = e.target.checked;
    if(this.marked){
      this.menuMap.set(item.id,item);
      this.price += Number(item.price);
    }else{
      this.menuMap.delete(item.id);
      this.price -= Number(item.price);
    }
  }

  getUsers(){
    this.userService.getTech()
    .subscribe(data => {
      data.forEach(doc => {
        let newItem = doc.data();
        newItem["id"] = doc.id;
        this.users.push(newItem);
      });

      setTimeout(() => {
        $('#tb-tech-menprocess').DataTable({
          retrieve: true,
          paging: true,
          responsive: true
        });
      }, 0);

    },
    err => {
      console.log(err);
    });
  }

  getbanchMenu(){
    this.branchMenuService.getByBranch(this.branchId)
    .subscribe(data => {
      data.forEach(doc => {
        let newItem = doc.data();
        if(newItem.menuRef){
          newItem.menuRef.get().then(res => {
            var dataRef = res.data();
            dataRef["price"] = newItem.price;
            dataRef["checked"]=false;
            this.menus.push(dataRef);
            //sort
            setTimeout(() => {
              this.menus.sort((a, b) => Number(a.price) - Number(b.price));
            }, 10);

            //console.log(dataRef);
          });
        }
        //console.log(newItem);
      });
    },
    err => {
      console.log(err);
    });
  }

  getbanchServicepackage(){
    this.branchServicepackageService.getByBranch(this.branchId)
    .subscribe(data => {
      data.forEach(doc => {
        let menuInServicepackage = "";
        let banchServicepackageId = doc.id;
        let dataReult = {};
        let item = doc.data();
        dataReult["id"] = banchServicepackageId;
        dataReult["price"] = item.price;
        dataReult["checked"]=false;
        if(item.servicepackageRef){
          item.servicepackageRef.get().then(res => {
            var dataRef = res.data();
            dataReult["name"] = dataRef.packageName;
          });
        }

        console.log(banchServicepackageId);
        this.banchServicepackageBanchMenuService.getByBranchServicepackage(banchServicepackageId)
        .subscribe(data => {
          let index = 0;
          data.forEach(doc => {
            let newItem = doc.data();
            if(newItem.branchMenuRef){
              console.log(">>>>>>>>>>>>>>>>>>>>>");
              newItem.branchMenuRef.get().then(res => {
                var dataMenuRef = res.data();
                if(dataMenuRef.menuRef){
                  dataMenuRef.menuRef.get().then(res => {
                    var dataRef = res.data();
                    menuInServicepackage = menuInServicepackage + dataRef.name +",";
                    console.log(menuInServicepackage);
                    index++;
                    if(index == data.size){
                      dataReult["menuInServicepackage"] = menuInServicepackage;
                      this.servicepackages.push(dataReult);
                    }
                  });
                }
              });
            }
          });
        },
        err => {
          console.log(err);
        });
      });
    },
    err => {
      console.log(err);
    });
  }

  triggerInputPrice(){
    this.isDisabled = !this.isDisabled;
    console.log(this.isDisabled);
  }

  search(){
    this.customers = [];
    this.customerService.get()
      .subscribe(data => {
        data.forEach(doc => {
          let newItem = doc.payload.doc.data();
          newItem["id"] = doc.payload.doc.id;
          this.customers.push(newItem);
        });

        setTimeout(() => {
          $('#table-search-cus-pending').DataTable({
            retrieve: true,
            paging: true,
            responsive: true
          });
        }, 0);

      },
      err => {
        console.log(err);
      });
  }

  selectTechnic(user){
    this.user = user;
    console.log(this.user);
  }
  
  historyValue = {};
  cashException = false;
  tableException = false;
  change = 0.00;

  calculateChange(){
    console.log(this.cash);
    console.log(this.price);
     
    this.change = this.cash-this.price
    if(this.change < 0){
      this.change = 0;
    }
    console.log(this.change);
  }

  selectTableNo(value){
    this.tableNo = value;
  }

  payment(){
    $('#loading').show();
    console.log(this.change);
    if(this.cash - this.price < 0){
      this.cashException = true;
      $('#loading').hide();
      return
    }else{
      this.cashException = false;
    }

    if(this.tableNo < 1){
      this.tableException = true;
      $('#loading').hide();
      return;
    }else{
      this.tableException = false;
    }

    if(!this.user){
      $('#loading').hide();
      alert("ยังไม่มอบหมายงานช่าง");
      return;
    }
 
    var menuList = [];
    this.menuMap.forEach((val, key) => {
      var data = {};
      data["id"] = key;
      data["name"] = val.name;
      data["price"] = val.price;
      menuList.push(data);
    });
    var servicepackageList = [];
    this.servicepackageMap.forEach((val, key) => {
      var data = {};
      data["id"] = key;
      data["name"] = val.name;
      data["price"] = val.price;
      servicepackageList.push(data);
    });

    if(menuList.length == 0 && servicepackageList.length ==0){
      $('#loading').hide();
      alert("กรุณาเลือกแพ็กเกจบริการ");
      return;
    }

    this.point = Math.trunc(this.price / 100);
    this.authenMenbarber = JSON.parse(localStorage.getItem('authenMenbarberToken'));
    this.historyValue['servicepackage'] = this.servicepackage;
    this.historyValue['status'] = 'PENDING';
    this.historyValue['payType'] = this.pay_type;
    this.historyValue['price'] = this.price;
    this.historyValue['cash'] = Number(this.cash);
    this.historyValue['desc'] = this.desc;
    this.historyValue['point'] = this.point;
    this.historyValue['sum_point'] = this.sum_point+this.point;
    this.historyValue['tableNo'] = this.tableNo;
    this.historyValue['startDateBook'] = new Date();
    this.historyValue['endDateBook'] = new Date();
    this.historyValue["userRef"] = this.db.doc('/users/'+this.user.id).ref;
    this.historyValue["branchRef"] = this.db.doc('/branch/'+this.authenMenbarber.branchId).ref;
    this.historyValue["customerRef"] = this.db.doc('/customer/'+this.customerData.id).ref;

    var customer = {};
    customer["point"] = this.sum_point+this.point;
    this.customerService.update(this.customerData.id, customer).then(
      res => {
            this.historyService.create(this.historyValue)
            .then(
              res => {
                var userHistoryValue = {};
                var historyId = res.id;

                userHistoryValue["customerId"] = this.customerData.id;
                userHistoryValue["customerRef"] = this.db.doc('/customer/'+this.customerData.id).ref;
                userHistoryValue["historyId"] = historyId;
                userHistoryValue["historyRef"] = this.db.doc('/history/'+historyId).ref;
                userHistoryValue["userId"] = this.user.id;
                userHistoryValue["userRef"] = this.db.doc('/users/'+this.user.id).ref;
                userHistoryValue["menuList"] = menuList;
                userHistoryValue["servicepackageList"] = servicepackageList;
                console.log("historyId : "+historyId);
                this.historyService.createUserHistory(userHistoryValue)
                .then(
                  res => {
                    console.log("createUserHistory success : "+res.id);
                    this.isPaymentBtn = false;
                    this.uploadPdf(historyId);
                  }
                )
              }
            )
      }
    );
  }

 
  assign(){
    $('#loading').show();
    if(this.tableNo < 1){
      this.tableException = true;
      $('#loading').hide();
      return;
    }else{
      this.tableException = false;
    }

    if(!this.user){
      $('#loading').hide();
      alert("ยังไม่มอบหมายงานช่าง");
      return;
    } 
    this.authenMenbarber = JSON.parse(localStorage.getItem('authenMenbarberToken'));
    let historyValue = {};
    historyValue['status'] = 'PENDING';
    this.historyService.update(this.historyBooking.id,historyValue).then(res => {
      console.log("update history success : "+ this.historyBooking.id,historyValue);
      this.isPaymentBtn = false;
      this.uploadPdf(this.historyBooking.id);
    });
  }
  

  genInvYYYYMMDD(){
    var today = new Date();
    var dd = today.getDate();
    var mm = today.getMonth() + 1; //January is 0!

    var ddStr = "";
    var mmStr = "";

    var yyyy = today.getFullYear();
    if (dd < 10) {
      ddStr = '0' + dd;
    }else{
      ddStr = dd+"";
    }
    if (mm < 10) {
      mmStr = '0' + mm;
    }else{
      mmStr = mm+"";
    }
    return yyyy+mmStr+ddStr;
  }

  pad(number,size) {
    var s = String(number);
    while (s.length < (size || 2)) {s = "0" + s;}
    return s;
  }

  pdfUrl = "";
  uploadPdf(historyId){
    // INV-รหัสสาขา-20191128-000001
    let invoiceno = "INV-"+this.branch.code+"-"+this.genInvYYYYMMDD()+this.pad(this.invoiceNoRunning,6);
    let taxNo = this.branch.tax;
    const form = new FormData();
    form.append("taxno", taxNo);
    form.append("invoiceno", invoiceno);
    if(this.processType == 'WALKIN'){
      this.servicepackageMap.forEach((val, index) => {
        console.log(val);
        console.log(index);
        form.append("order[]", val.name);
        form.append("order_price[]", val.price);
      });
      this.menuMap.forEach((val, index) => {
        console.log(val);
        console.log(index);
        form.append("order[]", val.name);
        form.append("order_price[]", val.price);
      });
    }else if(this.processType == 'BOOK'){
        for(let i=0;i<this.bookingServicePackageMenuList.length;i++){
          form.append("order[]", this.bookingServicePackageMenuList[i]['name']);
          form.append("order_price[]", this.bookingServicePackageMenuList[i]['price']);
        }
    }else{
      return;
    }
    
    form.append("customer_no", this.customerData.no);
    form.append("customer_name", this.customerData.fname+" "+this.customerData.lname);
    form.append("cashier", this.authenMenbarber.fname+" "+this.authenMenbarber.lname);
    form.append("customer_phone", this.customerData.tel);
    form.append("customer_no", this.customerData.no);
    form.append("point", this.point+"");
    form.append("sum_point", (this.sum_point+this.point)+"");
    form.append("tableno", this.tableNo+"");
    form.append("tech_name", "("+this.user['code']+")"+this.user['fname']+" "+this.user['lname']);
    form.append("total", this.price+"");
    form.append("net", this.price+"");
    form.append("cash", this.cash+"");
    form.append("change", (this.cash-this.price)+"");
    form.append("vat_able", this.price+"");
    form.append("vat", (this.price*0.07)+"");

    this.fileManagerService.createReceipt(form)
      .subscribe(res => {
        console.log(res);
        if (res.code == "200") {
          this.pdfUrl = res.data;
          $('#loading').hide();
          //update link receipt 
          var historyUpdate = {};
          historyUpdate["invoiceLink"] = this.pdfUrl;
          historyUpdate["invoiceNo"] = invoiceno;
          this.historyService.update(historyId, historyUpdate)
            .then(
              res => {
                console.log("update receipt history already.")
              }
            )

          this.urlSafe= this.sanitizer.bypassSecurityTrustResourceUrl(this.pdfUrl);
          $('#receipt-dialog').modal('show');
          var branchNew = {};
          branchNew["invoiceNoRunning"] = this.invoiceNoRunning+1;
          this.branchService.update(this.branchId, branchNew).then(
            res => {
              console.log(res);
            }
          );

        }
      })
  }

  setCustomer(e){
    this.price = 0;
    this.cash = 0;
    this.change = 0;
    this.processType = "WALKIN";
    this.pay_type = 'เงินสด';
    this.customerData = e;
    if(this.customerData.point){
      this.sum_point = this.customerData.point;
    }
    console.log(this.customerData);
    console.log(this.sum_point);
  }

  customerTypeChange(e){
    this.customerType = e.target.value;
  }

  bookingList = [];
  openBookingDialog(){
    $("#table-search-booking")
      .DataTable()
      .clear()
      .destroy();
    this.bookingList = [];
    this.historyService.searchByStatus('BOOK').subscribe(data => {
        data.forEach(doc => {
          let history = doc.data();
          history['id'] = doc.id;
              this.historyService.getUserHistoryByHistoryId(history['id']).subscribe(data => {
                  data.forEach(doc => {
                    let userHistory = doc.data();
                      if(userHistory.userRef){
                        userHistory.userRef.get().then(res => {
                          history['user'] = res.data();
                          history['user']['id'] = res.id;
                          history['servicePackageMenuList'] = userHistory['menuList'];
                          if(history.startDateBook && history.endDateBook){
                              let start = Number(history.startDateBook.seconds);
                              let end = Number(history.endDateBook.seconds);
                              let month = "";
                              let day = "";
                              let startDate = new Date(start * 1000);
                                let endDate = new Date(end * 1000);
                                var dd = startDate.getDate();
                                var mm = startDate.getMonth() + 1; //January is 0!
                                var yyyy = startDate.getFullYear();
                                if (dd < 10) {
                                  day = "0" + dd;
                                } else {
                                  day = "" + dd;
                                }
                                if (mm < 10) {
                                  month = "0" + mm;
                                } else {
                                  month = "" + mm;
                                }
                                var today =
                                  day +
                                  " " +
                                  this.convertMonthTH(month) +
                                  " " +
                                  (Number(yyyy) + 543);
                                var startTime =
                                  (startDate.getHours() < 10
                                    ? "0" + startDate.getHours()
                                    : startDate.getHours()) +
                                  "." +
                                  (startDate.getMinutes() < 10
                                    ? "0" + startDate.getMinutes()
                                    : startDate.getMinutes());
                                var endTime =
                                  (endDate.getHours() < 10
                                    ? "0" + endDate.getHours()
                                    : endDate.getHours()) +
                                  "." +
                                  (endDate.getMinutes() < 10
                                    ? "0" + endDate.getMinutes()
                                    : endDate.getMinutes());
                                history["createDate"] =
                                  today + "  " + startTime + " - " + endTime + " น.";
                              }
                        })
                      }
                      if(userHistory.customerRef){
                        userHistory.customerRef.get().then(res => {
                          history['customer'] = res.data();
                          history['customer']['id'] = res.id;
                          console.log(history);
                            if(history.branchRef){
                              history.branchRef.get().then(res => {
                                console.log(res.id)
                                if(res.id == this.branchId){
                                  this.bookingList.push(history);
                                }
                              })
                            }

                          setTimeout(() => {
                            $('#table-search-booking').DataTable({
                              retrieve: true,
                              paging: true,
                              responsive: true
                            });
                          }, 0);

                        })
                      }
                      setTimeout(() => {
                        $('#table-search-booking').DataTable({
                          retrieve: true,
                          paging: true,
                          responsive: true
                        });
                      }, 0);
                  }) //end loop user history
              })

              setTimeout(() => {
                $('#table-search-booking').DataTable({
                  retrieve: true,
                  paging: true,
                  responsive: true
                });
              }, 0);

        })//end loop history
        setTimeout(() => {
          $('#table-search-booking').DataTable({
            retrieve: true,
            paging: true,
            responsive: true
          });
        }, 0);
        $('#search-book-modal').modal('show');
    });
  }

  historyBooking;
  bookingServicePackageMenuList = [];
  setBooking(e){
    this.historyBooking = e;
    //console.log(this.historyBooking.id);
    this.price = this.historyBooking['price'];
    this.cash = this.historyBooking['cash'];
    this.processType = "BOOK";
    this.customerData = e.customer;
    this.user = e.user;
    this.pay_type = this.historyBooking['payType'];

    //set checked menu list
    this.bookingServicePackageMenuList = e.servicePackageMenuList;
    for(let i=0;i<e.servicePackageMenuList.length;i++){
      let menu = e.servicePackageMenuList[i];
      //console.log(menu['id']);
      for(let j=0;j<this.menus.length;j++){
        if(this.menus[j]['id'] == menu['id']){
          this.menus[j]['checked'] = true;
        }
      }
    }
  }

  currentPaySlip = "../../assets/img/no-data.png";
  viewSlip(){
    this.currentPaySlip = this.historyBooking['slip_file'];
    $('#view-slip-payment').modal('show');
  }


  convertMonthTH(m) {
    var month = "";
    if ("01" == m) {
      month = "มกราคม";
    } else if ("02" == m) {
      month = "กุมภาพันธ์";
    } else if ("03" == m) {
      month = "มีนาคม";
    } else if ("04" == m) {
      month = "เมษายน";
    } else if ("05" == m) {
      month = "พฤษภาคม";
    } else if ("06" == m) {
      month = "มิถุนายน";
    } else if ("07" == m) {
      month = "กรกฎาคม";
    } else if ("08" == m) {
      month = "สิงหาคม";
    } else if ("09" == m) {
      month = "กันยายน";
    } else if ("10" == m) {
      month = "ตุลาคม";
    } else if ("11" == m) {
      month = "พฤศจิกายน";
    } else if ("12" == m) {
      month = "ธันวาคม";
    }
    return month;
  }

}
