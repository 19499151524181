import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ServicePackageComponent } from './servicepackage.component';
import { Package } from '../model/package.model';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule
  ],
  declarations: [ServicePackageComponent],
  exports: [
    ServicePackageComponent
  ],
  providers: [
    Package
  ]
})
export class ServicePackageModule { }
