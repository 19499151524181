import { Component, OnInit } from '@angular/core';
import { CustomerService } from '../../service/customer.service';
import { Router, ActivatedRoute } from '@angular/router';
import { HttpClient } from "@angular/common/http";
import { HttpErrorResponse } from "@angular/common/http";
import { HistoryService } from '../../service/history.service'
declare var jquery: any;
declare var $: any;

@Component({
  selector: 'app-customerview',
  templateUrl: './customerview.component.html',
  styleUrls: ['./customerview.component.css']
})
export class CustomerViewComponent implements OnInit {
  customerData;
  customerId;
  countryJson;
  amphurJson;
  thumbonJson;
  postcode = "";
  historyList = [];
  historyCount = 0;
  historyTotalPaid = 0;
  historyTotalAvgPaid = 0;
  currentDateService = "";

  constructor(
    private activatedRoute: ActivatedRoute,
    private customerService: CustomerService,
    private httpService: HttpClient,
    private historyService: HistoryService
  ) { }

  ngOnInit() {
    //get params
    this.activatedRoute.params.forEach((urlParams) => {
      this.customerId = urlParams['cid'].replace('#', '');
      console.log("customerId : "+this.customerId);
      this.getHistoryCustomer(this.customerId);
    });
    this.getCustomer();
    this.getProvince(); 
  } 


  getHistoryCustomer(customerId){
    $('#loading').show();
    $("#customer-view-datatables")
      .DataTable()
      .clear()
      .destroy();

    this.historyList = [];
    this.historyService.getUserHistoryByCustomerId(customerId).subscribe(res =>{
      res.forEach(doc => {
          let historyUser = doc.data();

          if(historyUser.historyRef){
            historyUser.historyRef.get().then(res =>{
              let historyObj = res.data();
                if(historyObj.status == 'HIS'){
                    let history = historyObj;
                    history['id'] = res.id;
                    history['customerid'] = customerId;

                    //calculate amount and avg
                    this.historyCount++;
                    this.historyTotalPaid = this.historyTotalPaid + history['price'];
                    this.historyTotalAvgPaid = this.historyTotalPaid/this.historyCount;

                    //convert to thai date
                    let start = Number(history.startDate.seconds);
                    let month = "";
                    let day = "";
                    let startDate = new Date(start * 1000);
                    var dd = startDate.getDate();
                    var mm = startDate.getMonth() + 1; //January is 0!
                    var yyyy = startDate.getFullYear();
                    if (dd < 10) {
                      day = "0" + dd;
                    } else {
                      day = "" + dd;
                    }
                    if (mm < 10) {
                      month = "0" + mm;
                    } else {
                      month = "" + mm;
                    }
                    var today =
                      day +
                      " " +
                      this.convertMonthTH(month) +
                      " " +
                      (Number(yyyy) + 543);
                    var startTime =
                      (startDate.getHours() < 10
                        ? "0" + startDate.getHours()
                        : startDate.getHours()) +
                      "." +
                      (startDate.getMinutes() < 10
                        ? "0" + startDate.getMinutes()
                        : startDate.getMinutes()); 
                      history["createDate"] =
                      today + "  " + startTime + " น.";

                    //branch
                    if(history.branchRef){
                       history.branchRef.get().then(res => {
                         let branch = res.data();
                         branch['id'] = res.id;
                         history['branch'] = branch;
                       })
                    }
                    //console.log(history);

                    //menu package
                    let menuPackage = "";
                    for(let i=0;i<historyUser['menuList'].length;i++){
                      menuPackage = menuPackage + historyUser['menuList'][i]['name'] + ",";
                    }
                    history['menuPackage'] = menuPackage;

                    //technician
                    if(history.userRef){
                      history.userRef.get().then(res => {
                        let technician = res.data();
                        technician['id'] = res.id;
                        history['technician'] = technician;
                      })
                   } 

                    //add history to list
                    this.historyList.push(history);
                    setTimeout(() => {
                      this.historyList.sort((a, b) => Number(b.startDate.seconds) - Number(a.startDate.seconds));
                      this.currentDateService = this.historyList[0].createDate;
                    }, 100);



                    setTimeout(() => {
                      $('#customer-view-datatables').DataTable({
                        retrieve: true,
                        paging: true,
                        responsive: true
                      });
              
                    }, 0);

                }
            })
          }

      });

      setTimeout(() => {
        $('#loading').hide();
      }, 0);

    });
  }

  getProvince() {
    this.httpService.get("./assets/json/data.json").subscribe(
      data => {
        setTimeout(function() {
          $('#loading').hide();
        }, 500);

        this.countryJson = data as string[]; // FILL THE ARRAY WITH DATA.
        //console.log(this.countryJson);
      },
      (err: HttpErrorResponse) => {
        console.log(err.message);
      }
    );
  }
 
  getAmphur(e) {
    this.thumbonJson = [];
    if (e == "") {
      this.amphurJson = [];
    } else {
      this.postcode = "";
      let value = e.split("|");
      this.amphurJson = this.countryJson[value[0]][1];
    }
  }

  getCustomer(){
    this.customerService.getByKey(this.customerId).subscribe(data => {
      this.customerData = data.payload.data();
    })
  }

  getThumbon(e) {
    if (e == "") {
      this.postcode = "";
    } else {
      let value = e.split("|");
      this.thumbonJson = this.amphurJson[value[0]][1];
      let thumbons = this.thumbonJson[1];
      this.postcode = thumbons[1][0];
    }

    //console.log(thumbons[1][0])
  }

  getPost(e) {
    let value = e.split("|");
    let thumbons = this.thumbonJson[value[0]][1];
    this.postcode = thumbons[0];
    //console.log(this.postcode)
  }


  convertMonthTH(m) {
    var month = "";
    if ("01" == m) {
      month = "มกราคม";
    } else if ("02" == m) {
      month = "กุมภาพันธ์";
    } else if ("03" == m) {
      month = "มีนาคม";
    } else if ("04" == m) {
      month = "เมษายน";
    } else if ("05" == m) {
      month = "พฤษภาคม";
    } else if ("06" == m) {
      month = "มิถุนายน";
    } else if ("07" == m) {
      month = "กรกฎาคม";
    } else if ("08" == m) {
      month = "สิงหาคม";
    } else if ("09" == m) {
      month = "กันยายน";
    } else if ("10" == m) {
      month = "ตุลาคม";
    } else if ("11" == m) {
      month = "พฤศจิกายน";
    } else if ("12" == m) {
      month = "ธันวาคม";
    }
    return month;
  }
 
}
