import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { retry, catchError } from 'rxjs/operators';

var jsonResponse: any;
@Injectable({
    providedIn: 'root'
})

export class FileManagerService {

    // Define API
    apiURL = 'https://hairline7.com/menbarber/api';
    constructor(private http: HttpClient) { }

    // Http Options
    httpOptions = {
        headers: new HttpHeaders({
            'Content-Type': 'application/json'
        })
    }

    // upload
    uploadFile(file): Observable<any> {
        console.log(file);
        return this.http.post<any>(this.apiURL + '/v1/upload', file).pipe(
            retry(1),
            catchError(this.handleError)
        );
    }

    // create receipt
    createReceipt(data): Observable<any> {
        console.log(data);
        return this.http.post<any>(this.apiURL + '/v1/receipt', data).pipe(
            retry(1),
            catchError(this.handleError)
        );
    }

    // sendSms
    sendSms(data): Observable<any> {
        console.log(data);
        return this.http.post<any>(this.apiURL + '/v1/sms/newcustomer', data).pipe(
            retry(1),
            catchError(this.handleError)
        );
    }

    // Error handling
    handleError(error) {
        let errorMessage = '';
        if (error.error instanceof ErrorEvent) {
            // Get client-side error
            errorMessage = error.status;
        } else {
            // Get server-side error
            errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
        }
        //window.alert(errorMessage);
        return throwError(errorMessage);
    }

}