import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { ReactiveFormsModule } from '@angular/forms';
import { NewCustomerComponent } from './newcustomer.component';
import { WebcamModule } from '../../webcam_default/webcam.module';

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    WebcamModule
  ],
  declarations: [NewCustomerComponent],
  exports: [
    NewCustomerComponent
  ],
  providers: [
  ]
})
export class NewCustomerModule { }
